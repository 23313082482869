import {FastField, useField} from 'formik'
import capitalize from 'lodash.capitalize'
import {Fragment} from 'react'

import {
  Box,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
} from '@chakra-ui/react'
import {faCalendarDay as falCalendarDay} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {DayOfTheWeek, DayPeriod} from 'generated/graphql'

import {Checkbox} from './components'

interface TimeAvailabilitySelectorProps {
  isRequired?: boolean
  name?: string
}

const DAYS_OF_THE_WEEK = [
  DayOfTheWeek.MONDAY,
  DayOfTheWeek.TUESDAY,
  DayOfTheWeek.WEDNESDAY,
  DayOfTheWeek.THURSDAY,
  DayOfTheWeek.FRIDAY,
  DayOfTheWeek.SATURDAY,
  DayOfTheWeek.SUNDAY,
]
const DAY_PERIODS = [DayPeriod.MORNING, DayPeriod.AFTERNOON, DayPeriod.EVENING]

export function TimeAvailabilitySelector({
  isRequired,
  name,
}: TimeAvailabilitySelectorProps) {
  const [, fieldMeta] = useField(name)

  return (
    <FormControl
      isInvalid={!!fieldMeta.error && fieldMeta.touched}
      isRequired={isRequired}
    >
      <FormLabel>What times work best for you?</FormLabel>

      {DAYS_OF_THE_WEEK.map((day) => (
        <Flex
          flexDirection={{base: 'column', sm: 'row'}}
          gap="4"
          marginBlockEnd="4"
        >
          <Fragment key={day}>
            <HStack spacing="1">
              <FontAwesomeIcon fixedWidth icon={falCalendarDay} />
              <Box minWidth="34px" fontWeight="medium">
                {capitalize(day.slice(0, 3))}
              </Box>
            </HStack>
            <FastField name={`timeAvailabilities.${day}`}>
              {({field}) => (
                <Box
                  display="flex"
                  justifyContent="space-around"
                  gap="4"
                  width="100%"
                >
                  <CheckboxGroup onChange={field.onChange} value={field.value}>
                    {DAY_PERIODS.map((period) => (
                      <Checkbox width="100%" key={period} value={period}>
                        {capitalize(period)}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </Box>
              )}
            </FastField>
          </Fragment>
        </Flex>
      ))}
      <FormErrorMessage>{fieldMeta.error}</FormErrorMessage>
    </FormControl>
  )
}
