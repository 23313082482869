import React from 'react'
import {graphql, useFragment} from 'react-relay/hooks'
import {Box} from '@chakra-ui/react'

import {SportAndAgesText_game$key} from './__generated__/SportAndAgesText_game.graphql'

interface SportAndAgesTextProps {
  game: SportAndAgesText_game$key
}

export function SportAndAgesText(props: SportAndAgesTextProps) {
  const {endAge, sport, startAge, title} = useFragment(
    graphql`
      fragment SportAndAgesText_game on Game {
        endAge
        startAge
        title
        sport {
          name
        }
      }
    `,
    props.game
  )

  if (startAge <= 1 && endAge >= 76) {
    return (
      <Box fontSize="sm">
        {title && `${sport.name}, `}
        Ages: All Ages
      </Box>
    )
  }
  return (
    <Box fontSize="sm">
      {title && `${sport.name}, `}
      Ages: {startAge}
      {endAge >= 76 ? `+` : `-${endAge}`}
    </Box>
  )
}
