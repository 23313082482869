import debounce from 'lodash.debounce'
import React from 'react'
import {fetchQuery, graphql, useRelayEnvironment} from 'react-relay'
import Async from 'react-select/async'

const QUERY = graphql`
  query CitySelectQuery($name: String) {
    cities: allCities(name: $name, limit: 30) {
      id
      name
      province {
        shortName
      }
    }
  }
`

export default function CitySelect({id, ...props}) {
  const environment = useRelayEnvironment()
  const searchCityByName = debounce((value, callback) => {
    fetchQuery(environment, QUERY, {
      name: `%${value}%`,
    }).subscribe({
      next: ({cities}) => {
        const results = cities.map((city) => ({
          value: city.id,
          label: `${city.name}, ${city.province?.shortName}`,
        }))
        callback(results)
      },
    })
  }, 250)

  return (
    <Async
      id={id}
      {...props}
      cacheOptions
      placeholder="Search a city"
      loadOptions={searchCityByName}
    />
  )
}
