import React, {Suspense} from 'react'

import {OVERLAY_COMPONENTS} from './overlays'
import {useOverlayService} from './hooks'
import {DrawerSkeleton, ModalSkeleton} from './components'

const OVERLAY_TYPE_SKELETON_MAP = {
  drawer: DrawerSkeleton,
  modal: ModalSkeleton,
}

export function OverlayRoot() {
  const [overlayState, sendOverlay] = useOverlayService()

  if (overlayState.matches('closed')) {
    return null
  }

  const SpecificOverlay = OVERLAY_COMPONENTS[overlayState.context.overlay]
  const props = {
    ...overlayState.context.props,
    isOpen: overlayState.matches('opened'),
    onClose: () => sendOverlay('CLOSE'),
  }

  const SkeletonComponent =
    OVERLAY_TYPE_SKELETON_MAP[overlayState.context.overlayType]

  return (
    <Suspense fallback={<SkeletonComponent />}>
      <SpecificOverlay {...props} />
    </Suspense>
  )
}
