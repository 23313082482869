/**
 * @generated SignedSource<<906e3c06b4aa217797b5b44a429360e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LeaveRemindListInput = {
  gameId: string;
};
export type RemindButtonLeaveMutation$variables = {
  input: LeaveRemindListInput;
};
export type RemindButtonLeaveMutation$data = {
  readonly leaveRemindList: {
    readonly game: {
      readonly viewerIsOnRemindMeList: boolean;
    } | null;
  } | null;
};
export type RemindButtonLeaveMutation = {
  variables: RemindButtonLeaveMutation$variables;
  response: RemindButtonLeaveMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsOnRemindMeList",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemindButtonLeaveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LeaveRemindListPayload",
        "kind": "LinkedField",
        "name": "leaveRemindList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Game",
            "kind": "LinkedField",
            "name": "game",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemindButtonLeaveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LeaveRemindListPayload",
        "kind": "LinkedField",
        "name": "leaveRemindList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Game",
            "kind": "LinkedField",
            "name": "game",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33a1639357a1cdf21b5f2d5b5a640bba",
    "id": null,
    "metadata": {},
    "name": "RemindButtonLeaveMutation",
    "operationKind": "mutation",
    "text": "mutation RemindButtonLeaveMutation(\n  $input: LeaveRemindListInput!\n) {\n  leaveRemindList(input: $input) {\n    game {\n      viewerIsOnRemindMeList\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "19de322d5004df081f26981040cae582";

export default node;
