import React, {ReactNode} from 'react'
import {Box, Stack} from '@chakra-ui/react'

import {Header, Section} from './components'

interface CardProps {
  children?: ReactNode
  sectioned?: boolean
  title?: ReactNode
}

export function Card({children, sectioned, title}: CardProps) {
  const headerMarkup = title && <Header title={title} />
  const content = sectioned ? <Section>{children}</Section> : children

  return (
    <Stack bg="white" borderRadius="md" boxShadow="sm">
      {headerMarkup}
      {content}
    </Stack>
  )
}

Card.Header = Header
Card.Section = Section
