/**
 * @generated SignedSource<<0900a723ce60763197df7388d321cc84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenu_viewer$data = {
  readonly resourcePath: string;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_actor">;
  readonly " $fragmentType": "UserMenu_viewer";
};
export type UserMenu_viewer$key = {
  readonly " $data"?: UserMenu_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenu_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenu_viewer",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "size",
          "value": 24
        }
      ],
      "kind": "FragmentSpread",
      "name": "Avatar_actor"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourcePath",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "f99e9fafd4f24a2070c0f10d6ceac7d7";

export default node;
