import {Spinner} from '@chakra-ui/react'

import {LoadingContainer} from './LoadingContainer'

export function LoadingApp() {
  return (
    <LoadingContainer h="100vh">
      <Spinner size="lg" />
    </LoadingContainer>
  )
}
