import {SyntheticEvent} from 'react'

export function wrapEvent<EventType extends SyntheticEvent | Event>(
  theirHandler: ((event: EventType) => any) | undefined,
  ourHandler: (event: EventType) => any
): (event: EventType) => any {
  return (event) => {
    theirHandler && theirHandler(event)

    if (!event.defaultPrevented) {
      return ourHandler(event)
    }
  }
}
