import React, {lazy, Suspense} from 'react'
import {interpret} from 'xstate'
import {useActor} from '@xstate/react'

import machine from './machine'
import {LoadingModal} from 'components/loading'

const MODAL_COMPONENTS = {
  // alert dialogs
  DELETE_DOUBLE_CHECK_ALERT_DIALOG: lazy(
    () => import(`components/DeleteDoubleCheckAlertDialog`)
  ),

  // modals
  ADD_ADMIN_MODAL: lazy(
    () =>
      import(`pages/admin/AdminDashboardManageAdmins/components/AddAdminModal`)
  ),
  EDIT_ADMIN_MODAL: lazy(
    () =>
      import(`pages/admin/AdminDashboardManageAdmins/components/EditAdminModal`)
  ),
  FOLLOWING_CONFIRMATION_MODAL: lazy(
    () => import(`pages/site/components/FollowingConfirmationModal`)
  ),
  CHANGE_PASSWORD_MODAL: lazy(
    () =>
      import(
        `pages/admin/AdminDashboardManageAdmins/components/ChangePasswordModal`
      )
  ),
  UPDATE_PHOTO_MODAL: lazy(
    () =>
      import(
        `pages/site/UserProfile/UserProfileEdit/components/AccountInfo/components/UpdatePhotoModal`
      )
  ),
  EDIT_USER_MODAL: lazy(
    () => import(`pages/manage/ManageUsers/components/EditUserModal`)
  ),
  UPDATE_CHILD_MODAL: lazy(
    () =>
      import(
        `pages/site/Hub/components/Sidebar/components/Youth/components/UpdateChildModal`
      )
  ),
  WAITLIST_HOCKEY_POSITION_MODAL: lazy(
    () => import(`pages/site/components/WaitlistHockeyModal`)
  ),
  WAITLIST_NOTIFICATION_MODAL: lazy(
    () => import(`pages/site/components/WaitlistNotificationModal`)
  ),
  REMIND_EXPLANATION_MODAL: lazy(
    () => import(`pages/site/components/RemindExplanationModal`)
  ),
}

export const ModalService = interpret(
  machine.withContext({
    ...machine.context,
    modals: Object.keys(MODAL_COMPONENTS),
  })
).start()

export function ModalRoot() {
  const [state, send] = useActor(ModalService)

  if (state.matches('closed')) {
    return null
  }

  const SpecificModal = MODAL_COMPONENTS[state.context.modal]
  const props = {
    ...state.context.props,
    onClose: () => send('CLOSE'),
  }

  return (
    <Suspense fallback={<LoadingModal />}>
      <SpecificModal {...props} />
    </Suspense>
  )
}
