import React, {ReactNode} from 'react'
import {Box, Heading} from '@chakra-ui/react'

export interface SectionProps {
  children?: ReactNode
  fullWidth?: boolean
  title?: ReactNode
}

export function Section({children, fullWidth, title}: SectionProps) {
  const titleMarkup =
    typeof title === `string` ? (
      <Heading
        as="h3"
        fontWeight="semibold"
        fontSize="0.75rem"
        textTransform="uppercase"
      >
        {title}
      </Heading>
    ) : (
      title
    )

  return (
    <Box
      px={fullWidth ? 0 : 5}
      py={5}
      _notLast={{
        borderBottom: `1px solid`,
        borderBottomColor: `gray.100`,
      }}
      flexGrow={1}
    >
      {titleMarkup && <Box pb={2}>{titleMarkup}</Box>}
      {children}
    </Box>
  )
}
