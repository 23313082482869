import React, {lazy, Suspense} from 'react'
import {Routes, Route} from 'react-router-dom'

import ErrorBoundary from 'components/ErrorBoundary'

import ViewError from 'components/ViewError'
import Bootstrap from 'utils/Bootstrap'

//
import {LoadingApp, ModalRoot as ModalRootNew, OverlayRoot} from 'components'
import ModalRoot from 'components/modals/ModalRoot'
//

const AdminDashboard = lazy(() => import(`pages/admin/AdminDashboard`))
const AdminManageAdmins = lazy(() =>
  import(`pages/admin/AdminDashboardManageAdmins`)
)

const ManageRoot = lazy(() => import(`pages/manage/ManageRoot`))
const ManageLocations = lazy(() => import(`pages/manage/ManageLocations`))
const ManageProviders = lazy(() => import(`pages/manage/ManageProviders`))
const ManageUsers = lazy(() => import(`pages/manage/ManageUsers`))

const NotFound = lazy(() => import(`pages/NotFound`))

//
const SiteHubRoot = lazy(() => import(`pages/site/Hub/HubRoot`))
const SiteHubIndex = lazy(() => import(`pages/site/Hub/HubIndex`))
const SiteHubMySchedule = lazy(() => import(`pages/site/Hub/HubMySchedule`))

const ProviderAbout = lazy(() => import(`pages/site/Provider/ProviderAbout`))
const ProviderIndex = lazy(() => import(`pages/site/Provider/ProviderIndex`))
const ProviderPage = lazy(() => import(`pages/site/Provider/ProviderPage`))
const ProviderRoot = lazy(() => import(`pages/site/Provider/ProviderRoot`))

const UserProfileEdit = lazy(() =>
  import(`pages/site/UserProfile/UserProfileEdit`)
)
const UserProfileRoot = lazy(() =>
  import(`pages/site/UserProfile/UserProfileRoot`)
)
const UserProfileFans = lazy(() =>
  import(`pages/site/UserProfile/UserProfileFans`)
)
const UserProfileFavorites = lazy(() =>
  import(`pages/site/UserProfile/UserProfileFavorites`)
)
const UserProfileIndex = lazy(() =>
  import(`pages/site/UserProfile/UserProfileIndex`)
)

const catchAllInstanceTypes =
  Bootstrap.getBootstrapData(`catchAllInstanceTypes`, true) || {}

export default function AppRoutes() {
  return (
    <ErrorBoundary FallbackComponent={ViewError}>
      {/* dont let non-critical pieces of UI crash the whole app */}
      <ErrorBoundary>
        <ModalRoot />
      </ErrorBoundary>

      <ErrorBoundary>
        <ModalRootNew />
      </ErrorBoundary>

      <ErrorBoundary>
        <OverlayRoot />
      </ErrorBoundary>

      <Suspense fallback={<LoadingApp />}>
        <Routes>
          <Route element={<AdminDashboard />} path="admin">
            <Route element={<AdminManageAdmins />} path="manage/admins" />
          </Route>

          <Route element={<ManageRoot />} path="manage">
            <Route element={<ManageLocations />} path="locations" />
            <Route element={<ManageProviders />} path="providers" />
            <Route element={<ManageUsers />} path="users" />
          </Route>

          <Route element={<SiteHubRoot />} path="hub">
            <Route element={<SiteHubIndex />} index />
            <Route element={<SiteHubMySchedule />} path="my-schedule" />
          </Route>

          <Route
            element={<UserProfileEdit />}
            path="profile/:userId/:slug/edit"
          />

          <Route element={<UserProfileRoot />} path="profile/:userId/:slug">
            <Route element={<UserProfileFans />} path="fans" />
            <Route element={<UserProfileFavorites />} path="favorites" />
            <Route element={<UserProfileIndex />} index />
          </Route>

          {catchAllInstanceTypes.provider && (
            <Route element={<ProviderRoot />} path=":slug">
              <Route element={<ProviderIndex />} index />
              <Route element={<ProviderAbout />} path="about" />
              <Route element={<ProviderPage />} path="info/:pageHandle" />
            </Route>
          )}

          <Route element={<NotFound />} path="*" />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  )
}
