/**
 * @generated SignedSource<<fbad2a79770cf40e473fcab168af0227>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Map_locations$data = ReadonlyArray<{
  readonly address: string | null;
  readonly id: string;
  readonly hasWashRoom: boolean;
  readonly hasShower: boolean;
  readonly hasChangeRoom: boolean;
  readonly hasOutdoorLight: boolean;
  readonly latitude: number;
  readonly longitude: number;
  readonly name: string;
  readonly facility: {
    readonly name: string;
  } | null;
  readonly surface: {
    readonly name: string | null;
  } | null;
  readonly " $fragmentType": "Map_locations";
}>;
export type Map_locations$key = ReadonlyArray<{
  readonly " $data"?: Map_locations$data;
  readonly " $fragmentSpreads": FragmentRefs<"Map_locations">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Map_locations",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasWashRoom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasShower",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasChangeRoom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasOutdoorLight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longitude",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Facility",
      "kind": "LinkedField",
      "name": "facility",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Surface",
      "kind": "LinkedField",
      "name": "surface",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Location",
  "abstractKey": null
};
})();

(node as any).hash = "4638110acd432c37c17d830fb7dc239e";

export default node;
