import CommentListItem from '../CommentListItem/CommentListItem'

function CommentList({comments, subject, viewer}) {
  return comments.map((comment) => (
    <CommentListItem
      comment={comment}
      key={comment.id}
      subject={subject}
      viewer={viewer}
    />
  ))
}

export default CommentList
