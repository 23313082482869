import {RemindButtonJoinMutation$data} from './__generated__/RemindButtonJoinMutation.graphql'
import {createMachine} from 'xstate'
import {RemindButtonLeaveMutation$data} from './__generated__/RemindButtonLeaveMutation.graphql'

export const gameRemindButtonMachine = createMachine(
  {
    predictableActionArguments: true,
    id: 'gameRemindButton',
    initial: 'idle',
    schema: {
      context: {} as {},
      events: {} as
        | {
            type: 'JOIN_REMIND_LIST'
            id: string
          }
        | {
            type: 'LEAVE_REMIND_LIST'
            id: string
          },
      services: {} as {
        joinRemindList: {
          data: RemindButtonJoinMutation$data
        }
        leaveRemindList: {
          data: RemindButtonLeaveMutation$data
        }
      },
    },
    tsTypes: {} as import('./gameRemindButtonMachine.typegen').Typegen0,
    states: {
      idle: {
        on: {
          JOIN_REMIND_LIST: 'joining',
          LEAVE_REMIND_LIST: 'leaving',
        },
      },
      joining: {
        invoke: {
          id: 'joinRemindList',
          src: 'joinRemindList',
          onDone: {
            actions: 'checkModalRemindList',
            target: 'idle',
          },
          onError: {
            actions: 'setErrorMessage',
            target: 'idle',
          },
        },
      },
      leaving: {
        invoke: {
          id: 'leaveRemindList',
          src: 'leaveRemindList',
          onDone: 'idle',
          onError: {
            actions: 'setErrorMessage',
            target: 'idle',
          },
        },
      },
    },
  },
  {
    actions: {
      setErrorMessage: () => {},
    },
  }
)
