/**
 * @generated SignedSource<<ff7d9d5cace963be93b336ae5df67309>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SelectCitiesCitiesByProvideQuery$variables = {};
export type SelectCitiesCitiesByProvideQuery$data = {
  readonly citiesByProvider: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly province: {
      readonly shortName: string;
    } | null;
  }>;
};
export type SelectCitiesCitiesByProvideQuery = {
  variables: SelectCitiesCitiesByProvideQuery$variables;
  response: SelectCitiesCitiesByProvideQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectCitiesCitiesByProvideQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "City",
        "kind": "LinkedField",
        "name": "citiesByProvider",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Province",
            "kind": "LinkedField",
            "name": "province",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SelectCitiesCitiesByProvideQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "City",
        "kind": "LinkedField",
        "name": "citiesByProvider",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Province",
            "kind": "LinkedField",
            "name": "province",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "98b444d2cf63127648ef0ae28178effe",
    "id": null,
    "metadata": {},
    "name": "SelectCitiesCitiesByProvideQuery",
    "operationKind": "query",
    "text": "query SelectCitiesCitiesByProvideQuery {\n  citiesByProvider {\n    id\n    name\n    province {\n      shortName\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "976d130c11b7870cb8b7486feddc5657";

export default node;
