/**
 * @generated SignedSource<<e46fd59171ed59fd3da533976125ec70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GameListItemLocked_game$data = {
  readonly id: string;
  readonly url: string;
  readonly location: {
    readonly " $fragmentSpreads": FragmentRefs<"LocationTextWaitlist_location">;
  };
  readonly sport: {
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"SportIcon_sport">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"DateTimeTextWaitlist_game" | "TitleWaitlist_game" | "RemindButton_game" | "UpcomingCountdown_game">;
  readonly " $fragmentType": "GameListItemLocked_game";
};
export type GameListItemLocked_game$key = {
  readonly " $data"?: GameListItemLocked_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"GameListItemLocked_game">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GameListItemLocked_game",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DateTimeTextWaitlist_game"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TitleWaitlist_game"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemindButton_game"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpcomingCountdown_game"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LocationTextWaitlist_location"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sport",
      "kind": "LinkedField",
      "name": "sport",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SportIcon_sport"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Game",
  "abstractKey": null
};

(node as any).hash = "576dd2f08b0391dfd4173286cd9b5277";

export default node;
