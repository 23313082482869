import React, {useEffect} from 'react'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'
import {Box, Input} from '@chakra-ui/react'

export default function AutoComplete({onChange, value: _value}) {
  const {
    ready,
    value,
    suggestions: {status, data},
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  })

  useEffect(() => {
    setValue(_value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_value])

  const ref = useOnclickOutside(() => {
    clearSuggestions()
  })

  const handleInput = (e) => {
    setValue(e.target.value)
  }

  const handleSelect =
    ({description}) =>
    () => {
      setValue(description, false)
      clearSuggestions()

      getGeocode({address: description})
        .then((results) => getLatLng(results[0]))
        .then(({lat, lng}) => {
          onChange({lat, lng, address: description})
        })
        .catch((error) => {
          console.log(`😱 Error: `, error)
        })
    }

  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        structured_formatting: {main_text, secondary_text},
      } = suggestion

      return (
        <Box
          key={index}
          onClick={handleSelect(suggestion)}
          pl={3}
          my={1}
          _hover={{bg: `blue.100`}}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </Box>
      )
    })

  return (
    <div ref={ref}>
      <Input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Enter the full address for the location"
      />

      {status === `OK` && (
        <Box
          as="ul"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.200"
          mt={2}
          py={3}
          borderRadius="md"
        >
          {renderSuggestions()}
        </Box>
      )}
    </div>
  )
}
