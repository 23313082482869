import {Box, Flex} from '@chakra-ui/react'
import {Heading} from 'components'
import CitySelect from 'components/CitySelect'
import React, {useState} from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay/hooks'
import {SelectCitiesCitiesByProviderQuery} from './__generated__/SelectCitiesCitiesByProviderQuery.graphql'

export function SelectCities() {
  const [searchParams, setSearchParams] = useState([])

  const {citiesByProvider} =
    useLazyLoadQuery<SelectCitiesCitiesByProviderQuery>(
      graphql`
        query SelectCitiesCitiesByProvideQuery {
          citiesByProvider {
            id
            name
            province {
              shortName
            }
          }
        }
      `,
      {},
    )

  const defaultValue = citiesByProvider.map((item) => {
    return {
      label: item.name + ', ' + item.province.shortName,
      value: item.id,
    }
  })

  return (
    <Box>
      <Flex direction="column" gap="5">
        <Heading fontWeight="thin">Cities</Heading>
        <Box w="650px">
          <select hidden name="items[]">
            {searchParams.map((x) => (
              <option key={x} value={x} />
            ))}
          </select>
          <CitySelect
            id="city-select-manage"
            isMulti
            onChange={(e) => setSearchParams([e.map((x) => x.value)])}
            defaultValue={defaultValue}
          />
        </Box>
      </Flex>
    </Box>
  )
}
