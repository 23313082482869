/**
 * @generated SignedSource<<899cbfe0af9c5288210f8e4bcc6353ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchResultType = "PROVIDER" | "USER" | "%future added value";
export type SearchBoxQuery$variables = {
  query: string;
  types?: ReadonlyArray<SearchResultType> | null;
};
export type SearchBoxQuery$data = {
  readonly results: ReadonlyArray<{
    readonly __typename: string;
    readonly id?: string;
    readonly url?: string;
    readonly " $fragmentSpreads": FragmentRefs<"SearchBoxResultListItem_item">;
  }>;
};
export type SearchBoxQuery = {
  variables: SearchBoxQuery$variables;
  response: SearchBoxQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "types"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "types",
    "variableName": "types"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "City",
  "kind": "LinkedField",
  "name": "city",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    },
    {
      "kind": "Literal",
      "name": "times",
      "value": [
        "UPCOMING"
      ]
    }
  ],
  "concreteType": "GameConnection",
  "kind": "LinkedField",
  "name": "games",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "games(first:1,times:[\"UPCOMING\"])"
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "maxHeight",
          "value": 48
        },
        {
          "kind": "Literal",
          "name": "maxWidth",
          "value": 48
        }
      ],
      "kind": "ScalarField",
      "name": "transformedSrc",
      "storageKey": "transformedSrc(maxHeight:48,maxWidth:48)"
    },
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchBoxQuery",
    "selections": [
      {
        "alias": "results",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "search",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SearchBoxResultListItem_item"
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchBoxQuery",
    "selections": [
      {
        "alias": "results",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "search",
        "plural": true,
        "selections": [
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isSearchResultItem"
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v9/*: any*/)
                ],
                "type": "Actor",
                "abstractKey": "__isActor"
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  (v9/*: any*/)
                ],
                "type": "Actor",
                "abstractKey": "__isActor"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "User",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7974fc42f04f9bd98dc77f7a6faffe80",
    "id": null,
    "metadata": {},
    "name": "SearchBoxQuery",
    "operationKind": "query",
    "text": "query SearchBoxQuery(\n  $query: String!\n  $types: [SearchResultType!]\n) {\n  results: search(query: $query, types: $types) {\n    ...SearchBoxResultListItem_item\n    __typename\n    ... on Provider {\n      id\n      url\n    }\n    ... on User {\n      id\n      url\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment Avatar_actor_w8mmD on Actor {\n  __isActor: __typename\n  name\n  avatar {\n    transformedSrc(maxHeight: 48, maxWidth: 48)\n    id\n  }\n}\n\nfragment SearchBoxResultListItem_item on SearchResultItem {\n  __isSearchResultItem: __typename\n  __typename\n  ... on Provider {\n    ...Avatar_actor_w8mmD\n    name\n    city {\n      name\n      id\n    }\n    games(first: 1, times: [UPCOMING]) {\n      pageInfo {\n        total\n      }\n    }\n  }\n  ... on User {\n    ...Avatar_actor_w8mmD\n    displayName\n    city {\n      name\n      id\n    }\n    games(first: 1, times: [UPCOMING]) {\n      pageInfo {\n        total\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f858d46daace83e06eb50a416650b04";

export default node;
