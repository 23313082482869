import React, {useRef, Suspense} from 'react'
import {
  Box,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import FormLoader from './FormLoader'
import {useStore as useModalsStore} from 'store/modals'
import EditLocationFormModal from './EditLocationFormModal'

export default function EditLocationModal({locationId}) {
  const close = useModalsStore((state) => state.closeModal)
  const initialRef = useRef(null)

  return (
    <Modal isOpen initialFocusRef={initialRef} onClose={close} size="xl">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Edit Location</ModalHeader>
          <ModalCloseButton type="button" />
          <Suspense
            fallback={
              <Box p={6}>
                <FormLoader />
              </Box>
            }
          >
            <EditLocationFormModal
              locationId={locationId}
              initialRef={initialRef}
              close={close}
            />
          </Suspense>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
