/* eslint relay/must-colocate-fragment-spreads:0 */
import React, {Suspense} from 'react'
import {For} from 'react-loops'
import {graphql, useFragment, useLazyLoadQuery} from 'react-relay/hooks'
import {
  Box,
  Button,
  Divider,
  Flex,
  FlexProps,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import {
  faCog as farCog,
  faHiking as farHiking,
} from '@fortawesome/pro-regular-svg-icons'

import {UserMenu_viewer$key} from './__generated__/UserMenu_viewer.graphql'
import {UserMenuPopoverBodyQuery} from './__generated__/UserMenuPopoverBodyQuery.graphql'

import {PopoverBodyContentSkeleton} from './UserMenuSkeleton'

import {Avatar} from 'components/AvatarNew'
import {FontAwesomeIcon} from 'components/FontAwesomeIcon'
import {useSiteEnvironmentContext} from 'hooks'

interface UserMenuProps extends FlexProps {
  viewer: UserMenu_viewer$key
}

export function UserMenu({viewer: _viewer, ...props}: UserMenuProps) {
  const viewer = useFragment(
    graphql`
      fragment UserMenu_viewer on User {
        ...Avatar_actor @arguments(size: 24)
        resourcePath
      }
    `,
    _viewer
  )

  return (
    <Flex align="center" {...props}>
      <Popover placement="bottom-end">
        {({isOpen}) => (
          <>
            <PopoverTrigger>
              <Button size="sm" variant="ghost">
                <Avatar actor={viewer} borderRadius="full" />
              </Button>
            </PopoverTrigger>

            <Portal>
              <PopoverContent borderRadius="lg" _focus={{outline: `none`}}>
                <PopoverArrow />
                <PopoverBody
                  border="none"
                  borderRadius="lg"
                  boxShadow="lg"
                  p={2}
                >
                  {isOpen && (
                    <Suspense fallback={<PopoverBodyContentSkeleton />}>
                      <PopoverBodyContent />
                    </Suspense>
                  )}
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </>
        )}
      </Popover>
    </Flex>
  )
}

function PopoverBodyContent() {
  const {PICKUPHUB_URL} = useSiteEnvironmentContext()
  const {viewer} = useLazyLoadQuery<UserMenuPopoverBodyQuery>(
    graphql`
      query UserMenuPopoverBodyQuery {
        viewer {
          displayName
          resourcePath
          ...Avatar_actor @arguments(size: 48)
          credits {
            amount
            provider {
              name
              resourcePath
            }
          }
          profile {
            credits
          }
        }
      }
    `,
    {},
    {
      networkCacheConfig: {
        force: false,
      },
    }
  )

  return (
    <Box>
      <Link
        alignItems="center"
        borderRadius="md"
        d="flex"
        href={`${PICKUPHUB_URL}${viewer.resourcePath}`}
        p={2}
        _hover={{bg: `gray.50`}}
      >
        <Avatar actor={viewer} borderRadius="full" />
        <Box ml={2}>
          <Box color="gray.700" fontWeight="semibold">
            {viewer.displayName}
          </Box>
          <Box color="gray.500" fontSize="xs">
            See Your Profile
          </Box>
        </Box>
      </Link>

      <Divider borderColor="gray.300" m={2} />

      {(viewer.profile.credits > 0 || viewer.credits.length > 0) && (
        <>
          <Box color="gray.500" fontSize="xs" pt={2} px={2}>
            Credits
          </Box>

          {viewer.profile.credits > 0 && (
            <Flex align="center" justify="space-between" h={10} px={2}>
              <Box fontSize="sm">PickupHub</Box>
              <Box color="green.500" fontWeight="medium">
                ${viewer.profile.credits}
              </Box>
            </Flex>
          )}

          <For
            of={viewer.credits}
            as={(credit) => (
              <Link
                alignItems="center"
                borderRadius="md"
                d="flex"
                h={10}
                href={credit.provider.resourcePath}
                justifyContent="space-between"
                px={2}
                _hover={{bg: `gray.50`}}
              >
                <Box fontSize="sm">{credit.provider.name}</Box>
                <Box color="green.500" fontWeight="medium">
                  ${credit.amount}
                </Box>
              </Link>
            )}
          />

          <Divider borderColor="gray.300" m={2} />
        </>
      )}

      <Link
        alignItems="center"
        borderRadius="md"
        d="flex"
        h={10}
        href={`${PICKUPHUB_URL}${viewer.resourcePath}/edit`}
        px={2}
        _hover={{bg: `gray.50`}}
      >
        <FontAwesomeIcon icon={farCog} />
        <Box ml={2}>Edit profile</Box>
      </Link>
      <Link
        alignItems="center"
        borderRadius="md"
        d="flex"
        h={10}
        href="/logout"
        px={2}
        _hover={{bg: `gray.50`}}
      >
        <FontAwesomeIcon icon={farHiking} />
        <Box ml={2}>Logout</Box>
      </Link>
    </Box>
  )
}
