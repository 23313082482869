/**
 * @generated SignedSource<<9c147294f9544d901891412f772d9db6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GameFeed_games$data = ReadonlyArray<{
  readonly id: string;
  readonly startTime: string;
  readonly " $fragmentSpreads": FragmentRefs<"GameFeedItem_games">;
  readonly " $fragmentType": "GameFeed_games";
}>;
export type GameFeed_games$key = ReadonlyArray<{
  readonly " $data"?: GameFeed_games$data;
  readonly " $fragmentSpreads": FragmentRefs<"GameFeed_games">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "youth"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "GameFeed_games",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "youth",
          "variableName": "youth"
        }
      ],
      "kind": "FragmentSpread",
      "name": "GameFeedItem_games"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    }
  ],
  "type": "Game",
  "abstractKey": null
};

(node as any).hash = "691fc684bb90caca5f2bbd87e86a9af3";

export default node;
