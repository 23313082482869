import Downshift, {
  ControllerStateAndHelpers,
  GetItemPropsOptions,
} from 'downshift'
import {forwardRef, useState} from 'react'
import {
  fetchQuery,
  graphql,
  useFragment,
  useRelayEnvironment,
} from 'react-relay/hooks'
import {RemoveScroll} from 'react-remove-scroll'
import {Interpreter} from 'xstate'
import {
  Badge,
  Box,
  BoxProps,
  Flex,
  FlexProps,
  IconButton,
  Input,
  Portal,
  Show,
  Spinner,
  useMediaQuery,
  useTheme,
} from '@chakra-ui/react'
import {
  faArrowLeft as farArrowLeft,
  faMapMarkerAlt as farMapMarkerAlt,
  faSearch as farSearch,
} from '@fortawesome/pro-regular-svg-icons'
import {useMachine, useActor} from '@xstate/react'

import {SearchBoxResultListItem_item$key} from './__generated__/SearchBoxResultListItem_item.graphql'
import {
  SearchBoxContext,
  SearchBoxEvent,
  searchBoxMachine,
  SearchBoxState,
} from './searchBoxMachine'

import {Avatar} from 'components/AvatarNew'
import {FontAwesomeIcon} from 'components/FontAwesomeIcon'
import {
  SearchBoxQuery$data,
  SearchResultType,
} from './__generated__/SearchBoxQuery.graphql'
import {Draft} from 'immer'

/* eslint relay/must-colocate-fragment-spreads:0 */
/* eslint relay/unused-fields:0 */
const SEARCH_QUERY = graphql`
  query SearchBoxQuery($query: String!, $types: [SearchResultType!]) {
    results: search(query: $query, types: $types) {
      ...SearchBoxResultListItem_item
      __typename

      ... on Provider {
        id
        url
      }

      ... on User {
        id
        url
      }
    }
  }
`

const ITEM_TYPE_LABEL_MAP = {
  User: `Player`,
}

interface SearchBoxProps extends BoxProps {
  onChange: (item) => void
  searchType?: SearchResultType[]
  textPlaceholder?: string
}

export function SearchBox({
  onChange,
  searchType = [`USER`, `PROVIDER`],
  textPlaceholder = `Search Facilities & Players`,
  ...props
}: SearchBoxProps) {
  const {breakpoints} = useTheme()
  const relayEnvironment = useRelayEnvironment()
  const [mdBreakpoint] = useMediaQuery(`(min-width: ${breakpoints.md})`)
  const [, sendSearchBox, searchBoxService] = useMachine(searchBoxMachine, {
    services: {
      search: (ctx) => {
        return new Promise(function (resolve, reject) {
          fetchQuery(relayEnvironment, SEARCH_QUERY, {
            query: ctx.query,
            types: ctx.searchType,
          }).subscribe({
            error: (error) => {
              reject(error)
            },
            next: (data: Draft<SearchBoxQuery$data>) => {
              resolve(data)
            },
          })
        })
      },
    },
  })

  return (
    <Downshift
      itemToString={(item) => item?.name ?? ``}
      onChange={onChange}
      onInputValueChange={(query) => {
        sendSearchBox(`TYPE`, {query, searchType: searchType})
      }}
    >
      {(options) => {
        if (mdBreakpoint) {
          return (
            <SearchBoxDesktop
              downshift={options}
              searchBoxService={searchBoxService}
              textPlaceholder={textPlaceholder}
              {...props}
              {...options.getRootProps()}
            />
          )
        }

        return (
          <SearchBoxMobile
            downshift={options}
            searchBoxService={searchBoxService}
            textPlaceholder={textPlaceholder}
            {...props}
            {...options.getRootProps()}
          />
        )
      }}
    </Downshift>
  )
}

type SearchBoxDesktopProps = BoxProps & {
  downshift: ControllerStateAndHelpers<any>
  searchBoxService: Interpreter<
    SearchBoxContext,
    any,
    SearchBoxEvent,
    SearchBoxState
  >
  textPlaceholder: string
}

const SearchBoxDesktop = forwardRef(function SearchBoxDesktop(
  {
    downshift,
    searchBoxService,
    textPlaceholder,
    ...props
  }: SearchBoxDesktopProps,
  ref: any
) {
  const [searchBoxState] = useActor(searchBoxService)

  return (
    <Box flex={1} h="full" position="relative" ref={ref} {...props}>
      <SearchBoxInput
        downshift={downshift}
        searchBoxService={searchBoxService}
        textPlaceholder={textPlaceholder}
      />
      {downshift.isOpen && searchBoxState.context.results.length > 0 && (
        <SearchBoxResultList
          borderBottomRadius="sm"
          borderTop="2px solid"
          borderTopColor="primary.500"
          boxShadow="sm"
          downshift={downshift}
          maxH="32rem"
          searchBoxService={searchBoxService}
        />
      )}
    </Box>
  )
})

type SearchBoxMobileProps = BoxProps & {
  downshift: ControllerStateAndHelpers<any>
  searchBoxService: Interpreter<
    SearchBoxContext,
    any,
    SearchBoxEvent,
    SearchBoxState
  >
  textPlaceholder: string
}

const SearchBoxMobile = forwardRef(function SearchBoxMobile(
  {
    downshift,
    searchBoxService,
    textPlaceholder,
    ...props
  }: SearchBoxMobileProps,
  ref: any
) {
  const [expanded, setExpanded] = useState(false)
  const [searchBoxState] = useActor(searchBoxService)

  return (
    <>
      <IconButton
        aria-label="Search"
        colorScheme="primary"
        size="sm"
        onClick={() => setExpanded(true)}
      >
        <FontAwesomeIcon icon={farSearch} />
      </IconButton>
      {expanded && (
        <Portal>
          <RemoveScroll allowPinchZoom>
            <Flex
              bg="white"
              bottom={0}
              direction="column"
              left={0}
              overflow="hidden"
              position="fixed"
              ref={ref}
              right={0}
              top={0}
              zIndex={1}
              {...props}
            >
              <Flex
                align="center"
                borderBottom="2px solid"
                borderBottomColor="primary.500"
                h={16}
                overflow="hidden"
                w="full"
              >
                <IconButton
                  aria-label="Close search"
                  borderRight="1px solid"
                  borderRightColor="gray.100"
                  flexShrink={0}
                  h={16}
                  onClick={() => setExpanded(false)}
                  variant="unstyled"
                  w={16}
                  _focus={{boxShadow: `none`}}
                >
                  <FontAwesomeIcon icon={farArrowLeft} />
                </IconButton>
                <SearchBoxInput
                  downshift={downshift}
                  flex={1}
                  searchBoxService={searchBoxService}
                  textPlaceholder={textPlaceholder}
                />
              </Flex>

              {downshift.isOpen &&
                searchBoxState.context.results.length > 0 && (
                  <SearchBoxResultList
                    downshift={downshift}
                    searchBoxService={searchBoxService}
                  />
                )}
            </Flex>
          </RemoveScroll>
        </Portal>
      )}
    </>
  )
})

type SearchBoxInputProps = BoxProps & {
  downshift: ControllerStateAndHelpers<any>
  searchBoxService: Interpreter<
    SearchBoxContext,
    any,
    SearchBoxEvent,
    SearchBoxState
  >
  textPlaceholder: string
}

function SearchBoxInput({
  downshift: {getInputProps},
  searchBoxService,
  textPlaceholder,
  ...props
}: SearchBoxInputProps) {
  const [searchBoxState, sendSearchBox] = useActor(searchBoxService)

  return (
    <Box {...props} h="full" position="relative">
      {/* @ts-expect-error */}
      <Input
        {...getInputProps({
          onBlur: () => {
            sendSearchBox(`BLUR`)
          },
        })}
        border="none"
        borderRadius="none"
        h="inherit"
        placeholder={textPlaceholder}
        w="full"
        _focus={{border: `none`, boxShadow: `none`}}
      />
      {searchBoxState.matches(`searching`) && (
        <Flex
          position="absolute"
          right={4}
          top="50%"
          transform="translateY(-50%)"
          {...props}
        >
          <Spinner color="primary.500" size="sm" />
        </Flex>
      )}
    </Box>
  )
}

interface SearchBoxResultListProps extends BoxProps {
  downshift: ControllerStateAndHelpers<any>
  searchBoxService: Interpreter<
    SearchBoxContext,
    any,
    SearchBoxEvent,
    SearchBoxState
  >
}

function SearchBoxResultList({
  downshift: {getMenuProps, getItemProps, highlightedIndex},
  searchBoxService,
  ...props
}: SearchBoxResultListProps) {
  const [searchBoxState] = useActor(searchBoxService)

  return (
    <Box
      as="ul"
      bg="white"
      flex={1}
      mb={0}
      pl={0}
      overflow="auto"
      position="relative"
      zIndex={1}
      {...props}
      {...getMenuProps()}
    >
      {searchBoxState.context.results.map((item, index) => {
        return (
          <SearchBoxResultListItem
            isHighlighted={highlightedIndex === index}
            item={item}
            {...getItemProps({index, key: item.id, item})}
          />
        )
      })}
    </Box>
  )
}

type SearchBoxResultListItemProps = FlexProps &
  GetItemPropsOptions<any> & {
    isHighlighted: boolean
    item: SearchBoxResultListItem_item$key
  }

function SearchBoxResultListItem({
  isHighlighted,
  item: _item,
  ...props
}: SearchBoxResultListItemProps) {
  const item = useFragment(
    graphql`
      fragment SearchBoxResultListItem_item on SearchResultItem {
        __typename

        ... on Provider {
          ...Avatar_actor @arguments(size: 48)
          name
          city {
            name
          }
          games(first: 1, times: [UPCOMING]) {
            pageInfo {
              total
            }
          }
        }

        ... on User {
          ...Avatar_actor @arguments(size: 48)
          displayName
          city {
            name
          }
          games(first: 1, times: [UPCOMING]) {
            pageInfo {
              total
            }
          }
        }
      }
    `,
    _item
  )

  return (
    // @ts-expect-error
    <Flex
      {...props}
      as="li"
      align="center"
      bg={isHighlighted && `gray.100`}
      p={4}
    >
      <Avatar actor={item} borderRadius="full" />
      <Box mx={2} overflow="hidden">
        <Flex>
          <Box isTruncated color="gray.700" fontSize="lg" fontWeight="medium">
            {item.name ?? item.displayName}
          </Box>
          {item.city && (
            <Show above="sm">
              <Flex align="center" ml={4}>
                <FontAwesomeIcon icon={farMapMarkerAlt} size="sm" />
                <Box color="gray.500" fontSize="sm">
                  {item.city.name}
                </Box>
              </Flex>
            </Show>
          )}
        </Flex>
        <Box color="gray.500" fontSize="sm" fontStyle="italic">
          {item.games.pageInfo.total} upcoming{` `}
          {item.games.pageInfo.total > 0 ? `games` : `game`}
        </Box>
      </Box>
      <Badge ml="auto" variant="outline" colorScheme="gray">
        {ITEM_TYPE_LABEL_MAP[item.__typename] ?? item.__typename}
      </Badge>
    </Flex>
  )
}
