/* eslint-disable relay/unused-fields */

import {useState} from 'react'
import {graphql, useMutation} from 'react-relay/hooks'

import {Avatar, Box, Button, Checkbox, Flex, Text} from '@chakra-ui/react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {useIsYouth} from '../../context/YouthContext'

import CommentForm from '../CommentForm'
import CommentList from '../CommentList/CommentList'
import Time from '../Time'
import {getInitials} from '../../utils/getInitials'

import type {CommentListItemUpdateMutation} from './__generated__/CommentListItemUpdateMutation.graphql'

const AVATAR_SIZE = 32

export default function CommentListItem({comment, subject, viewer}) {
  const [commitCommentUpdate] = useMutation<CommentListItemUpdateMutation>(
    graphql`
      mutation CommentListItemUpdateMutation(
        $input: UpdateCommentHiddenStatusInput!
      ) {
        commentHiddenStatusUpdate(input: $input) {
          id
          isHidden
        }
      }
    `
  )

  const isYouth = useIsYouth()
  const [showReplyForm, setShowReplyForm] = useState(false)
  const {author, bodyHTML, comments, createdAt} = comment
  const isProvider = author.__typename === `Provider`

  const authorImage = isProvider ? (
    <Flex
      alignItems="center"
      bg="#ccc"
      borderRadius="50%"
      color="white"
      display="flex"
      height={10}
      justifyContent="center"
      width={10}
    >
      <FontAwesomeIcon fixedWidth icon={[`fal`, `bullhorn`]} />
    </Flex>
  ) : (
    <Avatar src={author.avatarUrl} size="sm" />
  )

  return (
    (!comment.isHidden || viewer.canHideComments) && (
      <Box mt={2}>
        <Box
          bg={comment.isHidden ? `gray.200` : `white`}
          borderColor="gray.100"
          borderRadius="md"
          borderWidth={1}
          boxShadow="sm"
          p={2}
        >
          <Box justifyContent="space-between" display="flex">
            <Box alignItems="center" display="flex">
              {authorImage}

              <Text fontSize="md" fontWeight="semibold" lineHeight={0} ml={2}>
                {isProvider
                  ? `Administrator`
                  : isYouth
                  ? getInitials(author.name)
                  : author.name}
              </Text>
            </Box>

            {viewer.canHideComments && (
              <Checkbox isChecked={comment.isHidden} onChange={handleChange}>
                Hide
              </Checkbox>
            )}
          </Box>

          <Box
            css={{
              marginLeft: `${AVATAR_SIZE + 8}px`,
            }}
          >
            <Text
              dangerouslySetInnerHTML={{__html: bodyHTML}}
              fontSize="md"
              mt={2}
              css={{
                whiteSpace: `pre-line`,
              }}
            />

            <Flex mt={3} alignItems="flex-end" justify="space-between">
              {viewer && (
                <Button
                  mr={3}
                  size="sm"
                  colorScheme="primary"
                  onClick={() => setShowReplyForm((value) => !value)}
                >
                  {comments?.edges.length > 0
                    ? `${comments.edges.length} Replies`
                    : `Reply`}
                </Button>
              )}
              <Text fontSize="xs">
                <Time relative value={createdAt} />
              </Text>
            </Flex>

            {viewer && showReplyForm && (
              <CommentForm
                replyToComment={comment}
                subject={subject}
                viewer={viewer}
                onSubmit={() => setShowReplyForm(false)}
              />
            )}
          </Box>
        </Box>

        {comments && (
          <Box ml={4}>
            <CommentList
              comments={comments.edges.map(({node}) => node)}
              subject={subject}
              viewer={viewer}
            />
          </Box>
        )}
      </Box>
    )
  )

  function handleChange() {
    commitCommentUpdate({
      variables: {
        input: {
          id: comment.id,
          isHidden: comment.isHidden,
        },
      },
    })
  }
}
