import {createMachine, interpret} from 'xstate'

import {assign} from '@xstate/immer'

import {OVERLAY_COMPONENTS} from '../overlays'

type OverlayType = 'drawer' | 'modal'

export const overlayMachine = createMachine(
  {
    id: 'overlay',
    initial: 'closed',
    schema: {
      context: {} as {
        overlay?: string
        overlays: string[]
        overlayType?: OverlayType
        props: any
        skeleton?: any
      },
      events: {} as
        | {
            type: 'CLOSE'
          }
        | {
            overlay: string
            props?: any
            type: 'OPEN'
          },
    },
    tsTypes: {} as import('./overlayMachine.typegen').Typegen0,
    context: {
      overlay: undefined,
      overlays: Object.keys(OVERLAY_COMPONENTS),
      overlayType: undefined,
      props: {},
      skeleton: undefined,
    },
    states: {
      closed: {
        on: {
          OPEN: {
            actions: 'setContext',
            cond: 'isValidPayload',
            target: 'opened',
          },
        },
      },

      closing: {
        after: {
          250: 'closed',
        },
      },

      opened: {
        on: {
          CLOSE: [
            {
              cond: 'isModal',
              target: 'closed',
            },
            {
              target: 'closing',
            },
          ],
        },
      },
    },
  },
  {
    actions: {
      setContext: assign((ctx, e) => {
        if (e.type === 'OPEN') {
          ctx.overlay = e.overlay
          ctx.overlayType = inferOverlayType(e.overlay)
          ctx.props = e.props ?? {}
        }
      }),
    },
    guards: {
      isModal: (ctx) => ctx.overlayType === 'modal',
      // @ts-ignore
      isValidPayload: (ctx, {overlay}) => ctx.overlays.includes(overlay),
    },
  }
)

function inferOverlayType(overlay: string): OverlayType {
  if (overlay.endsWith('DRAWER')) {
    return 'drawer'
  }

  if (overlay.endsWith('MODAL')) {
    return 'modal'
  }

  return undefined
}

export const overlayService = interpret(overlayMachine).start()
