import React from 'react'
import {graphql, useFragment} from 'react-relay/hooks'
import {Box} from '@chakra-ui/react'

import {Title_game$key} from './__generated__/Title_game.graphql'
import {TitleWaitlist_game$key} from './__generated__/TitleWaitlist_game.graphql'

interface TitleProps {
  game: Title_game$key
  isCancelled?: boolean
}

export function Title(props: TitleProps) {
  const {sport, title} = useFragment(
    graphql`
      fragment Title_game on Game {
        title
        sport {
          name
        }
      }
    `,
    props.game
  )

  return (
    <Box
      isTruncated
      color={props.isCancelled ? 'gray.500' : 'primary.500'}
      fontSize="lg"
    >
      {title ?? sport.name}
      {` `}
      {props.isCancelled ? (
        <Box as="span" fontWeight="semibold" color="red.500">
          Cancelled
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

interface TitleWaitlistProps {
  game: TitleWaitlist_game$key
}
export function TitleWaitlist(props: TitleWaitlistProps) {
  const {sport, title} = useFragment(
    graphql`
      fragment TitleWaitlist_game on Game {
        title
        sport {
          name
        }
      }
    `,
    props.game
  )

  return (
    <Box
      isTruncated
      color="whiteAlpha.900"
      fontWeight="semibold"
      textTransform="uppercase"
      fontSize="14px"
    >
      {title ?? sport.name}
    </Box>
  )
}
