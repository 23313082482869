import React from 'react'
import {graphql, useFragment} from 'react-relay/hooks'
import {Box} from '@chakra-ui/react'
import {faMapMarkerAlt as farMapMarkerAlt} from '@fortawesome/pro-regular-svg-icons'

import {LocationText_location$key} from './__generated__/LocationText_location.graphql'

import {FontAwesomeIcon} from 'components/FontAwesomeIcon'
import {LocationTextWaitlist_location$key} from './__generated__/LocationTextWaitlist_location.graphql'

export interface LocationTextProps {
  location: LocationText_location$key
  isHockey: boolean
  isCancelled?: boolean
}

export function LocationText({
  location,
  isHockey,
  isCancelled,
}: LocationTextProps) {
  const {facility, isOutdoor, name} = useFragment(
    graphql`
      fragment LocationText_location on Location {
        name
        isOutdoor
        facility {
          name
        }
      }
    `,
    location
  )

  return (
    <Box fontSize="sm" lineHeight="none">
      <Box as="span" color={isCancelled ? 'gray.500' : 'primary.500'}>
        <FontAwesomeIcon icon={farMapMarkerAlt} />
      </Box>
      {` `}
      <span>{facility ? `${facility.name} - ${name}` : `${name}`}</span>
      {` `}
      {!isHockey && (
        <Box as="span" color="gray.400" fontStyle="italic">
          {`(${isOutdoor ? `Outdoor` : `Indoor`})`}
        </Box>
      )}
    </Box>
  )
}

export interface LocationTextWaitlistProps {
  location: LocationTextWaitlist_location$key
}

export function LocationTextWaitlist({location}: LocationTextWaitlistProps) {
  const {facility, name} = useFragment(
    graphql`
      fragment LocationTextWaitlist_location on Location {
        name
        facility {
          name
        }
      }
    `,
    location
  )

  return (
    <Box
      fontSize={{base: '11px', sm: '14'}}
      color="white"
      lineHeight="none"
      textTransform="uppercase"
      fontWeight="semibold"
      margin="0"
    >
      {facility ? `${facility.name} - ${name}` : `${name}`}
    </Box>
  )
}
