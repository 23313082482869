import {Box, BoxProps} from '@chakra-ui/react'

interface SpacerProps extends BoxProps {}

export function Spacer({height, width, ...props}: SpacerProps) {
  return (
    <Box
      aria-hidden
      as="span"
      className="spacer"
      display="block"
      height={height}
      minHeight={height ?? 'px'}
      minWidth={width ?? 'px'}
      userSelect="none"
      width={width}
      {...props}
    />
  )
}
