interface Props {
  fill?: string
  icon: string
  size?: number
}

export function LocalIcon({fill, icon, size = 24}: Props) {
  return (
    <svg color={fill} fill={fill ?? 'currentColor'} height={size} width={size}>
      <use xlinkHref={`/static/sprites.svg#${icon}`} />
    </svg>
  )
}
