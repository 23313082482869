/**
 * @generated SignedSource<<2eb97005bb3c9a80d81680a7a44d8907>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Title_game$data = {
  readonly title: string | null;
  readonly sport: {
    readonly name: string;
  };
  readonly " $fragmentType": "Title_game";
};
export type Title_game$key = {
  readonly " $data"?: Title_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"Title_game">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Title_game",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sport",
      "kind": "LinkedField",
      "name": "sport",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Game",
  "abstractKey": null
};

(node as any).hash = "677d5392dbd825e8d223eca0d3d0907e";

export default node;
