import {useEffect, useState} from 'react'
import {
  fetchQuery,
  graphql,
  useFragment,
  useRelayEnvironment,
} from 'react-relay/hooks'
import {Box, useTheme} from '@chakra-ui/react'

import {ProgressBar_game$key} from './__generated__/ProgressBar_game.graphql'
import pusher from 'utils/Pusher'

export interface ProgressBarProps {
  game: ProgressBar_game$key
  gameType: `compact` | `full`
  changeToWaitlistItem?: () => any
}

export function ProgressBar({
  game: _game,
  gameType,
  changeToWaitlistItem,
}: ProgressBarProps) {
  const relayEnvironment = useRelayEnvironment()
  const theme = useTheme()
  const {minimumPlayers, numberOfTeams, players, template, id, __id} =
    useFragment(
      graphql`
        fragment ProgressBar_game on Game {
          __id
          # eslint-disable-next-line relay/unused-fields
          gameIsFull
          id
          minimumPlayers
          numberOfTeams
          players(first: 1) {
            pageInfo {
              total
            }
          }
          template {
            positions(first: 1) {
              pageInfo {
                total
              }
            }
          }
        }
      `,
      _game
    )

  const [playersCount, setPlayersCount] = useState(players.pageInfo.total)
  //   const playersCount = players.pageInfo.total
  const templatesCount = template.positions.pageInfo.total
  const maximumNumberOfPlayers = templatesCount * numberOfTeams
  const progress = (playersCount / templatesCount) * (100 / numberOfTeams)

  const height = gameType === `compact` ? 2 : 5
  const bg =
    minimumPlayers > 0 && minimumPlayers > playersCount
      ? `red.500`
      : `primary.500`

  useEffect(() => {
    const channelId = window.atob(id).replace('Game:', 'game-')
    const channel = pusher.subscribe(channelId)

    channel.bind(
      'App\\Events\\Positions\\CancelPosition',
      ({players_count}) => {
        setPlayersCount(players_count)
      }
    )
    channel.bind('App\\Events\\Positions\\PositionBuyed', ({players_count}) => {
      setPlayersCount(players_count)
    })

    return () => {
      channel.unbind('.App\\Events\\Positions\\CancelPosition')
      channel.unbind('.App\\Events\\Positions\\PositionBuyed')
      pusher.unsubscribe(channelId)
    }
  }, [id])

  useEffect(() => {
    if (progress === 100) {
      fetchQuery(
        relayEnvironment,
        graphql`
          query ProgressBarGameQuery($gameId: ID!) {
            node(id: $gameId) {
              ...ProgressBar_game
            }
          }
        `,
        {
          gameId: __id,
        },
        {
          networkCacheConfig: {
            force: true,
          },
        }
      ).toPromise()
    }
  }, [__id, progress, relayEnvironment])

  return (
    <Box bg="gray.200" h={height} overflow="hidden">
      <Box
        aria-valuemax={100}
        aria-valuemin={0}
        aria-valuenow={progress}
        bg={bg}
        borderBottomLeftRadius="sm"
        borderBottomRightRadius="sm"
        h="full"
        role="progressbar"
        style={{width: `${progress}%`}}
      >
        {gameType === `full` && (
          <Box
            color="white"
            fontSize="xs"
            fontWeight="medium"
            lineHeight={theme.sizes[5]}
            pl={4}
            textTransform="uppercase"
            whiteSpace="nowrap"
          >
            {playersCount} of {maximumNumberOfPlayers} players
          </Box>
        )}
      </Box>
    </Box>
  )
}
