import {ReactNode} from 'react'

import type {CheckboxProps as ChakraCheckboxProps} from '@chakra-ui/react'
import {
  Box,
  Stack,
  useCheckbox,
  useCheckboxGroupContext,
} from '@chakra-ui/react'
import {callAll} from '@chakra-ui/utils'

interface CheckboxProps extends ChakraCheckboxProps {
  children: ReactNode
}

export function Checkbox({
  children,
  isChecked: isCheckedProp,
  isDisabled,
  onChange: onChangeProp,
  ...rest
}: CheckboxProps) {
  const {value} = rest
  const group = useCheckboxGroupContext()

  let isChecked = isCheckedProp

  if (group?.value && value) {
    isChecked = group.value.includes(value)
  }

  let onChange = onChangeProp

  if (group?.onChange && value) {
    onChange = callAll(group.onChange, onChangeProp)
  }

  const {getInputProps, getCheckboxProps, getLabelProps, getRootProps} =
    useCheckbox({
      ...rest,
      isDisabled: group?.isDisabled ?? isDisabled,
      isChecked,
      onChange,
    })

  return (
    <Box as="label" cursor="pointer" {...getRootProps()}>
      <input {...getInputProps({})} />
      <Stack
        {...getCheckboxProps()}
        bgColor="gray.100"
        border="1px solid"
        borderColor="gray.50"
        borderRadius="md"
        boxShadow="base"
        paddingBlock="3"
        paddingInline="4"
        _checked={{
          bgColor: `primary.100`,
          borderColor: `primary.300`,
          boxShadow: `none`,
          _hover: {
            bgColor: `primary.200`,
          },
        }}
        _focus={{
          boxShadow: `outline`,
        }}
        _hover={{
          bgColor: `gray.100`,
        }}
      >
        <Box
          {...getLabelProps()}
          fontSize="sm"
          fontWeight="medium"
          lineHeight="none"
          textAlign="center"
        >
          {children}
        </Box>
      </Stack>
    </Box>
  )
}
