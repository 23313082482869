import {Button, ButtonProps, Icon, Text} from '@chakra-ui/react'
import {faEnvelope} from '@fortawesome/pro-regular-svg-icons'
import {useActor, useMachine} from '@xstate/react'
import {FontAwesomeIcon} from 'components/FontAwesomeIcon'
import {ModalService} from 'components/ModalRoot'
import {graphql, useFragment, useLazyLoadQuery, useMutation} from 'react-relay'
import {wrapEvent} from 'utils'
import {InterpreterFrom} from 'xstate'
import {gameRemindButtonMachine} from './gameRemindButtonMachine'
import {
  RemindButtonJoinMutation,
  RemindButtonJoinMutation$data,
} from './__generated__/RemindButtonJoinMutation.graphql'
import {
  RemindButtonLeaveMutation,
  RemindButtonLeaveMutation$data,
} from './__generated__/RemindButtonLeaveMutation.graphql'
import {RemindButtonViewerQuery} from './__generated__/RemindButtonViewerQuery.graphql'
import {RemindButton_game$key} from './__generated__/RemindButton_game.graphql'

export interface RemindButtonProps extends Omit<ButtonProps, 'children'> {
  game: RemindButton_game$key
  isYouth: boolean
}
export function RemindButton({game: _game, ...props}: RemindButtonProps) {
  const {id: gameId, viewerIsOnRemindMeList} = useFragment(
    graphql`
      fragment RemindButton_game on Game {
        id
        viewerIsOnRemindMeList
      }
    `,
    _game
  )

  const {viewer} = useLazyLoadQuery<RemindButtonViewerQuery>(
    graphql`
      query RemindButtonViewerQuery {
        viewer {
          id
        }
      }
    `,
    {}
  )

  const [joinRemindListCommit] = useMutation<RemindButtonJoinMutation>(
    graphql`
      mutation RemindButtonJoinMutation($input: JoinRemindListInput!) {
        joinRemindList(input: $input) {
          game {
            id
            viewerIsOnRemindMeList
          }
          user {
            id
            hideRemindModal
          }
        }
      }
    `
  )

  const [leaveRemindListCommit] = useMutation<RemindButtonLeaveMutation>(
    graphql`
      mutation RemindButtonLeaveMutation($input: LeaveRemindListInput!) {
        leaveRemindList(input: $input) {
          game {
            viewerIsOnRemindMeList
          }
        }
      }
    `
  )

  const [, , gameRemindButtonService] = useMachine(gameRemindButtonMachine, {
    actions: {
      checkModalRemindList: (ctx, e) => {
        if (!e.data.joinRemindList.user.hideRemindModal) {
          ModalService.send('OPEN', {
            modal: 'REMIND_EXPLANATION_MODAL',
          })
        }
      },
    },
    services: {
      joinRemindList: (ctx, e) => {
        return new Promise<RemindButtonJoinMutation$data>(function (
          resolve,
          reject
        ) {
          joinRemindListCommit({
            variables: {input: {gameId: e.id}},
            onCompleted(response) {
              resolve(response)
            },
            onError(error) {
              reject(error)
            },
            optimisticResponse: {
              joinRemindList: {
                game: {
                  id: gameId,
                  viewerIsOnRemindMeList: true,
                },
                user: {
                  id: viewer.id,
                  hideRemindModal: true || false,
                },
              },
            },
          })
        })
      },
      leaveRemindList: (ctx, e) => {
        return new Promise<RemindButtonLeaveMutation$data>(function (
          resolve,
          reject
        ) {
          leaveRemindListCommit({
            variables: {input: {gameId: e.id}},
            onCompleted(response) {
              resolve(response)
            },
            onError(error) {
              reject(error)
            },
            optimisticResponse: {
              leaveRemindList: {
                game: {
                  id: gameId,
                  viewerIsOnRemindMeList: false,
                },
              },
            },
          })
        })
      },
    },
  })

  if (viewerIsOnRemindMeList) {
    return (
      <LeaveRemindButton
        {...props}
        isYouth={props.isYouth}
        gameId={gameId}
        gameRemindButtonService={gameRemindButtonService}
      />
    )
  }

  return (
    <JoinRemindButton
      isYouth={props.isYouth}
      {...props}
      gameId={gameId}
      gameRemindButtonService={gameRemindButtonService}
    />
  )
}

interface JoinRemindButtonProps extends Omit<RemindButtonProps, 'game'> {
  gameId: string
  isYouth: boolean
  gameRemindButtonService: InterpreterFrom<typeof gameRemindButtonMachine>
}

function JoinRemindButton({
  onClick,
  gameId,
  gameRemindButtonService,
  isYouth,
  ...props
}: JoinRemindButtonProps) {
  const [, sendGameRemindList] = useActor(gameRemindButtonService)

  return (
    <Button
      width={{base: '100px', md: '108px'}}
      height="8"
      backgroundColor={'whiteAlpha.900'}
      paddingInline={{base: '3rem', md: '3.4rem'}}
      sx={{
        _hover: {
          backgroundColor: isYouth ? '#302f59' : 'green.500',
          color: 'whiteAlpha.900',
        },
      }}
      color={isYouth ? '#302f59' : 'green.500'}
      fontSize={{base: '12px', md: '14px'}}
      onClick={wrapEvent(onClick, () => {
        sendGameRemindList({type: 'JOIN_REMIND_LIST', id: gameId})
      })}
      {...props}
    >
      <FontAwesomeIcon icon={faEnvelope} />
      <Text marginInlineStart="1">Remind Me</Text>
    </Button>
  )
}

interface LeaveRemindButtonProps extends Omit<RemindButtonProps, 'game'> {
  gameId: string
  isYouth: boolean
  gameRemindButtonService: InterpreterFrom<typeof gameRemindButtonMachine>
}

function LeaveRemindButton({
  onClick,
  isYouth,
  gameId,
  gameRemindButtonService,
  ...props
}: LeaveRemindButtonProps) {
  const [, sendGameRemindList] = useActor(gameRemindButtonService)

  return (
    <Button
      padding="0"
      width={{base: '100px', md: '108px'}}
      height="8"
      paddingInline={{base: '3rem', md: '3.4rem'}}
      sx={{
        _hover: {
          backgroundColor: 'whiteAlpha.900',
          color: isYouth ? '#302f59' : 'green.500',
        },
      }}
      backgroundColor={isYouth ? '#302f59' : 'green.500'}
      color="whiteAlpha.900"
      fontSize={{base: '12px', md: '14px'}}
      onClick={wrapEvent(onClick, () => {
        sendGameRemindList({type: 'LEAVE_REMIND_LIST', id: gameId})
      })}
      {...props}
    >
      <Icon viewBox="0 0 640 512" color="" height={4} width={4}>
        <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4l217.6 163.2c11.4 8.5 27 8.5 38.4 0l57.4-43c23.9-59.8 79.7-103.3 146.3-109.8l13.9-10.4c12.1-9.1 19.2-23.3 19.2-38.4 0-26.5-21.5-48-48-48H48zm246.4 275.2a63.9 63.9 0 0 1-76.8 0L0 176v208c0 35.3 28.7 64 64 64h296.2c-25.1-30.4-40.2-69.5-40.2-112 0-5.6.3-11.1.8-16.6l-26.4 19.8zM640 336a144 144 0 1 0-288 0 144 144 0 1 0 288 0zm-76.7-43.3c6.2 6.2 6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0l28.7 28.7 60.7-60.7c6.2-6.2 16.4-6.2 22.6 0z" />
      </Icon>
      <Text marginInlineStart="1">Reminder</Text>
    </Button>
  )
}
