/**
 * @generated SignedSource<<9f9864954af6c473e4ed1829d2824329>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateLocationInput = {
  address?: string | null;
  cityId: string;
  facilityId?: string | null;
  name: string;
  locationId: string;
  latitude: number;
  longitude: number;
  hasWashRoom: boolean;
  hasShower: boolean;
  hasChangeRoom: boolean;
  isOutdoor: boolean;
  hasOutdoorLight: boolean;
  surfaceId: string;
};
export type EditLocationFormModalMutation$variables = {
  input: UpdateLocationInput;
};
export type EditLocationFormModalMutation$data = {
  readonly updateLocation: {
    readonly address: string | null;
    readonly id: string;
    readonly hasChangeRoom: boolean;
    readonly isOutdoor: boolean;
    readonly hasOutdoorLight: boolean;
    readonly hasShower: boolean;
    readonly hasWashRoom: boolean;
    readonly latitude: number;
    readonly longitude: number;
    readonly name: string;
    readonly city: {
      readonly value: string;
      readonly label: string;
    } | null;
    readonly facility: {
      readonly value: string;
      readonly label: string;
    } | null;
    readonly surface: {
      readonly value: string;
      readonly label: string | null;
    } | null;
  } | null;
};
export type EditLocationFormModalMutation = {
  variables: EditLocationFormModalMutation$variables;
  response: EditLocationFormModalMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasChangeRoom",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOutdoor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasOutdoorLight",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasShower",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasWashRoom",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latitude",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longitude",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = [
  (v12/*: any*/),
  (v13/*: any*/)
],
v15 = [
  (v12/*: any*/),
  (v13/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditLocationFormModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Location",
        "kind": "LinkedField",
        "name": "updateLocation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "City",
            "kind": "LinkedField",
            "name": "city",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Facility",
            "kind": "LinkedField",
            "name": "facility",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Surface",
            "kind": "LinkedField",
            "name": "surface",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditLocationFormModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Location",
        "kind": "LinkedField",
        "name": "updateLocation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "City",
            "kind": "LinkedField",
            "name": "city",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Facility",
            "kind": "LinkedField",
            "name": "facility",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Surface",
            "kind": "LinkedField",
            "name": "surface",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d1897982ebaa620651900df14d5e2cb",
    "id": null,
    "metadata": {},
    "name": "EditLocationFormModalMutation",
    "operationKind": "mutation",
    "text": "mutation EditLocationFormModalMutation(\n  $input: UpdateLocationInput!\n) {\n  updateLocation(input: $input) {\n    address\n    id\n    hasChangeRoom\n    isOutdoor\n    hasOutdoorLight\n    hasShower\n    hasWashRoom\n    latitude\n    longitude\n    name\n    city {\n      value: id\n      label: name\n      id\n    }\n    facility {\n      value: id\n      label: name\n      id\n    }\n    surface {\n      value: id\n      label: name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eaec6f2e7906507669d097259badab42";

export default node;
