import {format, parseISO} from 'date-fns'
import React from 'react'
import {graphql, useFragment} from 'react-relay/hooks'
import {Box, Flex, useTheme} from '@chakra-ui/react'
import {faCalendar as fadCalendar} from '@fortawesome/pro-duotone-svg-icons'

import {CalendarIcon_game$key} from './__generated__/CalendarIcon_game.graphql'

import {FontAwesomeIcon} from 'components/FontAwesomeIcon'

export interface CalendarIconProps {
  game: CalendarIcon_game$key
  isCancelled?: boolean
}

export function CalendarIcon(props: CalendarIconProps) {
  const theme = useTheme()
  const {startTime} = useFragment(
    graphql`
      fragment CalendarIcon_game on Game {
        startTime
      }
    `,
    props.game
  )

  return (
    <Flex align="flex-end" h={12} justify="center" position="relative" w={12}>
      <Box
        as={FontAwesomeIcon}
        color={props.isCancelled ? '#ca4c46' : 'primary.500'}
        fontSize="3rem"
        icon={fadCalendar}
        style={
          props.isCancelled
            ? // @ts-expect-error
              {'--fa-secondary-color': theme.colors.gray[100]}
            : {'--fa-secondary-color': theme.colors.gray[200]}
        }
      />
      <Box
        bottom="2px"
        fontSize="xs"
        left={0}
        lineHeight="none"
        position="absolute"
        right={0}
        textAlign="center"
      >
        <Box>{format(parseISO(startTime), `d`)}</Box>
        <Box>{format(parseISO(startTime), `E`)}</Box>
      </Box>
    </Flex>
  )
}
