import {lazy} from 'react'

export const OVERLAY_COMPONENTS = {
  // modals
  CREATE_CHILD_MODAL: lazy(
    () =>
      import(
        `pages/site/Hub/components/Sidebar/components/Youth/components/CreateChildModal`
      )
  ),
}
