import Checkbox from './checkbox'
import Input from './input'
import Modal from './modal'
import Radio from './radio'
import Skeleton from './skeleton'

export default {
  Checkbox,
  Input,
  Modal,
  Radio,
  Skeleton,
}
