/**
 * @generated SignedSource<<ad8f42daefd5a0417f8e31d22317a923>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PositionType = "GOALIE" | "PLAYER" | "%future added value";
export type JoinWaitlistInput = {
  gameId: string;
  positionType?: PositionType | null;
};
export type WaitlistButtonJoinMutation$variables = {
  input: JoinWaitlistInput;
};
export type WaitlistButtonJoinMutation$data = {
  readonly joinWaitingList: {
    readonly game: {
      readonly id: string;
      readonly viewerIsOnWaitingList: boolean;
    } | null;
    readonly user: {
      readonly id: string;
      readonly hideWaitlistModal: boolean;
    } | null;
  } | null;
};
export type WaitlistButtonJoinMutation = {
  variables: WaitlistButtonJoinMutation$variables;
  response: WaitlistButtonJoinMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "JoinWaitingListPayload",
    "kind": "LinkedField",
    "name": "joinWaitingList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Game",
        "kind": "LinkedField",
        "name": "game",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerIsOnWaitingList",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hideWaitlistModal",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WaitlistButtonJoinMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WaitlistButtonJoinMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "07b8ab26d2c85a43528a1f3a5c12bbe8",
    "id": null,
    "metadata": {},
    "name": "WaitlistButtonJoinMutation",
    "operationKind": "mutation",
    "text": "mutation WaitlistButtonJoinMutation(\n  $input: JoinWaitlistInput!\n) {\n  joinWaitingList(input: $input) {\n    game {\n      id\n      viewerIsOnWaitingList\n    }\n    user {\n      id\n      hideWaitlistModal\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "539ba9056f9dd1b259fe9f76317f9029";

export default node;
