/**
 * @generated SignedSource<<f085e538fa3dac5fbdb1d40c49b95150>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgressBar_game$data = {
  readonly __id: string;
  readonly gameIsFull: boolean;
  readonly id: string;
  readonly minimumPlayers: number;
  readonly numberOfTeams: number;
  readonly players: {
    readonly pageInfo: {
      readonly total: number;
    };
  };
  readonly template: {
    readonly positions: {
      readonly pageInfo: {
        readonly total: number;
      };
    };
  };
  readonly " $fragmentType": "ProgressBar_game";
};
export type ProgressBar_game$key = {
  readonly " $data"?: ProgressBar_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgressBar_game">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgressBar_game",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gameIsFull",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimumPlayers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfTeams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "players",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "players(first:1)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GameTemplate",
      "kind": "LinkedField",
      "name": "template",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "GameTemplatePositionConnection",
          "kind": "LinkedField",
          "name": "positions",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "positions(first:1)"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__id",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Game",
  "abstractKey": null
};
})();

(node as any).hash = "ae0ec6649653724e6f0c8122187a32d2";

export default node;
