/**
 * @generated SignedSource<<1366f5d37e1b49be88de62acdb198fa1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WaitlistButtonViewerQuery$variables = {};
export type WaitlistButtonViewerQuery$data = {
  readonly viewer: {
    readonly id: string;
  } | null;
};
export type WaitlistButtonViewerQuery = {
  variables: WaitlistButtonViewerQuery$variables;
  response: WaitlistButtonViewerQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WaitlistButtonViewerQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WaitlistButtonViewerQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9ee701b52fab91314051e6ac933d6c30",
    "id": null,
    "metadata": {},
    "name": "WaitlistButtonViewerQuery",
    "operationKind": "query",
    "text": "query WaitlistButtonViewerQuery {\n  viewer {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ad217b8ffaa7a3c531bd8ec6423b59a3";

export default node;
