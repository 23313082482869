import React, {useState, useEffect} from 'react'
import {Box} from '@chakra-ui/react'
import {formatDuration, intervalToDuration, isAfter} from 'date-fns'
import {graphql, useFragment} from 'react-relay'
import {UpcomingCountdown_game$key} from './__generated__/UpcomingCountdown_game.graphql'

export interface UpcomingCountdownProps {
  game: UpcomingCountdown_game$key
  changeToNormalItem: () => void
}

export function UpcomingCountdown(props: UpcomingCountdownProps) {
  const {game, changeToNormalItem} = props
  const {unlockTimeTz} = useFragment(
    graphql`
      fragment UpcomingCountdown_game on Game {
        unlockTimeTz
      }
    `,
    game
  )

  const initialCountdown = calculateInitialCountdown(unlockTimeTz)

  const [countdown, setCountdown] = useState(initialCountdown)

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now()
      const targetDate = new Date(unlockTimeTz).getTime()

      if (isAfter(now, targetDate)) {
        clearInterval(intervalId)
        changeToNormalItem()
        return
      }

      const duration = intervalToDuration({start: now, end: targetDate})
      const formattedCountdown = formatDuration(duration, {
        format: ['days', 'hours', 'minutes', 'seconds'],
      })

      const countdownString = formatCountdownString(formattedCountdown)

      setCountdown(countdownString)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [unlockTimeTz, changeToNormalItem])

  return (
    <Box
      color="whiteAlpha.900"
      fontWeight="semibold"
      textTransform="uppercase"
      fontSize={{base: '12px', md: '14px'}}
      textAlign="center"
    >
      {countdown}
    </Box>
  )
}

function calculateInitialCountdown(unlockTimeTz: string): string {
  const now = Date.now()
  const targetDate = new Date(unlockTimeTz).getTime()
  const duration = intervalToDuration({start: now, end: targetDate})
  const formattedCountdown = formatDuration(duration, {
    format: ['days', 'hours', 'minutes', 'seconds'],
  })

  return formatCountdownString(formattedCountdown)
}

function formatCountdownString(formattedCountdown: string): string {
  const countdown = formattedCountdown
    .replace('days', 'D')
    .replace('day', 'D')
    .replace('hours', 'H')
    .replace('hour', 'H')
    .replace('minutes', 'M')
    .replace('minute', 'M')
    .replace('seconds', 'S')
    .replace('second', 'S')
    .replace(/\s+/g, '')
    .replace(/([DHMS])/g, '$1 ')

  const countdownString =
    countdown.includes('D') || countdown.includes('H')
      ? countdown.replace(/\d+S/g, '')
      : countdown

  return countdownString
}
