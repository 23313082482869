/**
 * @generated SignedSource<<c5f96794b545b96d2d782b002e74d583>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuPopoverBodyQuery$variables = {};
export type UserMenuPopoverBodyQuery$data = {
  readonly viewer: {
    readonly displayName: string;
    readonly resourcePath: string;
    readonly credits: ReadonlyArray<{
      readonly amount: number | null;
      readonly provider: {
        readonly name: string;
        readonly resourcePath: string;
      };
    }> | null;
    readonly profile: {
      readonly credits: number | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_actor">;
  } | null;
};
export type UserMenuPopoverBodyQuery = {
  variables: UserMenuPopoverBodyQuery$variables;
  response: UserMenuPopoverBodyQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourcePath",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "credits",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserMenuPopoverBodyQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": [
              {
                "kind": "Literal",
                "name": "size",
                "value": 48
              }
            ],
            "kind": "FragmentSpread",
            "name": "Avatar_actor"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Credit",
            "kind": "LinkedField",
            "name": "credits",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Provider",
                "kind": "LinkedField",
                "name": "provider",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserMenuPopoverBodyQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "maxHeight",
                        "value": 48
                      },
                      {
                        "kind": "Literal",
                        "name": "maxWidth",
                        "value": 48
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "transformedSrc",
                    "storageKey": "transformedSrc(maxHeight:48,maxWidth:48)"
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Actor",
            "abstractKey": "__isActor"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Credit",
            "kind": "LinkedField",
            "name": "credits",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Provider",
                "kind": "LinkedField",
                "name": "provider",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v1/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ddcc2626d9b440b4952ecf6e1a221640",
    "id": null,
    "metadata": {},
    "name": "UserMenuPopoverBodyQuery",
    "operationKind": "query",
    "text": "query UserMenuPopoverBodyQuery {\n  viewer {\n    displayName\n    resourcePath\n    ...Avatar_actor_w8mmD\n    credits {\n      amount\n      provider {\n        name\n        resourcePath\n        id\n      }\n      id\n    }\n    profile {\n      credits\n      id\n    }\n    id\n  }\n}\n\nfragment Avatar_actor_w8mmD on Actor {\n  __isActor: __typename\n  name\n  avatar {\n    transformedSrc(maxHeight: 48, maxWidth: 48)\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4e68d7dd116370b1edda611593d3308d";

export default node;
