const green = {
  50: `#f3f9e9`,
  100: `#e0f0c7`,
  200: `#cce6a3`,
  300: `#b7dc7d`,
  400: `#a6d45e`,
  500: `#96cc40`,
  600: `#86bc38`,
  700: `#71a82e`,
  800: `#5d9424`,
  900: `#377211`,
}

const purple = {
  50: `#e8e9f1`,
  100: `#c5c6dc`,
  200: `#9fa2c5`,
  300: `#7c7ead`,
  400: `#62639c`,
  500: `#4a498c`,
  600: `#444183`,
  700: `#3c3878`,
  800: `#342f6c`,
  900: `#271e58`,
}

const colors = {
  green,

  purple,

  primary: window.__ENV__?.IS_YOUTH_DOMAIN ? purple : green,

  gray: {
    50: `#f9fafb`,
    100: `#f4f5f7`,
    200: `#e5e7eb`,
    300: `#d2d6dc`,
    400: `#9fa6b2`,
    500: `#6b7280`,
    600: `#4b5563`,
    700: `#374151`,
    800: `#252f3f`,
    900: `#161e2e`,
  },

  indigo: {
    50: `#f0f5ff`,
    100: `#e5edff`,
    200: `#cddbfe`,
    300: `#b4c6fc`,
    400: `#8da2fb`,
    500: `#6875f5`,
    600: `#5850ec`,
    700: `#5145cd`,
    800: `#42389d`,
    900: `#362f78`,
  },
}

export default colors
