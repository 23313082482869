import {getHeaders, getRequestBody, handleData} from './helpers'

const {user, admin} = window.env

export default async function _fetch(
  operation,
  variables,
  cacheConfig,
  uploadables
) {
  const body = getRequestBody(operation, variables, uploadables)

  const response = await fetch(`${window.__ENV__.PICKUPHUB_GRAPHQL_URL}?operation=${operation.name ?? 'unknown'}`, {
    body,
    headers: {
      ...getHeaders(uploadables),
      Authorization: user
        ? `Bearer ${user.sanctum_api_token}`
        : admin && `Bearer ${admin.sanctum_api_token}`,
    },
    method: `POST`,
  })

  return handleData(response)
}
