/**
 * @generated SignedSource<<f3fedcbb5fcb7c56bf2af74841de4f59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgressBarGameQuery$variables = {
  gameId: string;
};
export type ProgressBarGameQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ProgressBar_game">;
  } | null;
};
export type ProgressBarGameQuery = {
  variables: ProgressBarGameQuery$variables;
  response: ProgressBarGameQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gameId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "gameId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProgressBarGameQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProgressBar_game"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProgressBarGameQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gameIsFull",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minimumPlayers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfTeams",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "UserConnection",
                "kind": "LinkedField",
                "name": "players",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "players(first:1)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GameTemplate",
                "kind": "LinkedField",
                "name": "template",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "GameTemplatePositionConnection",
                    "kind": "LinkedField",
                    "name": "positions",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": "positions(first:1)"
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "type": "Game",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "93897952912812318e4adbc8c461642e",
    "id": null,
    "metadata": {},
    "name": "ProgressBarGameQuery",
    "operationKind": "query",
    "text": "query ProgressBarGameQuery(\n  $gameId: ID!\n) {\n  node(id: $gameId) {\n    __typename\n    ...ProgressBar_game\n    id\n  }\n}\n\nfragment ProgressBar_game on Game {\n  gameIsFull\n  id\n  minimumPlayers\n  numberOfTeams\n  players(first: 1) {\n    pageInfo {\n      total\n    }\n  }\n  template {\n    positions(first: 1) {\n      pageInfo {\n        total\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c0eee0e7cfaa9fcb717372caaef43f6";

export default node;
