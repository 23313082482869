import React, {ReactNode} from 'react'
import {
  ErrorBoundary as BaseErrorBoundary,
  ErrorBoundaryPropsWithComponent,
} from 'react-error-boundary'

import * as Sentry from "@sentry/react";

type Props = ErrorBoundaryPropsWithComponent & {
  children: ReactNode
}

function EmptyFallbackComponent() {
  return null
}

export default function ErrorBoundary({
  children,
  FallbackComponent = EmptyFallbackComponent,
}: Props) {
  return (
    <BaseErrorBoundary
      children={children}
      FallbackComponent={FallbackComponent}
      onError={(error) => {
        if (process.env.NODE_ENV === `development`) {
          console.error(error)
        } else {
          Sentry.captureException(error)
        }
      }}
    />
  )
}
