/**
 * @generated SignedSource<<03588d3a645e229085b32f8ed283d7bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GoalieIcon_game$data = {
  readonly goaliesCount: number;
  readonly " $fragmentType": "GoalieIcon_game";
};
export type GoalieIcon_game$key = {
  readonly " $data"?: GoalieIcon_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoalieIcon_game">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalieIcon_game",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "goaliesCount",
      "storageKey": null
    }
  ],
  "type": "Game",
  "abstractKey": null
};

(node as any).hash = "788fc5c829f1c182381c7be0ecd9d447";

export default node;
