import {actions, createMachine} from 'xstate'
import {assign} from '@xstate/immer'

import {SearchBoxQuery$data} from './__generated__/SearchBoxQuery.graphql'
import {SearchResultType} from 'generated/graphql'
import {Draft} from 'immer'

const {cancel, send} = actions

export interface SearchBoxContext extends SearchBoxQuery$data {
  query: string
  searchType: Array<SearchResultType>
}

export type SearchBoxEvent =
  | {type: 'TYPE'; query: string; searchType: Array<SearchResultType>}
  | {type: 'SEARCH'}
  | {type: 'BLUR'}

export type SearchBoxState =
  | {context: SearchBoxContext; value: 'idle'}
  | {context: SearchBoxContext; value: 'searching'}

export const searchBoxMachine = createMachine(
  {
    id: 'searchBox',
    initial: 'idle',
    schema: {
      context: {} as SearchBoxContext,
      events: {} as SearchBoxEvent,
      services: {} as {
        search: {
          data: Draft<SearchBoxQuery$data>
        }
      },
    },
    tsTypes: {} as import('./searchBoxMachine.typegen').Typegen0,
    context: {
      query: '',
      searchType: [],
      results: [],
    },
    on: {
      BLUR: {actions: 'clearResults'},
    },
    states: {
      idle: {
        on: {
          TYPE: {
            actions: ['assignQuery', 'cancelSearch', 'sendSearchEvent'],
          },
          SEARCH: {
            cond: 'searchValid',
            target: 'searching',
          },
        },
      },

      searching: {
        on: {
          TYPE: {
            target: 'idle',
            actions: send<
              SearchBoxContext,
              {
                type: 'TYPE'
                query: string
                searchType: Array<SearchResultType>
              }
            >((ctx, e) => ({
              type: e.type,
              query: e.query,
              searchType: e.searchType,
            })),
          },
        },
        invoke: {
          id: 'search',
          src: 'search',
          onDone: {
            actions: 'setResults',
            target: 'idle',
          },
        },
      },
    },
  },
  {
    actions: {
      assignQuery: assign((ctx, e) => {
        ctx.query = e.query
        ctx.searchType = e.searchType
      }),
      cancelSearch: cancel('debouncedSearch'),
      sendSearchEvent: send('SEARCH', {
        delay: 250,
        id: 'debouncedSearch',
      }),

      setResults: assign((ctx, e) => {
        ctx.results = e.data.results
      }),
      clearResults: assign((ctx) => {
        ctx.results = []
      }),
    },
    guards: {
      searchValid: (ctx) => ctx.query.trim().length > 1,
    },
  }
)
