import {object, string, bool, number} from 'yup'

export const validationSchema = object().shape({
  address: string().required(),
  cityId: string().required(),
  facilityId: string(),
  hasChangeRoom: bool(),
  isOutdoor: bool(),
  hasOutdoorLight: bool(),
  hasShower: bool(),
  hasWashRoom: bool(),
  latitude: number().required(),
  longitude: number().required(),
  name: string().required(),
  surfaceId: string().required(),
  facility: object().shape({
    name: string(),
    address: string(),
    phoneNumber: string(),
    website: string(),
  }),
})
