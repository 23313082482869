/**
 * @generated SignedSource<<f5762c7bfea0aab2ff1416cdcf98e5da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RemindButtonViewerQuery$variables = {};
export type RemindButtonViewerQuery$data = {
  readonly viewer: {
    readonly id: string;
  } | null;
};
export type RemindButtonViewerQuery = {
  variables: RemindButtonViewerQuery$variables;
  response: RemindButtonViewerQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RemindButtonViewerQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RemindButtonViewerQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f20f887427a7631b9495787afa71c0dc",
    "id": null,
    "metadata": {},
    "name": "RemindButtonViewerQuery",
    "operationKind": "query",
    "text": "query RemindButtonViewerQuery {\n  viewer {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "059ea5e18b3cdefe5e67f7e14e4e5ebd";

export default node;
