/**
 * @generated SignedSource<<b65c0a279bef04fe08fe2fe9a27ff1c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CitySelectQuery$variables = {
  name?: string | null;
};
export type CitySelectQuery$data = {
  readonly cities: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly province: {
      readonly shortName: string;
    } | null;
  }>;
};
export type CitySelectQuery = {
  variables: CitySelectQuery$variables;
  response: CitySelectQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 30
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CitySelectQuery",
    "selections": [
      {
        "alias": "cities",
        "args": (v1/*: any*/),
        "concreteType": "City",
        "kind": "LinkedField",
        "name": "allCities",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Province",
            "kind": "LinkedField",
            "name": "province",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CitySelectQuery",
    "selections": [
      {
        "alias": "cities",
        "args": (v1/*: any*/),
        "concreteType": "City",
        "kind": "LinkedField",
        "name": "allCities",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Province",
            "kind": "LinkedField",
            "name": "province",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "36f4cff37ac27e9b5893f24120f08ff7",
    "id": null,
    "metadata": {},
    "name": "CitySelectQuery",
    "operationKind": "query",
    "text": "query CitySelectQuery(\n  $name: String\n) {\n  cities: allCities(name: $name, limit: 30) {\n    id\n    name\n    province {\n      shortName\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d6b0f23f8ad68ff79b728ba3016d4079";

export default node;
