/**
 * @generated SignedSource<<bf55c92491fa93cd5e82de5e290506c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Navigation_viewer$data = {
  readonly viewerIsManager: boolean;
  readonly " $fragmentType": "Navigation_viewer";
};
export type Navigation_viewer$key = {
  readonly " $data"?: Navigation_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Navigation_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Navigation_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerIsManager",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "4a8bda36c894e97b49c6da2af9f00a2d";

export default node;
