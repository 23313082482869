import React from 'react'
import ContentLoader from 'react-content-loader'

export default function FormLoader() {
  return (
    <ContentLoader
      speed={2}
      width={528}
      height={340}
      viewBox="0 0 528 340"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <circle cx="18" cy="327" r="11" />
      <rect x="4" y="240" rx="3" ry="3" width="244" height="40" />
      <rect x="2" y="152" rx="3" ry="3" width="528" height="40" />
      <circle cx="117" cy="326" r="11" />
      <circle cx="495" cy="326" r="11" />
      <circle cx="316" cy="327" r="11" />
      <rect x="280" y="238" rx="3" ry="3" width="244" height="40" />
      <rect x="-3" y="67" rx="3" ry="3" width="528" height="40" />
      <circle cx="216" cy="327" r="11" />
      <circle cx="416" cy="326" r="11" />
      <rect x="2" y="40" rx="3" ry="3" width="190" height="14" />
      <rect x="2" y="131" rx="3" ry="3" width="190" height="14" />
      <rect x="5" y="213" rx="3" ry="3" width="190" height="14" />
      <rect x="282" y="213" rx="3" ry="3" width="190" height="14" />
      <rect x="0" y="5" rx="3" ry="3" width="190" height="14" />
      <rect x="205" y="5" rx="3" ry="3" width="46" height="13" />
    </ContentLoader>
  )
}
