export default new (function () {
  this.base = 1 // z-index: auto content will go here or inherit z-index from a parent

  this.background = this.base - 1 // content that should always be behind other things
  this.hidden = this.base - 2 // this content should be hidden completely

  this.navBar = 3000 // navBar should appear at the same level

  this.fullscreen = 4000 // fullscreen elements should cover all screen content except toasts

  this.modal = 5000 // modals should completely cover base content and slider as well
  this.gallery = this.modal + 1 // gallery should never appear behind a modal

  this.toast = 6000 // toasts should be visible in every context
  this.tooltip = this.toast + 1 // tooltips should always be on top
})()
