import React from 'react'
import {Box, BoxProps, forwardRef} from '@chakra-ui/react'

interface HeadingProps extends BoxProps {
  fancy?: boolean
}

// eslint-disable-next-line quotes
export const Heading = forwardRef<HeadingProps, 'div'>(function Heading(
  {fancy, ...props},
  ref
) {
  return (
    <Box
      as="h1"
      color="gray.900"
      fontSize="2xl"
      fontWeight="thin"
      lineHeight="none"
      ref={ref}
      {...props}
      {...(fancy && {
        borderBottomColor: `gray.200`,
        borderBottomWidth: `2px`,
        pb: 2,
        position: `relative`,
        _after: {
          bg: `primary.500`,
          content: `""`,
          h: `2px`,
          left: 0,
          position: `absolute`,
          top: `100%`,
          w: 8,
        },
      })}
    />
  )
})
