import {Suspense} from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay'

import {Box, Heading} from '@chakra-ui/react'

import {LoadingPage, Spacer} from 'components'
import {GameFeed} from 'components/GameFeed'
import {useSiteEnvironmentContext} from 'hooks'

import type {ProviderPluginGamesProviderQuery} from './__generated__/ProviderPluginGamesProviderQuery.graphql'

export function ProviderPluginGamesWrapper() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ProviderPluginGames />
    </Suspense>
  )
}

function ProviderPluginGames() {
  const [, , , providerSlug, , sportSlug] = window.location.pathname.split(`/`)
  const {IS_YOUTH_DOMAIN} = useSiteEnvironmentContext()
  const {provider} = useLazyLoadQuery<ProviderPluginGamesProviderQuery>(
    graphql`
      query ProviderPluginGamesProviderQuery(
        $providerSlug: String!
        $youth: Boolean!
        $sportSlug: String
      ) {
        provider(slug: $providerSlug) {
          games(
            first: 10
            times: [UPCOMING]
            youth: $youth
            orderBy: {direction: ASC, field: START_TIME}
            sportSlug: $sportSlug
          ) {
            edges {
              node {
                ...GameFeed_games
              }
            }
          }
        }
      }
    `,
    {
      providerSlug,
      youth: IS_YOUTH_DOMAIN,
      sportSlug,
    }
  )

  return (
    <Box bgColor="gray.100" display="flex" p={3}>
      <Box flex={1} width="100%">
        <Heading as="h1" color="primary" size="md" textAlign="center">
          Upcoming {IS_YOUTH_DOMAIN ? ` Youth` : ``} Pickup Games
        </Heading>
        <Spacer height="4" />
        <GameFeed
          games={provider.games.edges.map(({node}) => node)}
          gameType="full"
        />
      </Box>
    </Box>
  )
}
