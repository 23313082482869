import {ReactNode, StrictMode, useEffect, useMemo} from 'react'
import {RelayEnvironmentProvider} from 'react-relay/hooks'
import {BrowserRouter as Router} from 'react-router-dom'
import {ChakraProvider} from '@chakra-ui/react'

import {theme} from 'chakra'
import {SiteEnvironmentProvider} from 'context/SiteEnvironmentContext'
import environment from 'relayEnvironment'

interface AppProps {
  children: ReactNode
  resetCSS?: boolean
}

export default function App({children, resetCSS}: AppProps) {
  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty(`--vh`, `${vh}px`)

    function handleResize() {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01

      document.documentElement.style.setProperty(`--vh`, `${vh}px`)
    }

    window.addEventListener(`resize`, handleResize)

    return () => window.removeEventListener(`resize`, handleResize)
  }, [])
  const siteEnv = useMemo(() => {
    return {
      ...window.__ENV__,
      IS_RUNNING_IN_IFRAME: window.self !== window.top,
    }
  }, [])

  return (
    <StrictMode>
      <Router>
        <RelayEnvironmentProvider
          // @ts-ignore
          environment={environment}
        >
          <ChakraProvider resetCSS={resetCSS} theme={theme}>
            <SiteEnvironmentProvider value={siteEnv}>
              {children}
            </SiteEnvironmentProvider>
          </ChakraProvider>
        </RelayEnvironmentProvider>
      </Router>
    </StrictMode>
  )
}
