/**
 * @generated SignedSource<<a768f784230d691f1383fe95b4d68c07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EditLocationFormModalQuery$variables = {
  locationId: string;
};
export type EditLocationFormModalQuery$data = {
  readonly facilities: ReadonlyArray<{
    readonly value: string;
    readonly label: string;
    readonly latitude: number;
    readonly longitude: number;
  }>;
  readonly surfaces: ReadonlyArray<{
    readonly value: string;
    readonly label: string | null;
  }>;
  readonly location: {
    readonly address: string | null;
    readonly hasChangeRoom: boolean;
    readonly isOutdoor: boolean;
    readonly hasOutdoorLight: boolean;
    readonly hasShower: boolean;
    readonly hasWashRoom: boolean;
    readonly latitude: number;
    readonly longitude: number;
    readonly name: string;
    readonly city: {
      readonly value: string;
      readonly label: string;
    } | null;
    readonly facility: {
      readonly value: string;
      readonly label: string;
    } | null;
    readonly surface: {
      readonly value: string;
      readonly label: string | null;
    } | null;
  } | null;
};
export type EditLocationFormModalQuery = {
  variables: EditLocationFormModalQuery$variables;
  response: EditLocationFormModalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locationId"
  }
],
v1 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latitude",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longitude",
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v6 = [
  {
    "kind": "Variable",
    "name": "locationId",
    "variableName": "locationId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasChangeRoom",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOutdoor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasOutdoorLight",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasShower",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasWashRoom",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v14/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditLocationFormModalQuery",
    "selections": [
      {
        "alias": "facilities",
        "args": null,
        "concreteType": "Facility",
        "kind": "LinkedField",
        "name": "allFacilities",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "surfaces",
        "args": null,
        "concreteType": "Surface",
        "kind": "LinkedField",
        "name": "allSurfaces",
        "plural": true,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Location",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "City",
            "kind": "LinkedField",
            "name": "city",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Facility",
            "kind": "LinkedField",
            "name": "facility",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Surface",
            "kind": "LinkedField",
            "name": "surface",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditLocationFormModalQuery",
    "selections": [
      {
        "alias": "facilities",
        "args": null,
        "concreteType": "Facility",
        "kind": "LinkedField",
        "name": "allFacilities",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "surfaces",
        "args": null,
        "concreteType": "Surface",
        "kind": "LinkedField",
        "name": "allSurfaces",
        "plural": true,
        "selections": (v15/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Location",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "City",
            "kind": "LinkedField",
            "name": "city",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Facility",
            "kind": "LinkedField",
            "name": "facility",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Surface",
            "kind": "LinkedField",
            "name": "surface",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "736bd0ba5edd292f41e120ae53ca7769",
    "id": null,
    "metadata": {},
    "name": "EditLocationFormModalQuery",
    "operationKind": "query",
    "text": "query EditLocationFormModalQuery(\n  $locationId: ID!\n) {\n  facilities: allFacilities {\n    value: id\n    label: name\n    latitude\n    longitude\n    id\n  }\n  surfaces: allSurfaces {\n    value: id\n    label: name\n    id\n  }\n  location(locationId: $locationId) {\n    address\n    hasChangeRoom\n    isOutdoor\n    hasOutdoorLight\n    hasShower\n    hasWashRoom\n    latitude\n    longitude\n    name\n    city {\n      value: id\n      label: name\n      id\n    }\n    facility {\n      value: id\n      label: name\n      id\n    }\n    surface {\n      value: id\n      label: name\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a9a11b5df560435a2fa7dc5fefe50387";

export default node;
