import React, {Fragment} from 'react'
import shallow from 'zustand/shallow'

import {Global} from '@emotion/react'

import styles from './styles'

// manage
import CreateLocationModal from 'components/modals/manage/CreateLocationModal'
import EditLocationModal from 'components/modals/manage/EditLocationModal'

// users
import DeleteDoubleCheckModal from '../DeleteDoubleCheckModal'

import {useStore} from 'store/modals'

const MODAL_COMPONENTS = {
  CREATE_LOCATION_MODAL: CreateLocationModal,
  DELETE_DOUBLE_CHECK_MODAL: DeleteDoubleCheckModal,
  EDIT_LOCATION_MODAL: EditLocationModal,
}

function ModalRoot() {
  const {modalProps, modalType} = useStore(
    (state) => ({
      modalProps: state.modalProps,
      modalType: state.modalType,
    }),
    shallow
  )

  if (!modalType) {
    return null
  }

  const SpecificModal = MODAL_COMPONENTS[modalType]

  return (
    <Fragment>
      <Global styles={styles} />
      <SpecificModal {...modalProps} />
    </Fragment>
  )
}

export default ModalRoot
