import {useEffect, useState} from 'react'
import {graphql, useFragment} from 'react-relay/hooks'

import {Box, Center, FlexProps, Spinner} from '@chakra-ui/react'

import {Avatar_actor$key} from './__generated__/Avatar_actor.graphql'

import {Image} from 'components/Image'
import {getInitials} from 'utils'

type AvatarProps = FlexProps & {
  actor: Avatar_actor$key
}

export function Avatar({actor: _actor, ...props}: AvatarProps) {
  // @ts-expect-error we should find a better way to do this.
  const size = _actor.__fragments.Avatar_actor.size
  const actor = useFragment(
    graphql`
      fragment Avatar_actor on Actor
      @argumentDefinitions(size: {type: "Int!"}) {
        name
        avatar {
          transformedSrc(maxHeight: $size, maxWidth: $size)
        }
      }
    `,
    _actor
  )
  const [isLoading, setIsLoading] = useState(actor.avatar ? true : false)

  useEffect(() => {
    if (actor.avatar?.transformedSrc) {
      setIsLoading(true)
    }
  }, [actor.avatar?.transformedSrc])

  if (!actor.avatar) {
    return (
      <Center
        bg="gray.100"
        boxSize={`${size}px`}
        fontSize={`${size}px`}
        {...props}
      >
        <Box fontSize="0.45em" fontWeight="bold">
          {getInitials(actor.name)}
        </Box>
      </Center>
    )
  }

  const loadingMarkup = (
    <Center bg="gray.100" inset={0} position="absolute">
      <Spinner size="sm" />
    </Center>
  )

  return (
    <Box
      boxSize={`${size}px`}
      flexShrink={0}
      overflow="hidden"
      position="relative"
      {...props}
    >
      <Image
        boxSize="100%"
        fallback={isLoading && loadingMarkup}
        onError={() => setIsLoading(false)}
        onLoad={() => setIsLoading(false)}
        src={actor.avatar.transformedSrc}
        visibility={isLoading ? `hidden` : `visible`}
      />
    </Box>
  )
}
