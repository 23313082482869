/**
 * @generated SignedSource<<c4083a18100f9b4375a4e156dd2c1ad7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateCommentHiddenStatusInput = {
  id: string;
  isHidden: boolean;
};
export type CommentListItemUpdateMutation$variables = {
  input: UpdateCommentHiddenStatusInput;
};
export type CommentListItemUpdateMutation$data = {
  readonly commentHiddenStatusUpdate: {
    readonly id: string;
    readonly isHidden: boolean | null;
  };
};
export type CommentListItemUpdateMutation = {
  variables: CommentListItemUpdateMutation$variables;
  response: CommentListItemUpdateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Comment",
    "kind": "LinkedField",
    "name": "commentHiddenStatusUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isHidden",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentListItemUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommentListItemUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7d4f39c5743c860f6ee02e6da7599c5f",
    "id": null,
    "metadata": {},
    "name": "CommentListItemUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation CommentListItemUpdateMutation(\n  $input: UpdateCommentHiddenStatusInput!\n) {\n  commentHiddenStatusUpdate(input: $input) {\n    id\n    isHidden\n  }\n}\n"
  }
};
})();

(node as any).hash = "af111d779662a49ba2bdcb491a46b73f";

export default node;
