/* eslint-disable relay/unused-fields */
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay'
import Select from 'react-select'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  SimpleGrid,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react'
import {useMachine} from '@xstate/react'

import machine from './machine'
import AutoComplete from './AutoComplete'
import {validationSchema} from './validationSchema'
import CitySelect from 'components/CitySelect'
import Map from 'components/Map'

export default function CreateLocationFormModal({close, initialRef}) {
  const [hasFacility, setHasFacility] = useState(false)
  const [viewFacilityForm, setViewFacilityForm] = useState(false)

  const {facilities, surfaces} = useLazyLoadQuery(
    graphql`
      query CreateLocationFormModalQuery {
        facilities: allFacilities {
          address
          label: name
          latitude
          longitude
          value: id
        }
        surfaces: allSurfaces {
          value: id
          label: name
        }
      }
    `
  )

  const [createLocationModalMutation] = useMutation(graphql`
    mutation CreateLocationFormModalMutation($input: CreateLocationInput!) {
      createLocation(input: $input) {
        locationEdge {
          node {
            address
            latitude
            longitude
            id
            isOutdoor
            name
            city {
              name
            }
            facility {
              name
            }
            surface {
              name
            }
          }
        }
      }
    }
  `)

  const [current, send] = useMachine(machine, {
    actions: {
      createLocationSuccess: () => {
        close()
      },
      handleInputErrors: (ctx, event) => {
        console.log(ctx, event.values, event.actions)
      },
    },
    services: {
      createLocation: (_ctx, event) => {
        const {values} = event

        if (!hasFacility) {
          delete values.facilityId
        }

        if (values?.facilityId?.length !== 0) {
          delete values.facility
        }

        return new Promise(function (resolve, reject) {
          createLocationModalMutation({
            variables: {input: {...values}},
            onCompleted(response) {
              resolve(response)
            },
            onError(error) {
              reject(error)
            },
            configs: [
              {
                type: `RANGE_ADD`,
                parentID: `client:root`,
                edgeName: `locationEdge`,
                connectionInfo: [
                  {
                    key: `LocationsTable_locations`,
                    rangeBehavior: `prepend`,
                    filters: {
                      orderBy: [
                        {field: `LOCATION_ID`, order: `DESC`},
                        {field: `LOCATION_NAME`, order: `ASC`},
                      ],
                    },
                  },
                ],
              },
            ],
          })
        })
      },
    },
  })

  const formik = useFormik({
    validationSchema,
    initialValues: {
      address: ``,
      cityId: ``,
      facilityId: ``,
      hasChangeRoom: false,
      isOutdoor: false,
      hasOutdoorLight: false,
      hasShower: false,
      hasWashRoom: false,
      latitude: ``,
      longitude: ``,
      name: ``,
      surfaceId: ``,
      facility: {
        name: ``,
        address: ``,
        phoneNumber: ``,
        website: ``,
      },
    },
    onSubmit: (values, actions) => {
      send(`SUBMIT`, {values, actions})
    },
  })

  function handleHasFacility(e) {
    setHasFacility(e.target.checked)
  }

  return (
    <Box as="form" action="#" onSubmit={formik.handleSubmit}>
      <ModalBody pb={6}>
        <Flex align="center" mt={3}>
          <FormLabel htmlFor="it-has-facilty">is part of a facility?</FormLabel>
          <Switch
            id="it-has-facilty"
            isChecked={hasFacility}
            onChange={handleHasFacility}
          />
        </Flex>

        {hasFacility && (
          <SimpleGrid column={[1, 2]}>
            <FormControl
              mb={2}
              isInvalid={
                formik.touched?.facilityId && formik.errors?.facilityId
              }
            >
              <FormLabel htmlFor="facility">Facility</FormLabel>
              <Select
                placeholder="select a facility"
                isClearable
                options={facilities}
                onChange={(selected) => {
                  formik.setFieldValue(`facilityId`, selected?.value ?? ``)
                  setViewFacilityForm(false)
                  if (!selected) {
                    return
                  }
                  formik.setFieldValue(`address`, selected?.address)
                  formik.setFieldValue(`latitude`, selected?.latitude)
                  formik.setFieldValue(`longitude`, selected?.longitude)
                }}
                noOptionsMessage={() => `add new facility`}
              />
              <FormErrorMessage>{formik.errors?.facilityId}</FormErrorMessage>
            </FormControl>

            {formik.values?.facilityId?.length <= 0 && (
              <FormControl>
                <Button
                  my={2}
                  colorScheme="blue"
                  onClick={() => setViewFacilityForm((state) => !state)}
                >
                  Add new facilty
                </Button>
              </FormControl>
            )}
          </SimpleGrid>
        )}

        {viewFacilityForm && hasFacility && (
          <Stack>
            <FormControl
              isInvalid={
                formik.touched.facility?.name && formik.errors.facility?.name
              }
              isRequired
            >
              <FormLabel>Facility Name</FormLabel>
              <Input
                id="facility.name"
                name="facility.name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.facility.name}
              />
              <FormErrorMessage>
                {formik.errors.facility?.name}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                formik.touched.facility?.address &&
                formik.errors.facility?.address
              }
              isRequired
            >
              <FormLabel>Facility Address</FormLabel>
              <AutoComplete
                id="facility.address"
                name="facility.address"
                onChange={(value) => {
                  formik.setFieldValue(`facility.address`, value?.address)
                  formik.setFieldValue(`latitude`, value?.lat)
                  formik.setFieldValue(`longitude`, value?.lng)
                }}
                value={formik.values.facility.address}
              />
              <FormErrorMessage>
                {formik.errors.facility?.address}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                formik.touched.facility?.phoneNumber &&
                formik.errors.facility?.phoneNumber
              }
            >
              <FormLabel>Facility Phone Number</FormLabel>
              <Input
                id="facility.phoneNumber"
                name="facility.phoneNumber"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.facility.phoneNumber}
              />
              <FormErrorMessage>
                {formik.errors.facility?.phoneNumber}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                formik.touched.facility?.website &&
                formik.errors.facility?.website
              }
            >
              <FormLabel>Facility Website</FormLabel>
              <Input
                id="facility.website"
                name="facility.website"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.facility.website}
              />
              <FormErrorMessage>
                {formik.errors.facility?.website}
              </FormErrorMessage>
            </FormControl>
          </Stack>
        )}

        <FormControl
          isInvalid={formik.touched.address && formik.errors.address}
          isRequired
          my={3}
        >
          <FormLabel htmlFor="address">Location Address</FormLabel>
          <AutoComplete
            id="address"
            name="address"
            onChange={(value) => {
              formik.setFieldValue(`address`, value?.address)
              formik.setFieldValue(`latitude`, value?.lat)
              formik.setFieldValue(`longitude`, value?.lng)
            }}
            value={formik.values.address}
          />
          <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
        </FormControl>

        {formik.values.address &&
          formik.values.latitude &&
          formik.values.longitude && (
            <>
              <Text mt={5} fontWeight="bold">
                Location Map:
              </Text>
              <Map
                height="200px"
                marker={{
                  latitude: formik.values.latitude,
                  longitude: formik.values.longitude,
                }}
                zoom={16}
                onChange={({latitude, longitude}) => {
                  formik.setFieldValue(`latitude`, latitude)
                  formik.setFieldValue(`longitude`, longitude)
                }}
              />
            </>
          )}

        <FormControl
          my={3}
          isRequired
          isInvalid={formik.touched.name && formik.errors.name}
        >
          <FormLabel htmlFor="name">Location Name</FormLabel>
          <Input
            id="name"
            name="name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            ref={initialRef}
            value={formik.values.name}
          />
          <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
        </FormControl>

        <SimpleGrid columns={[1, 2]} spacing="40px">
          <FormControl
            isRequired
            isInvalid={formik.touched.cityId && formik.errors.cityId}
          >
            <FormLabel htmlFor="city">City</FormLabel>
            <CitySelect
              onChange={(selected) =>
                formik.setFieldValue(`cityId`, selected?.value)
              }
            />
            <FormErrorMessage>{formik.errors.cityId}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={formik.touched.surfaceId && formik.errors.surfaceId}
          >
            <FormLabel htmlFor="surface">Surface</FormLabel>
            <Select
              placeholder="Select a surface"
              options={surfaces}
              onChange={(selected) =>
                formik.setFieldValue(`surfaceId`, selected?.value)
              }
            />
            <FormErrorMessage>{formik.errors.surfaceId}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <Text mt={5} fontWeight="bold">
          Details:
        </Text>
        <Stack direction="row" spacing={5}>
          <Checkbox
            id="isOutdoor"
            colorScheme="blue"
            onChange={formik.handleChange}
            isChecked={formik.values.isOutdoor}
          >
            Outdoor
          </Checkbox>
          <Checkbox
            id="hasWashRoom"
            colorScheme="blue"
            onChange={formik.handleChange}
            isChecked={formik.values.hasWashRoom}
          >
            Washrooms
          </Checkbox>
          <Checkbox
            id="hasShower"
            colorScheme="blue"
            onChange={formik.handleChange}
            isChecked={formik.values.hasShower}
          >
            Showers
          </Checkbox>
          <Checkbox
            id="hasChangeRoom"
            colorScheme="blue"
            onChange={formik.handleChange}
            isChecked={formik.values.hasChangeRoom}
          >
            Change Rooms
          </Checkbox>
          <Checkbox
            id="hasOutdoorLight"
            colorScheme="blue"
            onChange={formik.handleChange}
            isChecked={formik.values.hasOutdoorLight}
          >
            Outdoor Lights
          </Checkbox>
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Stack direction="row" spacing={2}>
          <Button
            variant="ghost"
            isDisabled={current.matches(`updating`)}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            isLoading={current.matches(`updating`)}
            type="submit"
            colorScheme="blue"
          >
            Create
          </Button>
        </Stack>
      </ModalFooter>
    </Box>
  )
}
