/* eslint-disable relay/unused-fields */
import React, {Fragment, Suspense} from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import CommentForm from '../CommentForm'
import CommentList from '../CommentList/CommentList'
import {Loading} from '../loading'
import {Heading} from '../primitives'

graphql`
  fragment GameCommentsContainer_item on Comment {
    bodyHTML
    createdAt
    id
    author {
      __typename
      avatarUrl
      name
      url
      ... on User {
        id
      }
    }
    parent {
      id
    }
    isHidden
    comments(first: 10) @connection(key: "CommentListItem_comments") {
      edges {
        node {
          bodyHTML
          createdAt
          id
          author {
            __typename
            avatarUrl
            name
            url
            ... on User {
              id
            }
          }
          isHidden
          parent {
            id
            isHidden
          }
        }
      }
    }
  }
`

export default function GameCommentsContainer({gameId, viewer}) {
  const subject = {id: gameId}

  const {game} = useLazyLoadQuery(
    graphql`
      query GameCommentsContainerQuery($gameId: ID!) {
        game: node(id: $gameId) {
          ... on Game {
            __typename
            endTime
            id
            comments(first: 50)
              @connection(key: "GameCommentsContainer_comments") {
              edges {
                node {
                  ...GameCommentsContainer_item @relay(mask: false)
                }
              }
            }
            players(first: 24) {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    `,
    {gameId}
  )

  return (
    <Fragment>
      {game && (
        <>
          <Heading as="h2" fontSize="2xl">
            <FontAwesomeIcon fixedWidth icon={[`fal`, `comments`]} /> Comments
          </Heading>
          {viewer && <CommentForm subject={subject} viewer={viewer} />}

          <Suspense fallback={<Loading my={4} />}>
            {game.__typename === 'Game' && (
              <CommentList
                comments={game.comments.edges.map(({node}) => node)}
                subject={game}
                viewer={viewer}
              />
            )}
          </Suspense>
        </>
      )}
    </Fragment>
  )
}
