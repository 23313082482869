import React from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Skeleton,
} from '@chakra-ui/react'

import {useOverlayService} from '../hooks'

export function DrawerSkeleton() {
  const [, sendOverlay] = useOverlayService()

  return (
    <Drawer
      // isFullHeight
      isOpen
      onClose={() => sendOverlay(`CLOSE`)}
      // placement="bottom"
      size="md"
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Skeleton h={4} w={32} />
          </DrawerHeader>
          <DrawerBody borderBottomWidth="1px" borderTopWidth="1px">
            <Skeleton h={4} w="75%" />
            <Skeleton h={4} mt={4} w="45%" />
          </DrawerBody>
          <DrawerFooter>
            <Skeleton h={4} w={24} />
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}
