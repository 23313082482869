/**
 * @generated SignedSource<<88524ffcf86be0eca1e7ac12c31c4d4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DateTimeText_game$data = {
  readonly endTime: string;
  readonly startTime: string;
  readonly " $fragmentType": "DateTimeText_game";
};
export type DateTimeText_game$key = {
  readonly " $data"?: DateTimeText_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"DateTimeText_game">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DateTimeText_game",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    }
  ],
  "type": "Game",
  "abstractKey": null
};

(node as any).hash = "b638a6d6b6bcd2e2fcfe13b1961e8fa4";

export default node;
