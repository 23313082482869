import React from 'react'
import {
  Box,
  Flex,
  Hide,
  Skeleton,
  SkeletonCircle,
  Stack,
} from '@chakra-ui/react'

export function GameListItemSkeleton() {
  return (
    <Box bg="white" borderRadius="sm" boxShadow="sm" overflow="hidden">
      <Stack
        alignItems="center"
        bg="gray.50"
        borderTopLeftRadius="sm"
        borderTopRightRadius="sm"
        direction="row"
        h={20}
        p={5}
        spacing={4}
      >
        <SkeletonCircle h={10} w={10} />
        <Box>
          <Skeleton h={3} w={40} />
          <Skeleton h={3} mt={2} w={48} />
        </Box>
      </Stack>

      <Flex
        direction={[`column`, null, `row`]}
        justify={[null, null, `space-around`]}
        px={4}
        py={[4, null, 6]}
      >
        <Skeleton h={3} w="50%" />
        <Skeleton h={3} mt={{base: 3, md: 0}} w="65%" />
      </Flex>

      <Skeleton h={5} />
    </Box>
  )
}

export function GameListItemCompactSkeleton() {
  return (
    <Box bg="white" borderRadius="sm" boxShadow="sm" overflow="hidden">
      <Box p={4}>
        <Stack alignItems="center" direction="row" spacing={4}>
          <SkeletonCircle h={12} w={12} />
          <Box>
            <Skeleton h={3} w={40} />
            <Skeleton h={3} mt={2} w={48} />
          </Box>
        </Stack>
        <Hide above="md">
          <Box mt={6}>
            <Skeleton h={3} w={40} />
            <Skeleton h={3} mt={2} w={48} />
          </Box>
        </Hide>
      </Box>
      <Skeleton h={2} />
    </Box>
  )
}
