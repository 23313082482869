/**
 * @generated SignedSource<<1739609bef4ec4e54fbedf691636f903>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DateTimeTextWaitlist_game$data = {
  readonly endTime: string;
  readonly startTime: string;
  readonly " $fragmentType": "DateTimeTextWaitlist_game";
};
export type DateTimeTextWaitlist_game$key = {
  readonly " $data"?: DateTimeTextWaitlist_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"DateTimeTextWaitlist_game">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DateTimeTextWaitlist_game",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    }
  ],
  "type": "Game",
  "abstractKey": null
};

(node as any).hash = "9ca89a55fcb55c051201e9b5b3bac4f0";

export default node;
