/**
 * @generated SignedSource<<981ccfaed3b47de1c4ed97dd52a6ffc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CalendarIcon_game$data = {
  readonly startTime: string;
  readonly " $fragmentType": "CalendarIcon_game";
};
export type CalendarIcon_game$key = {
  readonly " $data"?: CalendarIcon_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"CalendarIcon_game">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CalendarIcon_game",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    }
  ],
  "type": "Game",
  "abstractKey": null
};

(node as any).hash = "288bdd4ba01d36abdd06d0134a08437a";

export default node;
