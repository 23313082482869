import create from 'zustand'

const [useStore, api] = create((set) => ({
  isOpen: false,
  modalProps: {},
  modalType: null,

  openModal: ({modalProps, modalType}) =>
    set({modalProps, modalType, isOpen: true}),

  closeModal: () => set({modalProps: {}, modalType: null, isOpen: false}),
}))

export {api, useStore}
