import {createMachine} from 'xstate'

export default createMachine({
  id: 'edit-location-modal-machine',
  initial: 'editing',
  states: {
    editing: {
      on: {SUBMIT: 'updating'},
    },
    updating: {
      invoke: {
        id: 'updateLocation',
        src: 'updateLocation',
        onDone: 'success',
        onError: {
          actions: 'handleInputErrors',
          target: 'editing',
        },
      },
    },
    success: {
      entry: 'updateLocationSuccess',
      type: 'final',
    },
  },
})
