/**
 * @generated SignedSource<<5066f9efbd2612f3b032d8e95634d526>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CreateLocationFormModalQuery$variables = {};
export type CreateLocationFormModalQuery$data = {
  readonly facilities: ReadonlyArray<{
    readonly address: string;
    readonly label: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly value: string;
  }>;
  readonly surfaces: ReadonlyArray<{
    readonly value: string;
    readonly label: string | null;
  }>;
};
export type CreateLocationFormModalQuery = {
  variables: CreateLocationFormModalQuery$variables;
  response: CreateLocationFormModalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v1 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latitude",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longitude",
  "storageKey": null
},
v4 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateLocationFormModalQuery",
    "selections": [
      {
        "alias": "facilities",
        "args": null,
        "concreteType": "Facility",
        "kind": "LinkedField",
        "name": "allFacilities",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "surfaces",
        "args": null,
        "concreteType": "Surface",
        "kind": "LinkedField",
        "name": "allSurfaces",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CreateLocationFormModalQuery",
    "selections": [
      {
        "alias": "facilities",
        "args": null,
        "concreteType": "Facility",
        "kind": "LinkedField",
        "name": "allFacilities",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "surfaces",
        "args": null,
        "concreteType": "Surface",
        "kind": "LinkedField",
        "name": "allSurfaces",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v1/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f7f5c588b61d6cc45bc4c734ad7005c9",
    "id": null,
    "metadata": {},
    "name": "CreateLocationFormModalQuery",
    "operationKind": "query",
    "text": "query CreateLocationFormModalQuery {\n  facilities: allFacilities {\n    address\n    label: name\n    latitude\n    longitude\n    value: id\n    id\n  }\n  surfaces: allSurfaces {\n    value: id\n    label: name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "169d774ddc94cfe3b968ed30b5767795";

export default node;
