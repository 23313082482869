import {library} from '@fortawesome/fontawesome-svg-core'

import {
  faAddressCard as fasAddressCard,
  faCalendarAlt as fasCalendarAlt,
  faCubes as fasCubes,
  faEdit as fasEdit,
  faMagic as fasMagic,
  faMap as fasMap,
  faTh as fasTh,
  faThLarge as fasThLarge,
  faUser as fasUser,
  faUserPlus as fasUserPlus,
  faUsers as fasUsers,
  faWallet as fasWallet,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faBullhorn as falBullhorn,
  faCalendarDay as falCalendarDay,
  faCheckCircle as falCheckCircle,
  faClock as falClock,
  faCog as falCog,
  faComments as falComments,
  faEdit as falEdit,
  faMapMarkerAlt as falMapMarkerAlt,
  faPlus as falPlus,
  faTrash as falTrash,
} from '@fortawesome/pro-light-svg-icons'

import {
  faCalendarAlt as farCalendarAlt,
  faCheck as farCheck,
  faChevronDown as farChevronDown,
  faChevronUp as farChevronUp,
  faBars as farBars,
  faHomeAlt as farHomeAlt,
  faTimes as farTimes,
} from '@fortawesome/pro-regular-svg-icons'

// free solid
library.add(
  fasAddressCard,
  fasCalendarAlt,
  fasCubes,
  fasEdit,
  fasMagic,
  fasMap,
  fasTh,
  fasThLarge,
  fasUser,
  fasUserPlus,
  fasUsers,
  fasWallet
)

// pro light
library.add(
  falBullhorn,
  falCalendarDay,
  falCheckCircle,
  falClock,
  falCog,
  falComments,
  falEdit,
  falMapMarkerAlt,
  falPlus,
  falTrash
)

// pro regular
library.add(
  farBars,
  farCalendarAlt,
  farCheck,
  farChevronDown,
  farChevronUp,
  farHomeAlt,
  farTimes
)
