import React from 'react'
import {Skeleton} from '@chakra-ui/react'

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from 'components/ModalNew'
import {ModalService} from 'components/ModalRoot'

export function LoadingModal() {
  return (
    <Modal
      isCentered
      isOpen
      onClose={() => ModalService.send(`CLOSE`)}
      size="lg"
    >
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <Skeleton h={4} w={32} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={40} pt={4}>
            <Skeleton h={4} w="75%" />
            <Skeleton h={4} mt={4} w="45%" />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
