import {createMachine} from 'xstate'

export default createMachine({
  id: 'create-location-modal-machine',
  initial: 'editingForm',
  states: {
    editingForm: {
      on: {SUBMIT: 'storing'},
    },
    storing: {
      invoke: {
        id: 'createLocation',
        src: 'createLocation',
        onDone: 'success',
        onError: {
          actions: 'handleInputErrors',
          target: 'editingForm',
        },
      },
    },
    success: {
      entry: 'createLocationSuccess',
      type: 'final',
    },
  },
})
