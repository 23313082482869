import React, {ReactNode} from 'react'
import {Box, Flex, Heading, Spacer} from '@chakra-ui/react'

export interface HeaderProps {
  children?: ReactNode
  title?: ReactNode
}

export function Header({children, title}: HeaderProps) {
  const titleMarkup =
    typeof title === `string` ? <Heading size="sm">{title}</Heading> : title

  return (
    <Box pt={5} px={5}>
      <Flex align="baseline">
        {titleMarkup}
        <Spacer />
        {children}
      </Flex>
    </Box>
  )
}
