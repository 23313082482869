import {Formik} from 'formik'
import {Avatar, Textarea, Stack, Button} from '@chakra-ui/react'
import {func, object, string} from 'prop-types'
import {graphql, useMutation} from 'react-relay/hooks'
import {useEffect, useRef} from 'react'
import * as Yup from 'yup'
import TextareaAutoSize from 'react-textarea-autosize'
import {Box} from '../primitives'

const validationSchema = Yup.object().shape({
  body: Yup.string().trim().required(),
})

function CommentForm({
  className,
  onSubmit,
  placeholder,
  replyToComment,
  subject,
  viewer,
}) {
  const textareaRef = useRef(``)

  const [commitAddComment, isInFlight] = useMutation(
    graphql`
      mutation CommentFormAddCommentMutation($input: AddCommentInput!) {
        addComment(input: $input) {
          commentEdge {
            node {
              ...GameCommentsContainer_item @relay(mask: false)
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    if (replyToComment) {
      textareaRef.current.focus()
    }
  }, [replyToComment])

  return (
    <Formik
      initialValues={{body: ''}}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({handleChange, handleSubmit, submitForm, values}) => (
        <Box
          marginTop={3}
          as="form"
          bg="white"
          borderRadius="md"
          boxShadow="sm"
          className={className}
          display="flex"
          flexDirection="column"
          mt={2}
          onSubmit={handleSubmit}
          p={2}
        >
          <Stack isInline>
            <Avatar size="sm" src={viewer.avatarUrl} />

            <Textarea
              as={TextareaAutoSize}
              ref={textareaRef}
              name="body"
              onChange={handleChange}
              onKeyDown={(event) => {
                if (event.key === `Enter` && event.metaKey) {
                  submitForm()
                }
              }}
              placeholder={placeholder}
              value={values.body}
              css={() => ({
                border: `1px solid #e8e8e8`,
                flex: 1,
                resize: `none`,
                '&:focus': {
                  borderColor: `#999`,
                },
                fontFamily: `inherit`,
              })}
            />
          </Stack>

          {values.body.trim() !== `` && (
            <Button
              alignSelf="flex-end"
              ml="auto"
              mt={2}
              size="sm"
              colorScheme="primary"
              isLoading={isInFlight}
              type="submit"
            >
              Send
            </Button>
          )}
        </Box>
      )}
    </Formik>
  )

  function handleSubmit(values, formikBag) {
    let parentID
    const {id: subjectId} = subject

    if (!replyToComment) {
      parentID = subjectId
    } else if (replyToComment.parent) {
      parentID = replyToComment.parent.id
    } else {
      parentID = replyToComment.id
    }

    return commitAddComment({
      onCompleted() {
        formikBag.resetForm()

        onSubmit?.()
      },
      variables: {
        input: {
          subjectId,
          body: values.body.trim(),
          parentCommentId: replyToComment?.id || null,
        },
      },
      configs: [
        {
          parentID,
          type: `RANGE_ADD`,
          edgeName: `commentEdge`,
          connectionInfo: [
            {
              key: replyToComment
                ? `CommentListItem_comments`
                : `GameCommentsContainer_comments`,
              rangeBehavior: `append`,
            },
          ],
        },
      ],
    })
  }
}

CommentForm.propTypes = {
  className: string,
  onSubmit: func,
  placeholder: string,
  replyToComment: object,
  subject: object.isRequired,
  viewer: object.isRequired,
}

CommentForm.defaultProps = {
  placeholder: `Type your comment`,
}

export default CommentForm
