/**
 * @generated SignedSource<<2faed606bb4ba05e604d2137db322c0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SportsSelector_sports$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"SportCheckbox_sport">;
  readonly " $fragmentType": "SportsSelector_sports";
}>;
export type SportsSelector_sports$key = ReadonlyArray<{
  readonly " $data"?: SportsSelector_sports$data;
  readonly " $fragmentSpreads": FragmentRefs<"SportsSelector_sports">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SportsSelector_sports",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SportCheckbox_sport"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Sport",
  "abstractKey": null
};

(node as any).hash = "6657f60b88f19bdc1228afbb2ba34911";

export default node;
