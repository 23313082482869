import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'
import {
  alignContent,
  alignItems,
  background,
  border,
  color,
  compose,
  flexbox,
  grid as _grid,
  justifyContent,
  justifyItems,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system'
import {Heading as ChakraHeading} from '@chakra-ui/react'

const core = compose(color, space)

const grid = compose(
  _grid,
  alignContent,
  alignItems,
  justifyContent,
  justifyItems
)

export const Box = styled(`div`, {shouldForwardProp})(
  background,
  border,
  core,
  flexbox,
  layout,
  position,
  shadow
)

export const Grid = styled(`div`, {shouldForwardProp})(
  background,
  border,
  core,
  layout,
  grid,
  position,
  shadow,
  {
    display: `grid`,
  }
)

export const Text = styled(`div`, {shouldForwardProp})(core, typography)

export const Heading = styled(ChakraHeading)(
  {position: `relative`},
  (props) =>
    props.fancy && {
      borderBottom: `2px solid #dedede`,
      paddingBottom: 8,

      '&:after': {
        content: `""`,
        height: 2,
        width: 32,
        left: 0,
        position: `absolute`,
        top: `100%`,
        background: `${props.theme.colors.primary}`,
      },
    }
)

Heading.defaultProps = {
  as: `h1`,
  color: `black`,
  fontSize: `4xl`,
  fontWeight: `hairline`,
  mb: 0,
  mt: 0,
}

export const Center = styled(Box)({
  alignItems: `center`,
  display: `flex`,
  justifyContent: `center`,
})
