import {format, getDaysInMonth, isValid, parse} from 'date-fns'
import {useField} from 'formik'
import range from 'lodash.range'
import {useEffect, useMemo, useState} from 'react'

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Select,
} from '@chakra-ui/react'

interface BirthdaySelectorProps {
  isYouth?: boolean
  name: string
}

const MINIMUM_ADULT_AGE = 18
const MAXIMUM_ADULT_AGE = 100
const MINIMUM_YOUTH_AGE = 3
const MAXIMUM_YOUTH_AGE = 17

const CURRENT_YEAR = new Date().getFullYear()
const MONTHS = range(0, 12)
const MONTH_LABELS = [
  `January`,
  `February`,
  `March`,
  `April`,
  `May`,
  `June`,
  `July`,
  `August`,
  `September`,
  `October`,
  `November`,
  `December`,
]

export function BirthdaySelector({isYouth, name}: BirthdaySelectorProps) {
  const minimumAge = isYouth ? MINIMUM_YOUTH_AGE : MINIMUM_ADULT_AGE
  const maximunAge = isYouth ? MAXIMUM_YOUTH_AGE : MAXIMUM_ADULT_AGE

  const [day, setDay] = useState<number>(undefined)
  const [month, setMonth] = useState<number>(undefined)
  const [year, setYear] = useState<number>(undefined)
  const [fieldProps, fieldMeta] = useField<string>(name)

  const years = useMemo(
    () => range(CURRENT_YEAR - minimumAge, CURRENT_YEAR - (maximunAge + 1)),
    [maximunAge, minimumAge]
  )

  useEffect(() => {
    const date = parse(fieldProps.value, `yyyy-MM-dd`, new Date())

    if (isValid(date)) {
      setDay(date.getDate())
      setMonth(date.getMonth())
      setYear(date.getFullYear())
    }
  }, [fieldProps.value])

  useEffect(() => {
    const dateString = `${year}-${month + 1}-${day}`
    const date = parse(dateString, `yyyy-MM-d`, new Date())

    if (isValid(date)) {
      fieldProps.onChange(format(date, `yyyy-MM-dd`))
    }
  }, [day, fieldProps, month, year])

  const isInvalid = !!fieldMeta.error

  return (
    <Box>
      <Box fontWeight="medium">Birthday</Box>
      <HStack>
        <FormControl isInvalid={isInvalid} isRequired>
          <FormLabel fontSize="sm">Year</FormLabel>
          <Select
            defaultValue="year"
            onChange={(e) => setYear(Number(e.target.value))}
            value={year}
          >
            <option disabled value="year">
              Year
            </option>
            {years.map((year) => (
              <option value={year} key={year}>
                {year}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl isInvalid={isInvalid} isRequired>
          <FormLabel fontSize="sm">Month</FormLabel>
          <Select
            defaultValue="month"
            onChange={(e) => setMonth(Number(e.target.value))}
            value={month}
          >
            <option disabled value="month">
              Month
            </option>
            {MONTHS.map((month) => (
              <option key={month} value={month}>
                {MONTH_LABELS[month]}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl isInvalid={isInvalid} isRequired>
          <FormLabel fontSize="sm">Day</FormLabel>
          <Select
            defaultValue="day"
            isDisabled={year === undefined || month === undefined}
            onChange={(e) => setDay(Number(e.target.value))}
            value={day}
          >
            <option disabled value="day">
              Day
            </option>
            {year !== undefined && month !== undefined
              ? range(1, getDaysInMonth(new Date(year, month)) + 1).map(
                  (day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  )
                )
              : null}
          </Select>
        </FormControl>
      </HStack>
      {fieldMeta.error && (
        <Flex
          alignItems="center"
          color="red.500"
          fontSize="sm"
          marginBlockStart="2"
        >
          {fieldMeta.error}
        </Flex>
      )}
    </Box>
  )
}
