import {ReactNode, useEffect, useRef} from 'react'
import {useIntersection} from 'react-use'

import {Box, BoxProps} from '@chakra-ui/react'

interface MorePagerProps extends BoxProps {
  children: ReactNode
  onMore: () => void
  rootMargin?: string
}

export function MorePager({
  children,
  onMore,
  rootMargin = `0px 0px 150px 0px`,
  ...props
}: MorePagerProps) {
  const intersectionRef = useRef(null)
  const intersection = useIntersection(intersectionRef, {
    rootMargin,
  })

  useEffect(() => {
    if (intersection?.intersectionRatio > 0) {
      onMore()
    }
  }, [intersection, onMore])

  return (
    <Box h="full" ref={intersectionRef} w="full" {...props}>
      {intersection?.intersectionRatio > 0 && children}
    </Box>
  )
}
