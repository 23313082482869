import React from 'react'
import {graphql, useFragment} from 'react-relay/hooks'
import {Flex} from '@chakra-ui/react'

import {SportIcon_sport$key} from './__generated__/SportIcon_sport.graphql'

import LocalIcon from 'components/LocalIcon'

export interface SportIconProps {
  sport: SportIcon_sport$key
  isCancelled?: boolean
  isWaitlistItem?: boolean
  isLocked?: boolean
  isYouth?: boolean
}

export function SportIcon(props: SportIconProps) {
  const {slug} = useFragment(
    graphql`
      fragment SportIcon_sport on Sport {
        slug
      }
    `,
    props.sport
  )

  if (props.isLocked || props.isWaitlistItem) {
    return (
      <Flex
        align="center"
        alignSelf="center"
        bg={props.isWaitlistItem ? 'primary.300' : '#a1a1a1'}
        color="white"
        h="3.5rem"
        justify="center"
        w="3.5rem"
        minW="3.5rem"
      >
        <LocalIcon icon={slug} size={32} />
      </Flex>
    )
  }

  return (
    <Flex
      align="center"
      bg={props.isCancelled ? '#CA4C46' : 'primary.300'}
      color="white"
      h="4.5rem"
      justify="center"
      w="4.5rem"
      minW="4.5rem"
    >
      <LocalIcon icon={slug} size={40} />
    </Flex>
  )
}
