export function getInitials(displayName: string): string {
  const names = (displayName.trim() || `?`).split(` `)
  let initials = Array.from(names[0])[0]

  if (names.length > 1) {
    initials += Array.from(names[names.length - 1])[0]
  }

  return initials.toUpperCase()
}
