/* eslint-disable relay/unused-fields */
import {useFormik} from 'formik'
import {graphql} from 'react-relay'
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks'
import Select from 'react-select'
import {useMachine} from '@xstate/react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  SimpleGrid,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'

import machine from './machine'
import {validationSchema} from './validationSchema'
import CitySelect from 'components/CitySelect'
import Map from 'components/Map'

export default function EditLocationFormModal({close, locationId, initialRef}) {
  const {facilities, location, surfaces} = useLazyLoadQuery(
    graphql`
      query EditLocationFormModalQuery($locationId: ID!) {
        facilities: allFacilities {
          value: id
          label: name
          latitude
          longitude
        }
        surfaces: allSurfaces {
          value: id
          label: name
        }
        location(locationId: $locationId) {
          address
          hasChangeRoom
          isOutdoor
          hasOutdoorLight
          hasShower
          hasWashRoom
          latitude
          longitude
          name
          city {
            value: id
            label: name
          }
          facility {
            value: id
            label: name
          }
          surface {
            value: id
            label: name
          }
        }
      }
    `,
    {locationId}
  )

  const [editLocationModalMutation] = useMutation(graphql`
    mutation EditLocationFormModalMutation($input: UpdateLocationInput!) {
      updateLocation(input: $input) {
        address
        id
        hasChangeRoom
        isOutdoor
        hasOutdoorLight
        hasShower
        hasWashRoom
        latitude
        longitude
        name
        city {
          value: id
          label: name
        }
        facility {
          value: id
          label: name
        }
        surface {
          value: id
          label: name
        }
      }
    }
  `)

  const [current, send] = useMachine(machine, {
    actions: {
      updateLocationSuccess: () => {
        close()
      },
      handleInputErrors: (ctx, event) => {
        console.log(ctx, event.values, event.actions)
      },
    },
    services: {
      updateLocation: (ctx, event) => {
        const {values} = event

        return new Promise(function (resolve, reject) {
          editLocationModalMutation({
            variables: {input: {...values, locationId}},
            onCompleted(response) {
              resolve(response)
            },
            onError(error) {
              reject(error)
            },
          })
        })
      },
    },
  })

  const formik = useFormik({
    validationSchema,
    initialValues: {
      address: location.address,
      cityId: location?.city?.value,
      facilityId: location?.facility?.value,
      hasChangeRoom: location.hasChangeRoom,
      isOutdoor: location.isOutdoor,
      hasOutdoorLight: location.hasOutdoorLight,
      hasShower: location.hasShower,
      hasWashRoom: location.hasWashRoom,
      latitude: location.latitude,
      longitude: location.longitude,
      name: location?.name,
      surfaceId: location?.surface?.value,
    },
    onSubmit: (values, actions) => {
      send(`SUBMIT`, {values, actions})
    },
  })

  return (
    <Box as="form" action="#" onSubmit={formik.handleSubmit}>
      <ModalBody pb={6}>
        <SimpleGrid columns={[1, 2]} spacing="40px">
          <FormControl
            isRequired
            isInvalid={formik.touched.name && formik.errors.name}
          >
            <FormLabel htmlFor="name">Location Name</FormLabel>
            <Input
              id="name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              ref={initialRef}
              value={formik.values.name}
            />
            <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={formik.touched.facility && formik.errors.facility}
          >
            <FormLabel htmlFor="facility">Facility</FormLabel>
            <Select
              isClearable
              defaultValue={location.facility}
              options={facilities}
              onChange={(selected) => {
                formik.setFieldValue(`facilityId`, selected?.value)
                if (!selected) {
                  return
                }
                formik.setFieldValue(`latitude`, selected?.latitude)
                formik.setFieldValue(`longitude`, selected?.longitude)
              }}
            />
            <FormErrorMessage>{formik.errors.facilityId}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={formik.touched.cityId && formik.errors.cityId}
          >
            <FormLabel htmlFor="city">City</FormLabel>
            <CitySelect
              defaultValue={location.city}
              onChange={(selected) =>
                formik.setFieldValue(`cityId`, selected?.value)
              }
            />
            <FormErrorMessage>{formik.errors.cityId}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={formik.touched.surfaceId && formik.errors.surfaceId}
          >
            <FormLabel htmlFor="surface">Surface</FormLabel>
            <Select
              defaultValue={location.surface}
              options={surfaces}
              onChange={(selected) =>
                formik.setFieldValue(`surfaceId`, selected?.value)
              }
            />
            <FormErrorMessage>{formik.errors.surface}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <FormControl
          mt={4}
          isRequired
          isInvalid={formik.touched.address && formik.errors.address}
        >
          <FormLabel htmlFor="address">Address</FormLabel>
          <Input
            id="address"
            name="address"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.address}
          />
          <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
        </FormControl>
        <Text mt={5} fontWeight="bold">
          Details:
        </Text>
        <Wrap spacing={4}>
          <WrapItem>
            <Checkbox
              id="isOutdoor"
              size="md"
              colorScheme="blue"
              onChange={formik.handleChange}
              isChecked={formik.values.isOutdoor}
            >
              Outdoor
            </Checkbox>
          </WrapItem>
          <WrapItem>
            <Checkbox
              id="hasWashRoom"
              size="md"
              colorScheme="blue"
              onChange={formik.handleChange}
              isChecked={formik.values.hasWashRoom}
            >
              Washrooms
            </Checkbox>
          </WrapItem>
          <WrapItem>
            <Checkbox
              id="hasShower"
              size="md"
              colorScheme="blue"
              onChange={formik.handleChange}
              isChecked={formik.values.hasShower}
            >
              Showers
            </Checkbox>
          </WrapItem>
          <WrapItem>
            <Checkbox
              id="hasChangeRoom"
              size="md"
              colorScheme="blue"
              onChange={formik.handleChange}
              isChecked={formik.values.hasChangeRoom}
            >
              Change Rooms
            </Checkbox>
          </WrapItem>
          <WrapItem>
            <Checkbox
              id="hasOutdoorLight"
              size="md"
              colorScheme="blue"
              onChange={formik.handleChange}
              isChecked={formik.values.hasOutdoorLight}
            >
              Outdoor Lights
            </Checkbox>
          </WrapItem>
        </Wrap>
        <Text mt={5} fontWeight="bold">
          Location Map:
        </Text>

        <Map
          height="400px"
          marker={{
            latitude: formik.values.latitude,
            longitude: formik.values.longitude,
          }}
          onChange={({latitude, longitude}) => {
            formik.setFieldValue(`latitude`, latitude)
            formik.setFieldValue(`longitude`, longitude)
          }}
          zoom={18}
        />
      </ModalBody>

      <ModalFooter>
        <Stack direction="row" spacing={2}>
          <Button
            variant="ghost"
            isDisabled={current.matches(`updating`)}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            isLoading={current.matches(`updating`)}
            type="submit"
            colorScheme="blue"
          >
            Update
          </Button>
        </Stack>
      </ModalFooter>
    </Box>
  )
}
