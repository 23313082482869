/**
 * @generated SignedSource<<704ba39ff65186d3955faf900b92b0ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderQuery$variables = {
  isUser: boolean;
};
export type HeaderQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"Navigation_viewer" | "UserMenu_viewer">;
  } | null;
};
export type HeaderQuery = {
  variables: HeaderQuery$variables;
  response: HeaderQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isUser"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Navigation_viewer"
          },
          {
            "condition": "isUser",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserMenu_viewer"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerIsManager",
            "storageKey": null
          },
          {
            "condition": "isUser",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "avatar",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "maxHeight",
                            "value": 24
                          },
                          {
                            "kind": "Literal",
                            "name": "maxWidth",
                            "value": 24
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "transformedSrc",
                        "storageKey": "transformedSrc(maxHeight:24,maxWidth:24)"
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Actor",
                "abstractKey": "__isActor"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "resourcePath",
                "storageKey": null
              }
            ]
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da37a5c7c6ca87a083cda524e470c508",
    "id": null,
    "metadata": {},
    "name": "HeaderQuery",
    "operationKind": "query",
    "text": "query HeaderQuery(\n  $isUser: Boolean!\n) {\n  viewer {\n    ...Navigation_viewer\n    ...UserMenu_viewer @include(if: $isUser)\n    id\n  }\n}\n\nfragment Avatar_actor_2V5WqX on Actor {\n  __isActor: __typename\n  name\n  avatar {\n    transformedSrc(maxHeight: 24, maxWidth: 24)\n    id\n  }\n}\n\nfragment Navigation_viewer on User {\n  viewerIsManager\n}\n\nfragment UserMenu_viewer on User {\n  ...Avatar_actor_2V5WqX\n  resourcePath\n}\n"
  }
};
})();

(node as any).hash = "999b72acf5061d5d411a6cce6d14f339";

export default node;
