/**
 * @generated SignedSource<<9d28ed4b4a48163f3be3255e13802b5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SportCheckbox_sport$data = {
  readonly name: string;
  readonly slug: string;
  readonly " $fragmentType": "SportCheckbox_sport";
};
export type SportCheckbox_sport$key = {
  readonly " $data"?: SportCheckbox_sport$data;
  readonly " $fragmentSpreads": FragmentRefs<"SportCheckbox_sport">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SportCheckbox_sport",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Sport",
  "abstractKey": null
};

(node as any).hash = "5d7003aac4d150b5abb9fc943b23010e";

export default node;
