/**
 * @generated SignedSource<<99bfccd0b77596265e6b2abe456390d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GameFeedItem_games$data = {
  readonly id: string;
  readonly unlockTimeTz: any;
  readonly gameIsFull: boolean;
  readonly startTime: string;
  readonly " $fragmentSpreads": FragmentRefs<"GameListItem_game" | "GameListItemCompact_game" | "GameListItemWaitlist_game" | "GameListItemLocked_game">;
  readonly " $fragmentType": "GameFeedItem_games";
};
export type GameFeedItem_games$key = {
  readonly " $data"?: GameFeedItem_games$data;
  readonly " $fragmentSpreads": FragmentRefs<"GameFeedItem_games">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "youth",
    "variableName": "youth"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "youth"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GameFeedItem_games",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "GameListItem_game"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "GameListItemCompact_game"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GameListItemWaitlist_game"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GameListItemLocked_game"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unlockTimeTz",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gameIsFull",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    }
  ],
  "type": "Game",
  "abstractKey": null
};
})();

(node as any).hash = "03c7a42093c0b0d44e3e4d6071c89d65";

export default node;
