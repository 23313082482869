/**
 * @generated SignedSource<<ffa73a3965a2e4d63e05fefb659897f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TitleWaitlist_game$data = {
  readonly title: string | null;
  readonly sport: {
    readonly name: string;
  };
  readonly " $fragmentType": "TitleWaitlist_game";
};
export type TitleWaitlist_game$key = {
  readonly " $data"?: TitleWaitlist_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"TitleWaitlist_game">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TitleWaitlist_game",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sport",
      "kind": "LinkedField",
      "name": "sport",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Game",
  "abstractKey": null
};

(node as any).hash = "496d8385028406e6107b1d2b445e1884";

export default node;
