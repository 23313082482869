import Pusher from 'pusher-js'

const pusher = new Pusher(window.__ENV__.PUSHER_APP_KEY, {
  wsHost: window.__ENV__.PUSHER_HOST,
  wsPort: window.__ENV__.PUSHER_PORT,
  forceTLS: false,
  disableStats: true,
  enabledTransports: ['ws', 'wss']
})

export default pusher
