import React from 'react'
import {Box, Flex, Button, Heading, Text} from '@chakra-ui/react'

export default function ViewError({
  children,
  emoji = `🥺`,
  heading = `Something went wrong.`,
  refresh,
  subheading = `Sorry about the technical issues. We have been notified of the problem and should resolve it soon.`,
}) {
  return (
    <Flex
      align="center"
      bg="gray.800"
      direction="column"
      height="100vh"
      justify="center"
      p={4}
      textAlign="center"
    >
      <Box
        bg="white"
        borderRadius="lg"
        boxShadow="md"
        maxWidth={320}
        px={4}
        py={12}
      >
        <Box aria-label="Emoji" fontSize="4xl" role="img">
          {emoji}
        </Box>
        <Heading as="h1" size="lg">
          {heading}
        </Heading>
        <Text fontSize="sm" mt={2}>
          {subheading}
        </Text>

        {refresh && (
          <Button
            mt={4}
            onClick={() => window.location.reload(true)}
            colorScheme="primary"
            size="sm"
          >
            Refresh the page
          </Button>
        )}

        {children}
      </Box>
    </Flex>
  )
}
