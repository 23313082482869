import React from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
} from '@chakra-ui/react'

import {useOverlayService} from '../hooks'

export function ModalSkeleton() {
  const [, sendOverlay] = useOverlayService()

  return (
    <Modal isCentered isOpen onClose={() => sendOverlay(`CLOSE`)} size="lg">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <Skeleton h={4} w={32} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={40} pt={4}>
            <Skeleton h={4} w="75%" />
            <Skeleton h={4} mt={4} w="45%" />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
