import React from 'react'
import {graphql, useFragment} from 'react-relay/hooks'
import {Box, Button, Link, Show, Stack} from '@chakra-ui/react'

import {Navigation_viewer$key} from './__generated__/Navigation_viewer.graphql'

import {useSiteEnvironmentContext} from 'hooks'

interface NavigationProps {
  viewer: Navigation_viewer$key
}

export function Navigation(props: NavigationProps) {
  const {PICKUPHUB_URL, PICKUPHUB_YOUTH_URL, IS_YOUTH_DOMAIN} =
    useSiteEnvironmentContext()

  const viewer = useFragment(
    graphql`
      fragment Navigation_viewer on User {
        viewerIsManager
      }
    `,
    props.viewer
  )


  if (Boolean(viewer)) {
    return (
      <Stack alignItems="center" direction="row" spacing={4}>
        {window.env.user?.is_impersonate_enable && (
          <Show>
            <Button
              as="a"
              colorScheme="yellow"
              href={`/manage/users/stop-impersonating`}
              size="sm"
            >
              Return to account
            </Button>
          </Show>
        )}
        <Show above="md">
          {viewer.viewerIsManager && (
            <Button
              as="a"
              colorScheme="yellow"
              href={`${PICKUPHUB_URL}/manage`}
              size="sm"
            >
              Manage
            </Button>
          )}
        </Show>
        <Button
          as="a"
          colorScheme="purple"
          href={`${PICKUPHUB_YOUTH_URL}/hub`}
          size="sm"
        >
          <Show above="sm">Youth Hub</Show>
          {IS_YOUTH_DOMAIN ? (
            <Show below="sm">Hub</Show>
          ) : (
            <Show below="sm">Youth</Show>
          )}
        </Button>
        <Button
          as="a"
          colorScheme="green"
          href={`${PICKUPHUB_URL}/hub`}
          size="sm"
        >
          <Show above="sm">Adult Hub</Show>
          {IS_YOUTH_DOMAIN ? (
            <Show below="sm">Adult</Show>
          ) : (
            <Show below="sm">Hub</Show>
          )}
        </Button>
      </Stack>
    )
  }

  return (
    <Box>
      <Stack alignItems="center" direction="row" spacing={4}>
        <Show above="md">
          <Link fontSize="sm" href="https://about.pickuphub.net" mr={4}>
            Become a Host
          </Link>
          <Link fontSize="sm" href="/how-it-works" mr={4}>
            How It Works
          </Link>
        </Show>
        <Button as="a" href="/login" size="sm" colorScheme="primary">
          Login
        </Button>
        <Button as="a" href="/join" size="sm" colorScheme="primary">
          Join
        </Button>
      </Stack>
    </Box>
  )
}
