import React, {useEffect, useState} from 'react'
import {For} from 'react-loops'
import {Badge, Box, Button, Stack, Text} from '@chakra-ui/react'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from '@react-google-maps/api'

import {Loading} from 'components/loading'
import {useStore as useModalsStore} from 'store/modals'
import {graphql} from 'react-relay'
import {useFragment} from 'react-relay/hooks'

const LIBRARIES = [`places`]

const MAP_FRAGMENT = graphql`
  fragment Map_locations on Location @relay(plural: true) {
    address
    id
    hasWashRoom
    hasShower
    hasChangeRoom
    hasOutdoorLight
    latitude
    longitude
    name
    facility {
      name
    }
    surface {
      name
    }
  }
`

export default function Map({
  height = `600px`,
  marker,
  markerOptions: _markerOptions,
  onChange,
  zoom,
}) {
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: window.env.google_maps_api_key,
    libraries: LIBRARIES,
  })
  const [zoomValue, setZoomValue] = useState(6)
  const [center, setCenter] = useState({
    lat: 43.485051,
    lng: -80.508513,
  })
  const markerOptions = useFragment(MAP_FRAGMENT, _markerOptions)

  useEffect(() => {
    if (marker) {
      setCenter({lat: marker.latitude, lng: marker.longitude})
    }
    if (zoom) {
      setZoomValue(zoom)
    }
  }, [marker, zoom])

  const mapContainerStyle = {
    height: height,
    width: `100%`,
  }

  function getZoomValueOnZoomChanged() {
    setZoomValue(this.getZoom())
  }

  if (!isLoaded) {
    return <Loading />
  }

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={zoomValue}
      center={center}
      onZoomChanged={getZoomValueOnZoomChanged}
    >
      {markerOptions && (
        <MarkerOptions markerOptions={markerOptions} zoomValue={zoomValue} />
      )}

      {marker && (
        <SingleMarker
          position={{lat: marker.latitude, lng: marker.longitude}}
          onChange={onChange}
        />
      )}
    </GoogleMap>
  )
}

function MarkerOptions({markerOptions, zoomValue}) {
  const openModal = useModalsStore((state) => state.openModal)
  const [markerIcon, setMarkerIcon] = useState(
    `https://storage.googleapis.com/support-kms-prod/SNP_2752125_en_v0`
  )
  const [position, setPosition] = useState(null)
  const [markerSelected, setMarkerSelected] = useState(null)

  useEffect(() => {
    if (zoomValue < 7) {
      setMarkerIcon(
        `https://storage.googleapis.com/support-kms-prod/SNP_2752125_en_v0`
      )
    } else {
      setMarkerIcon(
        `https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png`
      )
    }
  }, [zoomValue])

  function handleClickMarker(markerOption) {
    setPosition({
      lat: markerOption.latitude,
      lng: markerOption.longitude,
    })
    setMarkerSelected(markerOption)
  }

  return (
    <>
      <For
        of={markerOptions}
        as={(markerOption) => (
          <Marker
            icon={markerIcon}
            position={{lat: markerOption.latitude, lng: markerOption.longitude}}
            onClick={() => handleClickMarker(markerOption)}
          />
        )}
      />
      {position && markerSelected && (
        <InfoWindow onCloseClick={() => setPosition(null)} position={position}>
          <Box>
            <Text fontWeight="bold" fontSize="lg" mb={4}>
              {markerSelected?.name}
            </Text>
            <Text fontWeight="bold">Facility:</Text>
            <Text>{markerSelected?.facility?.name}</Text>
            <Text fontWeight="bold">Address: </Text>
            <Text>{markerSelected?.address}</Text>
            <Text fontWeight="bold">Surface: </Text>
            <Text>{markerSelected?.surface?.name}</Text>
            <Text fontWeight="bold">Type: </Text>
            <Text>{markerSelected?.type}</Text>
            <hr style={{margin: `0.5em`}} />
            <Stack direction="row" spacing={3}>
              {markerSelected.hasWashRoom && (
                <Badge colorScheme="green">Wash Room</Badge>
              )}
              {markerSelected.hasShower && (
                <Badge colorScheme="green">Shower</Badge>
              )}
              {markerSelected.hasChangeRoom && (
                <Badge colorScheme="green">Change Room</Badge>
              )}
              {markerSelected.hasOutdoorLight && (
                <Badge colorScheme="green">Outdoor Light</Badge>
              )}
            </Stack>
            <Button
              colorScheme="blue"
              mt={5}
              onClick={() =>
                openModal({
                  modalType: `EDIT_LOCATION_MODAL`,
                  modalProps: {locationId: markerSelected.id},
                })
              }
            >
              Edit
            </Button>
          </Box>
        </InfoWindow>
      )}
    </>
  )
}

function SingleMarker({onChange, position: _position}) {
  const [position, setPosition] = useState(_position)

  useEffect(() => {
    setPosition(_position)
  }, [_position])

  const moveMarker = (coord) => {
    const lat = coord.latLng.lat()
    const lng = coord.latLng.lng()

    onChange({latitude: lat, longitude: lng})
    setPosition({lat, lng})
  }

  return <Marker draggable={true} onDragEnd={moveMarker} position={position} />
}
