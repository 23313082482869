import {Spinner} from '@chakra-ui/react'

import {LoadingContainer, LoadingContainerProps} from './LoadingContainer'

interface LoadingPageProps extends LoadingContainerProps {}

export function LoadingPage(props: LoadingPageProps) {
  return (
    <LoadingContainer mt={8} {...props}>
      <Spinner color="primary.500" size="lg" />
    </LoadingContainer>
  )
}
