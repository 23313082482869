import React from 'react'
import {graphql, useFragment} from 'react-relay/hooks'
import {Box, Flex} from '@chakra-ui/react'

import {Cost_template$key} from './__generated__/Cost_template.graphql'

export interface CostProps {
  template: Cost_template$key
}

export function Cost(props: CostProps) {
  const {gameCost} = useFragment(
    graphql`
      fragment Cost_template on GameTemplate {
        gameCost
      }
    `,
    props.template
  )
  const [absolute, decimals] = gameCost.toString().split(`.`)

  return (
    <Flex align="center" color="primary.500" fontSize="xl">
      ${absolute}
      {decimals && <Box as="sup">.{decimals}</Box>}
    </Flex>
  )
}
