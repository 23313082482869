import {format, formatDistance, parseISO} from 'date-fns'
import React from 'react'
import {Box, BoxProps} from '@chakra-ui/react'

interface TimeProps {
  format?: string
  relative?: boolean
  titleFormat?: string
  value: number | string | Date
}

export default function Time({
  format: dateFormat,
  relative,
  titleFormat = `yyyy-MM-dd HH:mm`,
  value,
  ...rest
}: TimeProps & BoxProps) {
  if (typeof value === `string`) {
    value = parseISO(value)
  }

  const machineReadable = format(value, `yyyy-MM-dd'T'HH:mm:ssxxx`)

  if (dateFormat || relative) {
    const humanReadable = relative
      ? formatDistance(value, new Date(), {addSuffix: true})
      : format(value, dateFormat)

    return (
      <Box
        as="time"
        title={relative ? format(value, titleFormat) : null}
        {...rest}
        {...{dateTime: machineReadable}}
      >
        {humanReadable}
      </Box>
    )
  }

  return (
    <Box as="time" {...rest} dateTime={machineReadable}>
      {machineReadable}
    </Box>
  )
}
