import {
  addDays,
  addWeeks,
  format,
  isSameDay,
  isSameMonth,
  isSameWeek,
  isSameYear,
  isWeekend,
  parseISO,
  subDays,
} from 'date-fns'

import {GameFeed_games$data} from './__generated__/GameFeed_games.graphql'

export function buildTimeline(games: GameFeed_games$data): {
  [key: string]: GameFeed_games$data
} {
  return games.reduce((games, game) => {
    const {startTime} = game
    const label = timeLabel(startTime)

    ;(games[label] = games[label] || []).push(game)

    return games
  }, {})
}

const cache = {}
const now = new Date()

export function timeLabel(date: Date | string) {
  if (typeof date === `string`) {
    date = parseISO(date)
  }

  const cacheKey = date.toDateString()

  if (cache[cacheKey]) {
    return cache[cacheKey]
  }

  let label: string

  if (isSameDay(date, subDays(now, 1))) {
    label = `Yesterday`
  } else if (isSameDay(date, now)) {
    label = `Today`
  } else if (isSameDay(date, addDays(now, 1))) {
    label = `Tomorrow`
  } else if (isSameWeek(date, now, {weekStartsOn: 1}) && !isWeekend(date)) {
    label = `This week`
  } else if (isSameWeek(date, now, {weekStartsOn: 1}) && isWeekend(date)) {
    label = `This weekend`
  } else if (isSameWeek(date, addWeeks(now, 1), {weekStartsOn: 1})) {
    label = `Next week`
  } else if (isSameMonth(date, now)) {
    label = `This month`
  } else if (isSameYear(date, now)) {
    label = format(date, `MMMM`)
  } else {
    label = format(date, `MMMM, yyyy`)
  }

  cache[cacheKey] = label

  return label
}
