/* eslint-disable */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: string;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: string;
  /** A datetime and timezone string in ISO 8601 format `Y-m-dTH:i:sP`, e.g. `2020-04-20T13:53:12+02:00`. */
  DateTimeTz: any;
  /** A [RFC 5321](https://tools.ietf.org/html/rfc5321) compliant email. */
  Email: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** An RFC 3986 and RFC 3987 compliant URI string. */
  URL: string;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec. */
  Upload: any;
};

export type ActivityDetail = {
  __typename?: 'ActivityDetail';
  cancelGame?: Maybe<Scalars['String']>;
  edited?: Maybe<Scalars['JSON']>;
  gameId?: Maybe<Scalars['Int']>;
  playerId?: Maybe<Scalars['Int']>;
  playerName?: Maybe<Scalars['String']>;
  sentTo?: Maybe<Scalars['String']>;
  sport?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Represents an object which can take actions on PickupHub. Typically a User or Provider. */
export type Actor = {
  /** Avatar image for the actor. */
  avatar?: Maybe<Image>;
  /** A URL pointing to the actor's public avatar. */
  avatarUrl?: Maybe<Scalars['URL']>;
  /** The name of the actor. */
  name: Scalars['String'];
  /** The HTTP path for this actor. */
  resourcePath: Scalars['URL'];
  /** The HTTP URL for this actor. */
  url: Scalars['URL'];
};

export type AddCommentInput = {
  /** The contents of the comment. */
  body: Scalars['String'];
  /** The parent comment id. */
  parentCommentId?: Maybe<Scalars['ID']>;
  /** The Node ID of the subject to modify. */
  subjectId: Scalars['ID'];
};

export type AddCommentPayload = {
  __typename?: 'AddCommentPayload';
  /** The edge from the subject's comment connection. */
  commentEdge?: Maybe<CommentEdge>;
  /** The subject */
  subject?: Maybe<Node>;
};

export type AdminActivity = {
  __typename?: 'AdminActivity';
  /** Action of admin activity */
  action: AdminActivityActionType;
  /** Activity Create At */
  createdAt: Scalars['DateTime'];
  /** Details of admin activity */
  extras: ActivityDetail;
  /** Admin Activity's Provider */
  provider?: Maybe<Provider>;
};

/** Admin activity action type */
export enum AdminActivityActionType {
  /** Add player to game */
  ADD_PLAYER_TO_GAME = 'ADD_PLAYER_TO_GAME',
  /** Add profile picture */
  ADD_PROFILE_PICTURE = 'ADD_PROFILE_PICTURE',
  /** Cancel game */
  CANCEL_GAME = 'CANCEL_GAME',
  /** Cancel game by tipping point */
  CANCEL_GAME_BY_TIPPING_POINT = 'CANCEL_GAME_BY_TIPPING_POINT',
  /** Change position */
  CHANGE_POSITION = 'CHANGE_POSITION',
  /** Create game */
  CREATE_GAME = 'CREATE_GAME',
  /** Credit player */
  CREDIT_PLAYER = 'CREDIT_PLAYER',
  /** Delete game */
  DELETE_GAME = 'DELETE_GAME',
  /** Edit game */
  EDIT_GAME = 'EDIT_GAME',
  /** Edit game player */
  EDIT_GAME_PLAYER = 'EDIT_GAME_PLAYER',
  /** Exporting customer list */
  EXPORTING_CUSTOMER_LIST = 'EXPORTING_CUSTOMER_LIST',
  /** Login */
  LOGIN = 'LOGIN',
  /** Logout */
  LOGOUT = 'LOGOUT',
  /** Remove player from game */
  REMOVE_PLAYER_FROM_GAME = 'REMOVE_PLAYER_FROM_GAME',
  /** Remove profile picture */
  REMOVE_PROFILE_PICTURE = 'REMOVE_PROFILE_PICTURE',
  /** Send out email to customers */
  SEND_OUT_EMAIL_TO_CUSTOMERS = 'SEND_OUT_EMAIL_TO_CUSTOMERS',
  /** Send out email to game players */
  SEND_OUT_EMAIL_TO_GAME_PLAYERS = 'SEND_OUT_EMAIL_TO_GAME_PLAYERS'
}

/** A paginated list of AdminActivity edges. */
export type AdminActivityConnection = {
  __typename?: 'AdminActivityConnection';
  /** A list of AdminActivity edges. */
  edges: Array<AdminActivityEdge>;
  /** Pagination information about the list of edges. */
  pageInfo: PageInfo;
};

/** An edge that contains a node of type AdminActivity and a cursor. */
export type AdminActivityEdge = {
  __typename?: 'AdminActivityEdge';
  /** A unique cursor that can be used for pagination. */
  cursor: Scalars['String'];
  /** The AdminActivity node. */
  node: AdminActivity;
};

/** Ways in which activity connections can be ordered. */
export type AdminActivityOrder = {
  /** The direction in which to order nodes. */
  direction: OrderDirection;
  /** The field in which to order nodes by. */
  field: AdminActivityOrderField;
};

/** Order by clause for the `orderBy` argument on the query `posts`. */
export enum AdminActivityOrderField {
  CREATED_AT = 'CREATED_AT'
}

/** Attachment. */
export type Attachment = {
  __typename?: 'Attachment';
  /** File creation date */
  createdAt: Scalars['DateTime'];
  /** Extension file. */
  extension: Scalars['String'];
  /** Media object (image, video, etc.) contained in the attachment. */
  media: AttachmentMedia;
  /** Represents the mime type of the media. */
  mimeType?: Maybe<Scalars['String']>;
  /** Name of the attachment. */
  name: Scalars['String'];
  /** Order of the attachment. */
  order: Scalars['Int'];
  /** Bytes file size */
  size: Scalars['Int'];
  /** URL of the attachment */
  url: Scalars['URL'];
};

/** A paginated list of Attachment edges. */
export type AttachmentConnection = {
  __typename?: 'AttachmentConnection';
  /** A list of Attachment edges. */
  edges: Array<AttachmentEdge>;
  /** Pagination information about the list of edges. */
  pageInfo: PageInfo;
};

/** An edge that contains a node of type Attachment and a cursor. */
export type AttachmentEdge = {
  __typename?: 'AttachmentEdge';
  /** A unique cursor that can be used for pagination. */
  cursor: Scalars['String'];
  /** The Attachment node. */
  node: Attachment;
};

/** Attachment media. */
export type AttachmentMedia = {
  __typename?: 'AttachmentMedia';
  image: AttachmentMediaImage;
};

/** Attachment media. */
export type AttachmentMediaImage = {
  __typename?: 'AttachmentMediaImage';
  /** The media's height. */
  height: Scalars['Int'];
  /** The media's src. */
  src: Scalars['URL'];
  /** The media's width. */
  width: Scalars['Int'];
};

export type BooleanWrapper = {
  __typename?: 'BooleanWrapper';
  booleanValue?: Maybe<Scalars['Boolean']>;
};

export type ChangePasswordInput = {
  confirmPassword: Scalars['String'];
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  providerId: Scalars['ID'];
};

/** Specifies the input fields required to create or update a child. */
export type ChildInput = {
  /** The child's birth date. */
  birthDate?: Maybe<Scalars['Date']>;
  /** The child's color. */
  color?: Maybe<Scalars['String']>;
  /** The child's first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The child's gender. */
  gender?: Maybe<Gender>;
  /** Specifies the child to update in updateChild or creates a new child if absent in createChild. */
  id?: Maybe<Scalars['ID']>;
  /** The child's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** Create only required field. Specifies the user on which to create the child. */
  parentId?: Maybe<Scalars['ID']>;
};

/** Represents a City. */
export type City = UniformResourceLocatable & {
  __typename?: 'City';
  /** A list of facilities associated with the city. */
  facilities: Array<Facility>;
  /** A list of games associated with the city. */
  games: GameConnection;
  /** Globally unique identifier. */
  id: Scalars['ID'];
  /** The city's latitude. */
  latitude: Scalars['Float'];
  /** A list of locations associated with the city. */
  locations: Array<Location>;
  /** The city's longitude. */
  longitude: Scalars['Float'];
  /** The city's name. */
  name: Scalars['String'];
  /** The Province this location belongs to. */
  province?: Maybe<Province>;
  /** The HTML path to this resource. */
  resourcePath: Scalars['URL'];
  /** The URL to this resource. */
  url: Scalars['URL'];
};


/** Represents a City. */
export type CitygamesArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
  youth?: Maybe<Scalars['Boolean']>;
};

export type ClearCacheInput = {
  keys: Array<Scalars['String']>;
};

export type Comment = Deletable & Updatable & {
  __typename?: 'Comment';
  /** The actor who authored the comment. */
  author: Actor;
  /** Identifies the comment body. */
  body: Scalars['String'];
  /** Identifies the comment body rendered to HTML. */
  bodyHTML: Scalars['String'];
  /** The body rendered to text. */
  bodyText: Scalars['String'];
  /** Comments that reply to this comment. */
  comments: CommentConnection;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTimeTz'];
  /** Globally unique identifier. */
  id: Scalars['ID'];
  /** Checks if this object is hidden or visible. */
  isHidden?: Maybe<Scalars['Boolean']>;
  /** The parent of the comment. */
  parent?: Maybe<Comment>;
  /** Check if the current viewer can delete this object. */
  viewerCanDelete: Scalars['Boolean'];
  /** Check if the current viewer can update this object. */
  viewerCanUpdate: Scalars['Boolean'];
  /** Did the viewer author this comment. */
  viewerDidAuthor: Scalars['Boolean'];
};


export type CommentcommentsArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};

/** A paginated list of Comment edges. */
export type CommentConnection = {
  __typename?: 'CommentConnection';
  /** A list of Comment edges. */
  edges: Array<CommentEdge>;
  /** Pagination information about the list of edges. */
  pageInfo: PageInfo;
};

/** An edge that contains a node of type Comment and a cursor. */
export type CommentEdge = {
  __typename?: 'CommentEdge';
  /** A unique cursor that can be used for pagination. */
  cursor: Scalars['String'];
  /** The Comment node. */
  node: Comment;
};

/** Ways in which to filter lists of comments. */
export enum CommentFilters {
  /** All-level comments in chronological order. */
  STREAM = 'STREAM',
  /** It returns all top-level comments in chronological order. */
  TOPLEVEL = 'TOPLEVEL'
}

/** Represents a subject that can be commented on. */
export type Commentable = {
  /** A list of comments left on the subject. */
  comments?: Maybe<CommentConnection>;
  id: Scalars['ID'];
};


/** Represents a subject that can be commented on. */
export type CommentablecommentsArgs = {
  after?: Maybe<Scalars['String']>;
  filterBy?: Maybe<CommentFilters>;
  first: Scalars['Int'];
};

export type CreateAdminInput = {
  confirmPassword: Scalars['String'];
  email: Scalars['Email'];
  name: Scalars['String'];
  password: Scalars['String'];
  viewerId: Scalars['ID'];
};

export type CreateChildPayload = {
  __typename?: 'CreateChildPayload';
  /** The newly created child. */
  childEdge?: Maybe<UserEdge>;
  /** The user associated with the child. */
  parent: User;
};

export type CreateFacilityInput = {
  /** Address of facility */
  address?: Maybe<Scalars['String']>;
  /** Name of facilty */
  name?: Maybe<Scalars['String']>;
  /** Phone Number of facility */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Website of facility */
  website?: Maybe<Scalars['String']>;
};

export type CreateLocationInput = {
  /** the address of location. */
  address?: Maybe<Scalars['String']>;
  /** the city of location belongs. */
  cityId: Scalars['ID'];
  /** add a new facility */
  facility?: Maybe<CreateFacilityInput>;
  /** the facility of the location */
  facilityId?: Maybe<Scalars['ID']>;
  /** location has a change room. */
  hasChangeRoom: Scalars['Boolean'];
  /** location has a outdoor light. */
  hasOutdoorLight: Scalars['Boolean'];
  /** location has a shower. */
  hasShower: Scalars['Boolean'];
  /** location has a wash room. */
  hasWashRoom: Scalars['Boolean'];
  /** location is outdoor. */
  isOutdoor: Scalars['Boolean'];
  /** Latitude of marker in map. */
  latitude: Scalars['Float'];
  /** Longitude of marker in map. */
  longitude: Scalars['Float'];
  /** name of location. */
  name: Scalars['String'];
  /** type of surface of location. */
  surfaceId: Scalars['ID'];
};

export type CreateProviderPayload = {
  __typename?: 'CreateProviderPayload';
  adminEdge?: Maybe<ProviderEdge>;
};

export type Credit = {
  __typename?: 'Credit';
  /** Amount credit */
  amount?: Maybe<Scalars['Float']>;
  /** Date of creation */
  createdAt: Scalars['DateTime'];
  /** Credit ID */
  id: Scalars['ID'];
  /** Credit owner */
  provider: Provider;
  /** User of credit */
  user: User;
};

/** Crop region */
export enum CropRegion {
  /** Keep the bottom of the image. */
  BOTTOM = 'BOTTOM',
  /** Keep the center of the image. */
  CENTER = 'CENTER',
  /** Keep the left of the image. */
  LEFT = 'LEFT',
  /** Keep the right of the image. */
  RIGHT = 'RIGHT',
  /** Keep the top of the image. */
  TOP = 'TOP'
}

/** Day of the week */
export enum DayOfTheWeek {
  /** Friday */
  FRIDAY = 'FRIDAY',
  /** Monday */
  MONDAY = 'MONDAY',
  /** Saturday */
  SATURDAY = 'SATURDAY',
  /** Sunday */
  SUNDAY = 'SUNDAY',
  /** Thursday */
  THURSDAY = 'THURSDAY',
  /** Tuesday */
  TUESDAY = 'TUESDAY',
  /** Wednesday */
  WEDNESDAY = 'WEDNESDAY'
}

/** Day period */
export enum DayPeriod {
  /** Afternoon */
  AFTERNOON = 'AFTERNOON',
  /** Evening */
  EVENING = 'EVENING',
  /** Morning */
  MORNING = 'MORNING'
}

/** Entities that can be deleted. */
export type Deletable = {
  /** Check if the current viewer can delete this object. */
  viewerCanDelete: Scalars['Boolean'];
};

export type DeleteAdminInput = {
  adminId: Scalars['ID'];
};

export type DeleteChildInput = {
  /** The ID of the child. */
  id: Scalars['ID'];
};

export type DeleteChildPayload = {
  __typename?: 'DeleteChildPayload';
  /** The ID of the deleted child. */
  deletedChildId?: Maybe<Scalars['ID']>;
  /** The user associated with the child. */
  parent: User;
};

export type DeleteLocationInput = {
  /** the location id */
  locationId: Scalars['ID'];
};

export type DeleteLocationPayload = {
  __typename?: 'DeleteLocationPayload';
  deletedLocationId?: Maybe<Scalars['ID']>;
};

export type DeleteProviderPayload = {
  __typename?: 'DeleteProviderPayload';
  /** The deleted admin of a provider */
  deletedAdminId?: Maybe<Scalars['ID']>;
};

/** Represents an error in the input of a mutation. */
export type DisplayableError = {
  /** Path to the input field which caused the error. */
  field?: Maybe<Array<Scalars['String']>>;
  /** The error message. */
  message: Scalars['String'];
};

export type DropdownKeys = {
  __typename?: 'DropdownKeys';
  data?: Maybe<ProvinceKeys>;
};

/** Represents a Facility. */
export type Facility = {
  __typename?: 'Facility';
  /** The address of the facility. */
  address: Scalars['String'];
  /** The city this facility belongs to. */
  city: City;
  /** Globally unique identifier. */
  id: Scalars['ID'];
  /** The facility's latitude. */
  latitude: Scalars['Float'];
  /** The facility's longitude. */
  longitude: Scalars['Float'];
  /** The name of the facility. */
  name: Scalars['String'];
};

/** Represents a Field Section. */
export type FieldSection = {
  __typename?: 'FieldSection';
  /** The name of the section. */
  name: Scalars['String'];
  /** The sort order of the section. */
  sortOrder: Scalars['Int'];
};

export type FilterItem = {
  __typename?: 'FilterItem';
  params: Array<FilterItemParam>;
  province?: Maybe<Scalars['String']>;
  selected: Scalars['Boolean'];
  shouldBeOpen?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: FilterItemType;
};

export type FilterItemParam = {
  __typename?: 'FilterItemParam';
  key: Scalars['String'];
  value: ValueWrapper;
  valueType: FilterItemParamValueType;
};

/** Filter item param value type */
export enum FilterItemParamValueType {
  /** Array */
  ARRAY = 'ARRAY',
  /** Boolean */
  BOOLEAN = 'BOOLEAN'
}

/** Filter item type */
export enum FilterItemType {
  /** Checkbox */
  CHECKBOX = 'CHECKBOX'
}

export type FilterSection = {
  __typename?: 'FilterSection';
  filterBarTitle: Scalars['String'];
  filterSectionId: Scalars['String'];
  items: Array<Maybe<FilterItem>>;
  selected: Scalars['Boolean'];
  title: Scalars['String'];
};

export type Filters = {
  __typename?: 'Filters';
  allFiltersCount?: Maybe<Scalars['Int']>;
  filterBarOrdering: Array<Scalars['String']>;
  id: Scalars['ID'];
  sections: Array<FilterSection>;
};

export type FollowUserInput = {
  /** ID of the user to follow. */
  userId: Scalars['ID'];
};

export type FollowUserPayload = {
  __typename?: 'FollowUserPayload';
  /** The follow edge for update the list of followers. */
  followEdge?: Maybe<UserEdge>;
  /** The user that was followed. */
  user?: Maybe<User>;
};

/** Represents a Game. */
export type Game = Commentable & Node & UniformResourceLocatable & {
  __typename?: 'Game';
  /** A list of comments associated with the game. */
  comments: CommentConnection;
  /** The maximum to participate in this game. */
  endAge: Scalars['Int'];
  /** End time. */
  endTime: Scalars['DateTime'];
  /** A list of favorite users who have registered in the context of the game. */
  favoritePlayers: Array<User>;
  /** Whether the game is full or not */
  gameIsFull: Scalars['Boolean'];
  /** Number of goalies signed up. */
  goaliesCount: Scalars['Int'];
  id: Scalars['ID'];
  /** Whether the game was cancelled or not */
  isCancelled?: Maybe<Scalars['Boolean']>;
  /** The Location this game belongs to. */
  location: Location;
  /** The minimum number of players to go. */
  minimumPlayers: Scalars['Int'];
  /** The number of teams in this game. */
  numberOfTeams: Scalars['Int'];
  /** A list of users who are players of this team. */
  players: UserConnection;
  /** The HTML path to this resource. */
  resourcePath: Scalars['URL'];
  /** The Sport this game belongs to. */
  sport: Sport;
  /** The minimum  age to participate in this game. */
  startAge: Scalars['Int'];
  /** Start time. */
  startTime: Scalars['DateTime'];
  /** The Template this game belongs to. */
  template: GameTemplate;
  /** The title of the game. */
  title?: Maybe<Scalars['String']>;
  /** The unlock time of the game. */
  unlockTime: Scalars['DateTime'];
  /** The unlock time of the game in ISO 8601 format. */
  unlockTimeTz: Scalars['DateTimeTz'];
  /** The URL to this resource. */
  url: Scalars['URL'];
  /** The information about an specific player in this game */
  userDetails?: Maybe<UserDetails>;
  /** Viewer children members of this game. */
  viewerChildrenMembers: UserConnection;
  viewerChildrenTeam?: Maybe<Array<Maybe<GameWiewerTeam>>>;
  /** Whether the viewer is a goalie player of this game. */
  viewerIsAGoaliePlayer: Scalars['Boolean'];
  /** Whether the viewer is a player of this game. */
  viewerIsAPlayer: Scalars['Boolean'];
  /** Whether the viewer is on the remind me list of this game. */
  viewerIsOnRemindMeList: Scalars['Boolean'];
  /** Whether the viewer is on the waiting list of this game. */
  viewerIsOnWaitingList: Scalars['Boolean'];
  /** The information of the team if the viewer is a player of this game otherwise null. */
  viewerTeam?: Maybe<GameWiewerTeam>;
};


/** Represents a Game. */
export type GamecommentsArgs = {
  after?: Maybe<Scalars['String']>;
  filterBy?: Maybe<CommentFilters>;
  first: Scalars['Int'];
};


/** Represents a Game. */
export type GameplayersArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** Represents a Game. */
export type GameuserDetailsArgs = {
  userId: Scalars['ID'];
};


/** Represents a Game. */
export type GameviewerChildrenMembersArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};

/** A paginated list of Game edges. */
export type GameConnection = {
  __typename?: 'GameConnection';
  /** A list of Game edges. */
  edges: Array<GameEdge>;
  /** Pagination information about the list of edges. */
  pageInfo: PageInfo;
};

/** An edge that contains a node of type Game and a cursor. */
export type GameEdge = {
  __typename?: 'GameEdge';
  /** A unique cursor that can be used for pagination. */
  cursor: Scalars['String'];
  /** The Game node. */
  node: Game;
};

/** Ways in which game connections can be ordered. */
export type GameOrder = {
  /** The direction in which to order nodes. */
  direction: OrderDirection;
  /** The field in which to order nodes by. */
  field: GameOrderField;
};

/** Properties by which game connections can be ordered. */
export enum GameOrderField {
  /** Allows ordering a list of games by start time. */
  START_TIME = 'START_TIME'
}

export type GameTemplate = {
  __typename?: 'GameTemplate';
  /** The cost of the position. */
  cost: Scalars['Float'];
  /** Cost of the positions associated with the template and age bracket for the user. */
  gameCost?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** The name of the template. */
  name: Scalars['String'];
  /** A list of positions associated with the template. */
  positions: GameTemplatePositionConnection;
};


export type GameTemplatepositionsArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type GameTemplatePosition = {
  __typename?: 'GameTemplatePosition';
  /** The cost of the position. */
  cost: Scalars['Float'];
  id: Scalars['ID'];
  position: Position;
};

/** A paginated list of GameTemplatePosition edges. */
export type GameTemplatePositionConnection = {
  __typename?: 'GameTemplatePositionConnection';
  /** A list of GameTemplatePosition edges. */
  edges: Array<GameTemplatePositionEdge>;
  /** Pagination information about the list of edges. */
  pageInfo: PageInfo;
};

/** An edge that contains a node of type GameTemplatePosition and a cursor. */
export type GameTemplatePositionEdge = {
  __typename?: 'GameTemplatePositionEdge';
  /** A unique cursor that can be used for pagination. */
  cursor: Scalars['String'];
  /** The GameTemplatePosition node. */
  node: GameTemplatePosition;
};

/** Times by which game connections can be filtered. */
export enum GameTimeFilter {
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  MORNING = 'MORNING',
  PAST = 'PAST',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  UPCOMING = 'UPCOMING',
  WEDNESDAY = 'WEDNESDAY',
  WEEKEND = 'WEEKEND'
}

export type GameWiewerTeam = {
  __typename?: 'GameWiewerTeam';
  /** Team color. */
  color: Scalars['String'];
  /** Team name. */
  name: Scalars['String'];
};

/** Gender */
export enum Gender {
  /** Female */
  FEMALE = 'FEMALE',
  /** Male */
  MALE = 'MALE'
}

export type Image = {
  __typename?: 'Image';
  /** The original height of the image in pixels. */
  height?: Maybe<Scalars['Int']>;
  /** A unique identifier for the image. */
  id: Scalars['ID'];
  /** The location of the original image as a URL. */
  originalSrc: Scalars['URL'];
  /**
   * The location of the transformed image as a URL.
   *
   * All transformation arguments are considered "best-effort".
   * If they can be applied to an image, they will be.
   * Otherwise any transformations which an image type does not support will be ignored.
   */
  transformedSrc: Scalars['URL'];
  /** The original width of the image in pixels. */
  width?: Maybe<Scalars['Int']>;
};


export type ImagetransformedSrcArgs = {
  crop?: Maybe<CropRegion>;
  maxHeight?: Maybe<Scalars['Int']>;
  maxWidth?: Maybe<Scalars['Int']>;
  preferredContentType?: Maybe<ImageContentType>;
  scale?: Maybe<Scalars['Int']>;
};

/** A paginated list of Image edges. */
export type ImageConnection = {
  __typename?: 'ImageConnection';
  /** A list of Image edges. */
  edges: Array<ImageEdge>;
  /** Pagination information about the list of edges. */
  pageInfo: PageInfo;
};

/** Image content type */
export enum ImageContentType {
  /** A JPG image. */
  JPG = 'JPG',
  /** A PNG image. */
  PNG = 'PNG',
  /** A WEBP image. */
  WEBP = 'WEBP'
}

/** An edge that contains a node of type Image and a cursor. */
export type ImageEdge = {
  __typename?: 'ImageEdge';
  /** A unique cursor that can be used for pagination. */
  cursor: Scalars['String'];
  /** The Image node. */
  node: Image;
};

export type IntWrapper = {
  __typename?: 'IntWrapper';
  intValue?: Maybe<Scalars['Int']>;
};

/** An user joins the game remind me list. */
export type JoinRemindListInput = {
  /** The ID of the game. */
  gameId: Scalars['ID'];
};

export type JoinRemindListPayload = {
  __typename?: 'JoinRemindListPayload';
  /** The game whose remind me list was joined */
  game?: Maybe<Game>;
  /** The user that joined the remind me list of the game */
  user?: Maybe<User>;
};

export type JoinWaitingListPayload = {
  __typename?: 'JoinWaitingListPayload';
  /** The game whose waitlist was joined */
  game?: Maybe<Game>;
  /** The user that waitlisted the game */
  user?: Maybe<User>;
};

/** An user joins the game waiting list. */
export type JoinWaitlistInput = {
  /** The ID of the game. */
  gameId: Scalars['ID'];
  /** The position type that the it's being waitlisted */
  positionType?: Maybe<PositionType>;
};

/** An user leave game remind list. */
export type LeaveRemindListInput = {
  /** The ID of the game. */
  gameId: Scalars['ID'];
};

export type LeaveRemindListPayload = {
  __typename?: 'LeaveRemindListPayload';
  /** The game whose remind me list was left */
  game?: Maybe<Game>;
};

export type LeaveWaitingListPayload = {
  __typename?: 'LeaveWaitingListPayload';
  /** The game whose waitlist was left */
  game?: Maybe<Game>;
};

/** An user leave game waiting list. */
export type LeaveWaitlistInput = {
  /** The ID of the game. */
  gameId: Scalars['ID'];
};

export type Location = UniformResourceLocatable & {
  __typename?: 'Location';
  /** The address line 1 of location. */
  address?: Maybe<Scalars['String']>;
  /** The city that location belongs */
  city?: Maybe<City>;
  /** The Facility this location belongs to. */
  facility?: Maybe<Facility>;
  /** A list of games associated with the location. */
  games: GameConnection;
  /** Location has change room */
  hasChangeRoom: Scalars['Boolean'];
  /** Location has outdoor light */
  hasOutdoorLight: Scalars['Boolean'];
  /** Location has shower */
  hasShower: Scalars['Boolean'];
  /** Location has washroom */
  hasWashRoom: Scalars['Boolean'];
  /** Globally unique identifier. */
  id: Scalars['ID'];
  /** The location is outdoor or indoor */
  isOutdoor: Scalars['Boolean'];
  /** The location's latitude. */
  latitude: Scalars['Float'];
  /** The location's longitude. */
  longitude: Scalars['Float'];
  /** The name of the location. */
  name: Scalars['String'];
  /** The HTML path to this resource. */
  resourcePath: Scalars['URL'];
  /** The type of surface. */
  surface?: Maybe<Surface>;
  /** The URL to this resource. */
  url: Scalars['URL'];
};


export type LocationgamesArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};

/** A paginated list of Location edges. */
export type LocationConnection = {
  __typename?: 'LocationConnection';
  /** A list of Location edges. */
  edges: Array<LocationEdge>;
  /** Pagination information about the list of edges. */
  pageInfo: PageInfo;
};

/** An edge that contains a node of type Location and a cursor. */
export type LocationEdge = {
  __typename?: 'LocationEdge';
  /** A unique cursor that can be used for pagination. */
  cursor: Scalars['String'];
  /** The Location node. */
  node: Location;
};

/** A custom description for this custom enum. */
export enum LocationOrderByColumn {
  LOCATION_ID = 'LOCATION_ID',
  LOCATION_NAME = 'LOCATION_NAME'
}

export type LocationPayload = {
  __typename?: 'LocationPayload';
  locationEdge: LocationEdge;
};

export type Mutation = {
  __typename?: 'Mutation';
  addComment?: Maybe<AddCommentPayload>;
  changePassword?: Maybe<Provider>;
  clearCache?: Maybe<Scalars['Boolean']>;
  commentHiddenStatusUpdate: Comment;
  createAdmin?: Maybe<CreateProviderPayload>;
  /** Creates a child. */
  createChild?: Maybe<CreateChildPayload>;
  /** Create location a new location. */
  createLocation?: Maybe<LocationPayload>;
  deleteAdmin?: Maybe<DeleteProviderPayload>;
  /** Deletes a child. */
  deleteChild?: Maybe<DeleteChildPayload>;
  /** Delete location. */
  deleteLocation?: Maybe<DeleteLocationPayload>;
  /** Follow a user. */
  followUser?: Maybe<FollowUserPayload>;
  /** Mutation for joining the remind me list of a game. */
  joinRemindList?: Maybe<JoinRemindListPayload>;
  /** Mutation to join the waitlist of a game. */
  joinWaitingList?: Maybe<JoinWaitingListPayload>;
  /** Mutation for leaving the remind me list of a game. */
  leaveRemindList?: Maybe<LeaveRemindListPayload>;
  /** Mutation for leaving the waitlist of a game. */
  leaveWaitingList?: Maybe<LeaveWaitingListPayload>;
  /** Unfollow a user. */
  unfollowUser?: Maybe<UnfollowUserPayload>;
  updateAdmin?: Maybe<Provider>;
  /** Updates a child. */
  updateChild?: Maybe<UpdateChildPayload>;
  /** Update if the user wants the remind me explanation modal to show. */
  updateDisplayRemindMeModal?: Maybe<UpdateDisplayRemindMeModalPayload>;
  /** Update if the user wants the waiting list confirmation modal to show. */
  updateDisplayWaitlistModal?: Maybe<UpdateDisplayWaitlistModalPayload>;
  updateHubFilters: Filters;
  /** Update a existent location. */
  updateLocation?: Maybe<Location>;
  /** Update an user. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Update the user dropdown filter */
  updateUserDropdownFilter?: Maybe<UpdateUserDropdownFilterPayload>;
  /** Update an user notification categories. */
  updateUserNotificationCategory?: Maybe<UpdateUserPayload>;
  /** Update an user password. */
  updateUserPassword?: Maybe<UpdateUserPayload>;
  /** Update the photo of the user. */
  updateUserPhoto?: Maybe<UpdateUserPayload>;
  /** Mutation for update the profile of an user. */
  updateUserProfile?: Maybe<UpdateUserProfilePayload>;
  /** Deletes a user. */
  userDelete?: Maybe<UserDeletePayload>;
  /** Updates a user. */
  userUpdate?: Maybe<UserUpdatePayload>;
  userUpdatePrivacy?: Maybe<UserUpdatePrivacyPayload>;
};


export type MutationaddCommentArgs = {
  input: AddCommentInput;
};


export type MutationchangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationclearCacheArgs = {
  input: ClearCacheInput;
};


export type MutationcommentHiddenStatusUpdateArgs = {
  input: UpdateCommentHiddenStatusInput;
};


export type MutationcreateAdminArgs = {
  input: CreateAdminInput;
};


export type MutationcreateChildArgs = {
  input: ChildInput;
};


export type MutationcreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationdeleteAdminArgs = {
  input: DeleteAdminInput;
};


export type MutationdeleteChildArgs = {
  input: DeleteChildInput;
};


export type MutationdeleteLocationArgs = {
  input: DeleteLocationInput;
};


export type MutationfollowUserArgs = {
  input: FollowUserInput;
};


export type MutationjoinRemindListArgs = {
  input: JoinRemindListInput;
};


export type MutationjoinWaitingListArgs = {
  input: JoinWaitlistInput;
};


export type MutationleaveRemindListArgs = {
  input: LeaveRemindListInput;
};


export type MutationleaveWaitingListArgs = {
  input: LeaveWaitlistInput;
};


export type MutationunfollowUserArgs = {
  input: UnfollowUserInput;
};


export type MutationupdateAdminArgs = {
  input: UpdateAdminInput;
};


export type MutationupdateChildArgs = {
  input: ChildInput;
};


export type MutationupdateDisplayRemindMeModalArgs = {
  input?: Maybe<UpdateDisplayRemindMeModalInput>;
};


export type MutationupdateDisplayWaitlistModalArgs = {
  input?: Maybe<UpdateDisplayWaitlistModalInput>;
};


export type MutationupdateHubFiltersArgs = {
  input: UpdateHubFiltersInput;
};


export type MutationupdateLocationArgs = {
  input: UpdateLocationInput;
};


export type MutationupdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationupdateUserDropdownFilterArgs = {
  input?: Maybe<UpdateUserDropdownFilterInput>;
};


export type MutationupdateUserNotificationCategoryArgs = {
  input: UpdateUserNotificationCategoryInput;
};


export type MutationupdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};


export type MutationupdateUserPhotoArgs = {
  input: UpdateUserPhotoInput;
};


export type MutationupdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationuserDeleteArgs = {
  input: UserDeleteInput;
};


export type MutationuserUpdateArgs = {
  input: UserInput;
  isYouthMultiStep?: Maybe<Scalars['Boolean']>;
};


export type MutationuserUpdatePrivacyArgs = {
  input: UserInput;
};

/** Any object implementing this type can be found by ID through `Query.node`. */
export type Node = {
  /** Global identifier that can be used to resolve any Node implementation. */
  id: Scalars['ID'];
};

export type NotificationCategory = {
  __typename?: 'NotificationCategory';
  /** The key of the model in the database. */
  id: Scalars['ID'];
  /** The name of the notification. */
  name: NotificationCategoryName;
  /** The pivot values for the notification category. */
  notificationValue: NotificationValue;
};

export type NotificationCategoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  notificationValue: NotificationValueInput;
};

/** Notification category name */
export enum NotificationCategoryName {
  /** Admin added you to the game */
  ADMIN_ADDED_YOU_TO_THE_GAME = 'ADMIN_ADDED_YOU_TO_THE_GAME',
  /** Admin removed you from game */
  ADMIN_REMOVED_YOU_FROM_GAME = 'ADMIN_REMOVED_YOU_FROM_GAME',
  /** Cancelled game */
  CANCELLED_GAME = 'CANCELLED_GAME',
  /** Favorite player joined your game */
  FAVORITE_PLAYER_JOINED_YOUR_GAME = 'FAVORITE_PLAYER_JOINED_YOUR_GAME',
  /** Friend add you to a game */
  FRIEND_ADD_YOU_TO_A_GAME = 'FRIEND_ADD_YOU_TO_A_GAME',
  /** Game comment */
  GAME_COMMENT = 'GAME_COMMENT',
  /** Game organizer emails */
  GAME_ORGANIZER_EMAILS = 'GAME_ORGANIZER_EMAILS',
  /** Game reminder */
  GAME_REMINDER = 'GAME_REMINDER',
  /** New fan */
  NEW_FAN = 'NEW_FAN',
  /** Pickuphub announcements */
  PICKUPHUB_ANNOUNCEMENTS = 'PICKUPHUB_ANNOUNCEMENTS',
  /** Upcoming game */
  UPCOMING_GAME = 'UPCOMING_GAME',
  /** Waiting list */
  WAITING_LIST = 'WAITING_LIST'
}

export type NotificationValue = {
  __typename?: 'NotificationValue';
  /** Enable the notification for send via Email. */
  isEmail: Scalars['Boolean'];
  /** Enable the notification for send via SMS. */
  isSms: Scalars['Boolean'];
};

export type NotificationValueInput = {
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  COUNT = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  AVG = 'AVG',
  /** Amount of items. */
  COUNT = 'COUNT',
  /** Maximum. */
  MAX = 'MAX',
  /** Minimum. */
  MIN = 'MIN',
  /** Sum. */
  SUM = 'SUM'
}

/** Possible directions in which to order a list of items when provided an orderBy argument. */
export enum OrderDirection {
  /** Specifies an ascending order for a given orderBy argument. */
  ASC = 'ASC',
  /** Specifies a descending order for a given orderBy argument. */
  DESC = 'DESC'
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type PhotoDimensionsInput = {
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

/** Represents a Position. */
export type Position = {
  __typename?: 'Position';
  /** Globally unique identifier. */
  id: Scalars['ID'];
  /** The name of the position. */
  name: Scalars['String'];
  /** The short name of the position. */
  shortName: Scalars['String'];
  /** The sort order of the position. */
  sortOrder: Scalars['Int'];
};

/** Position type */
export enum PositionType {
  /** Goalie */
  GOALIE = 'GOALIE',
  /** Player */
  PLAYER = 'PLAYER'
}

export type Provider = Actor & Deletable & Node & UniformResourceLocatable & {
  __typename?: 'Provider';
  /** The provider's address. */
  address?: Maybe<Scalars['String']>;
  /** The provider's admins. */
  admins: ProviderConnection;
  /** Avatar image for the provider. */
  avatar?: Maybe<Image>;
  /** A URL pointing to the provider's public avatar. */
  avatarUrl?: Maybe<Scalars['URL']>;
  /** The city this provider belongs to. */
  city?: Maybe<City>;
  /**
   * The public-facing contact phone for the provider.
   * Customers will use this phone to communicate with the provider.
   */
  contactPhone?: Maybe<Scalars['String']>;
  /** Cover image for the provider. */
  cover?: Maybe<Image>;
  /** A URL pointing to the provider's public cover. */
  coverUrl?: Maybe<Scalars['URL']>;
  /** The description of the provider. */
  description?: Maybe<Scalars['String']>;
  /** The provider's youth description. */
  descriptionYouth?: Maybe<Scalars['String']>;
  /** The provider's email. */
  email: Scalars['String'];
  /** A list of games of the provider. */
  games: GameConnection;
  id: Scalars['ID'];
  /** The images associated with the provider. */
  images: ImageConnection;
  /** provider viewer */
  isViewer: Scalars['Boolean'];
  /** The provider's name. */
  name: Scalars['String'];
  /** Retrieve the pages of provider */
  pages: ProviderPageConnection;
  /**
   * The provider phone.
   * PickupHub will use this phone to communicate with the provider.
   */
  phone?: Maybe<Scalars['String']>;
  /**
   * Retrieve the photos associated to a provider.
   * @deprecated Use Provider.images instead.
   */
  photos: AttachmentConnection;
  /** The provider's postal code. */
  postalCode?: Maybe<Scalars['String']>;
  /** The provider public email */
  publicEmail?: Maybe<Scalars['String']>;
  /** The HTML path to this resource. */
  resourcePath: Scalars['URL'];
  /** The current revenue from this month */
  revenueCurrentMonth?: Maybe<Scalars['Float']>;
  /** The revenue from last month */
  revenueLastMonth?: Maybe<Scalars['Float']>;
  /** The slug corresponding to the provider. */
  slug: Scalars['String'];
  /** The URL to this resource. */
  url: Scalars['URL'];
  /** Check if the current viewer can delete this object. */
  viewerCanDelete: Scalars['Boolean'];
};


export type ProvideradminsArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};


export type ProviderdescriptionArgs = {
  format?: Maybe<ProviderDescriptionFormat>;
};


export type ProviderdescriptionYouthArgs = {
  format?: Maybe<ProviderDescriptionFormat>;
};


export type ProvidergamesArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
  orderBy?: Maybe<GameOrder>;
  sportSlug?: Maybe<Scalars['String']>;
  times?: Maybe<Array<GameTimeFilter>>;
  youth?: Maybe<Scalars['Boolean']>;
};


export type ProviderimagesArgs = {
  after?: Maybe<Scalars['String']>;
  collection?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};


export type ProviderpagesArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};


export type ProviderphotosArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};

/** A paginated list of Provider edges. */
export type ProviderConnection = {
  __typename?: 'ProviderConnection';
  /** A list of Provider edges. */
  edges: Array<ProviderEdge>;
  /** Pagination information about the list of edges. */
  pageInfo: PageInfo;
};

/** Provider description format */
export enum ProviderDescriptionFormat {
  /** Html */
  HTML = 'HTML',
  /** Text */
  TEXT = 'TEXT'
}

/** An edge that contains a node of type Provider and a cursor. */
export type ProviderEdge = {
  __typename?: 'ProviderEdge';
  /** A unique cursor that can be used for pagination. */
  cursor: Scalars['String'];
  /** The Provider node. */
  node: Provider;
};

export type ProviderPage = {
  __typename?: 'ProviderPage';
  /** The description of the provider page. */
  description: Scalars['String'];
  /** @deprecated Use ProviderPage.description instead. */
  descriptionHTML: Scalars['String'];
  /** Globally unique identifier. */
  id: Scalars['ID'];
  slug: Scalars['String'];
  tabTitle: Scalars['String'];
  title: Scalars['String'];
};


export type ProviderPagedescriptionArgs = {
  format?: Maybe<ProviderPageDescriptionFormat>;
};

/** A paginated list of ProviderPage edges. */
export type ProviderPageConnection = {
  __typename?: 'ProviderPageConnection';
  /** A list of ProviderPage edges. */
  edges: Array<ProviderPageEdge>;
  /** Pagination information about the list of edges. */
  pageInfo: PageInfo;
};

/** Provider page description format */
export enum ProviderPageDescriptionFormat {
  /** Html */
  HTML = 'HTML',
  /** Text */
  TEXT = 'TEXT'
}

/** An edge that contains a node of type ProviderPage and a cursor. */
export type ProviderPageEdge = {
  __typename?: 'ProviderPageEdge';
  /** A unique cursor that can be used for pagination. */
  cursor: Scalars['String'];
  /** The ProviderPage node. */
  node: ProviderPage;
};

export type Province = {
  __typename?: 'Province';
  /** Globally unique identifier. */
  id: Scalars['ID'];
  /** The name of the province. */
  name: Scalars['String'];
  /** The short name of the province. */
  shortName: Scalars['String'];
};

export type ProvinceKeys = {
  __typename?: 'ProvinceKeys';
  firstRender?: Maybe<Scalars['Boolean']>;
  provinceKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** City pagination type. */
export type Query = {
  __typename?: 'Query';
  /** The provider's admin activities */
  adminActivities: AdminActivityConnection;
  /** all cities query */
  allCities: Array<City>;
  /** Query for all facilities */
  allFacilities: Array<Facility>;
  /** all locations query */
  allLocations: Array<Location>;
  /** all surfaces query */
  allSurfaces: Array<Surface>;
  /** Returns a child resource by ID. */
  child?: Maybe<User>;
  /** cities selected query */
  citiesByProvider: Array<City>;
  dropdownFilters?: Maybe<DropdownKeys>;
  /** The user's games feed. */
  hubFeed: GameConnection;
  hubFilters: Filters;
  /** query for retrieve a location */
  location?: Maybe<Location>;
  /** paginated locations query */
  locations: LocationConnection;
  node?: Maybe<Node>;
  /** Retrieve paginate users from database */
  paginatedUsers: UserPaginator;
  /** Provider query */
  provider?: Maybe<Provider>;
  providerPage?: Maybe<ProviderPage>;
  /** Paginate Providers query */
  providers: ProviderConnection;
  /** Perform a search across resources. */
  search: Array<SearchResultItem>;
  /** List of sports. */
  sports: Array<Sport>;
  /** Returns a user resource by ID. */
  user?: Maybe<User>;
  viewer?: Maybe<User>;
  viewerProvider?: Maybe<Provider>;
};


/** City pagination type. */
export type QueryadminActivitiesArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<AdminActivityOrder>>;
  searchValue?: Maybe<Scalars['String']>;
};


/** City pagination type. */
export type QueryallCitiesArgs = {
  limit?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};


/** City pagination type. */
export type QuerychildArgs = {
  id: Scalars['ID'];
};


/** City pagination type. */
export type QuerydropdownFiltersArgs = {
  userId: Scalars['ID'];
};


/** City pagination type. */
export type QueryhubFeedArgs = {
  after?: Maybe<Scalars['String']>;
  filters: UserFeedFilters;
  first: Scalars['Int'];
  userId: Scalars['ID'];
};


/** City pagination type. */
export type QueryhubFiltersArgs = {
  userId: Scalars['ID'];
};


/** City pagination type. */
export type QuerylocationArgs = {
  locationId: Scalars['ID'];
};


/** City pagination type. */
export type QuerylocationsArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
  orderBy?: Maybe<Array<QueryLocationsOrderByOrderByClause>>;
  searchValue?: Maybe<Scalars['String']>;
};


/** City pagination type. */
export type QuerynodeArgs = {
  id: Scalars['ID'];
};


/** City pagination type. */
export type QuerypaginatedUsersArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  searchQuery?: Maybe<Scalars['String']>;
  withoutChildren?: Maybe<Scalars['Boolean']>;
};


/** City pagination type. */
export type QueryproviderArgs = {
  slug: Scalars['String'];
};


/** City pagination type. */
export type QueryproviderPageArgs = {
  slug: Scalars['String'];
};


/** City pagination type. */
export type QueryprovidersArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** City pagination type. */
export type QuerysearchArgs = {
  query: Scalars['String'];
  types?: Maybe<Array<SearchResultType>>;
};


/** City pagination type. */
export type QueryuserArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

/** Order by clause for Query.locations.orderBy. */
export type QueryLocationsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: LocationOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** The results of a search. */
export type SearchResultItem = Provider | User;

/** Search result type */
export enum SearchResultType {
  /** Provider */
  PROVIDER = 'PROVIDER',
  /** User */
  USER = 'USER'
}

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  ASC = 'ASC',
  /** Sort records in descending order. */
  DESC = 'DESC'
}

/** Represents a Sport. */
export type Sport = UniformResourceLocatable & {
  __typename?: 'Sport';
  /** A list of positions associated with the sport. */
  fields: Array<FieldSection>;
  /** A list of games associated with the sport. */
  games: GameConnection;
  /** The number of games included in the sport. */
  gamesCount: Scalars['Int'];
  /** Globally unique identifier. */
  id: Scalars['ID'];
  /** The name of the sport. */
  name: Scalars['String'];
  /** A list of positions associated with the sport. */
  positions: Array<Position>;
  /** The HTML path to this resource. */
  resourcePath: Scalars['URL'];
  /** The slug corresponding to the sport. */
  slug: Scalars['String'];
  /** The URL to this resource. */
  url: Scalars['URL'];
};


/** Represents a Sport. */
export type SportgamesArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
  youth?: Maybe<Scalars['Boolean']>;
};

export type StringWrapper = {
  __typename?: 'StringWrapper';
  stringValue?: Maybe<Scalars['String']>;
};

export type Surface = {
  __typename?: 'Surface';
  /** Globally unique identifier. */
  id: Scalars['ID'];
  /** Name of surface */
  name?: Maybe<Scalars['String']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  ONLY = 'ONLY',
  /** Return both trashed and non-trashed results. */
  WITH = 'WITH',
  /** Only return non-trashed results. */
  WITHOUT = 'WITHOUT'
}

export type UnfollowUserInput = {
  /** ID of the user to unfollow. */
  userId: Scalars['ID'];
};

export type UnfollowUserPayload = {
  __typename?: 'UnfollowUserPayload';
  /** The user that was unfollowed. */
  user?: Maybe<User>;
};

/** Represents a type that can be retrieved by a URL. */
export type UniformResourceLocatable = {
  /** The HTML path to this resource. */
  resourcePath: Scalars['URL'];
  /** The URL to this resource. */
  url: Scalars['URL'];
};

/** Entities that can be updated. */
export type Updatable = {
  /** Check if the current viewer can update this object. */
  viewerCanUpdate: Scalars['Boolean'];
};

export type UpdateAdminInput = {
  adminId: Scalars['ID'];
  confirmPassword?: Maybe<Scalars['String']>;
  email: Scalars['Email'];
  password?: Maybe<Scalars['String']>;
};

export type UpdateChildPayload = {
  __typename?: 'UpdateChildPayload';
  /** The updated child. */
  child?: Maybe<User>;
};

export type UpdateCommentHiddenStatusInput = {
  /** The comment id. */
  id: Scalars['ID'];
  /** The hidden status of the object. */
  isHidden: Scalars['Boolean'];
};

export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  comment: Comment;
};

export type UpdateDisplayRemindMeModalInput = {
  /** If the user wants the modal to display again */
  hideRemindModal: Scalars['Boolean'];
};

export type UpdateDisplayRemindMeModalPayload = {
  __typename?: 'UpdateDisplayRemindMeModalPayload';
  /** The user that updated if they want to show the modal */
  user?: Maybe<User>;
};

export type UpdateDisplayWaitlistModalInput = {
  /** If the user wants the modal to display again */
  hideWaitlistModal: Scalars['Boolean'];
};

export type UpdateDisplayWaitlistModalPayload = {
  __typename?: 'UpdateDisplayWaitlistModalPayload';
  /** The user that updated if they want to show the modal */
  user?: Maybe<User>;
};

export type UpdateHubFiltersInput = {
  cityIds: Array<Scalars['ID']>;
  sportIds: Array<Scalars['ID']>;
  timeIds: Array<GameTimeFilter>;
  userId: Scalars['ID'];
};

export type UpdateLocationInput = {
  /** the address of location. */
  address?: Maybe<Scalars['String']>;
  /** the city of location belongs. */
  cityId: Scalars['ID'];
  /** the facility of the location. */
  facilityId?: Maybe<Scalars['ID']>;
  /** location has a change room. */
  hasChangeRoom: Scalars['Boolean'];
  /** location has a outdoor light. */
  hasOutdoorLight: Scalars['Boolean'];
  /** location has a shower. */
  hasShower: Scalars['Boolean'];
  /** location has a wash room. */
  hasWashRoom: Scalars['Boolean'];
  /** location is outdoor. */
  isOutdoor: Scalars['Boolean'];
  /** Latitude of marker in map. */
  latitude: Scalars['Float'];
  /** ID of location. */
  locationId: Scalars['ID'];
  /** Longitude of marker in map. */
  longitude: Scalars['Float'];
  /** name of location. */
  name: Scalars['String'];
  /** type of surface of location. */
  surfaceId: Scalars['ID'];
};

export type UpdateUserDropdownFilterInput = {
  /** User save the dropdowns menu */
  provinceKey?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateUserDropdownFilterPayload = {
  __typename?: 'UpdateUserDropdownFilterPayload';
  /** The user that updated the filter dropdown */
  user?: Maybe<User>;
};

export type UpdateUserInput = {
  /** The user's birthDay to update. */
  birthDate?: Maybe<Scalars['Date']>;
  /** The user's cellphone to update. */
  cellPhone?: Maybe<Scalars['String']>;
  /** The email to update. */
  email?: Maybe<Scalars['String']>;
  /** The first name to update. */
  firstName?: Maybe<Scalars['String']>;
  /** The last name to update. */
  lastName?: Maybe<Scalars['String']>;
  /** The user's postal code to update. */
  postalCode?: Maybe<Scalars['String']>;
  /** The favorite sports of the user. */
  sportIds?: Maybe<Array<Scalars['ID']>>;
  /** The time availability of the user for games. */
  timeAvailabilities?: Maybe<Array<UserTimeAvailabilityInput>>;
  /** The id of the user to update. */
  userId: Scalars['ID'];
};

export type UpdateUserNotificationCategoryInput = {
  notifications: Array<NotificationCategoryInput>;
  userId: Scalars['ID'];
};

export type UpdateUserPasswordInput = {
  /** The new password for user. */
  password: Scalars['String'];
  /** The password confirmation that must be the same. */
  passwordConfirmation: Scalars['String'];
  /** The id for the user to update. */
  userId: Scalars['ID'];
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** The updated user. */
  user?: Maybe<User>;
};

export type UpdateUserPhotoInput = {
  dimensions: PhotoDimensionsInput;
  photo: Scalars['Upload'];
  userId: Scalars['ID'];
};

export type UpdateUserProfileInput = {
  /** The birth date of the user. */
  birthDate?: Maybe<Scalars['DateTime']>;
  /** Whether the user wants to show his/her birth date on the public profile. */
  birthDayPrivacy?: Maybe<Scalars['Boolean']>;
  /** The pickuphub credits of the user. */
  credits?: Maybe<Scalars['Float']>;
  /** Whether the user wants to show his/her follower button on the public profile. */
  followerPrivacy?: Maybe<Scalars['Boolean']>;
  /** The gender of the user. */
  gender?: Maybe<Gender>;
  /**
   * Whether the user wants to show his/her
   * last name on the public profile and profile URL.
   */
  lastNamePrivacy?: Maybe<Scalars['Boolean']>;
  /** The id of the user profile. */
  userId: Scalars['ID'];
};

export type UpdateUserProfilePayload = {
  __typename?: 'UpdateUserProfilePayload';
  /** Type user gql */
  user?: Maybe<User>;
};

/** A user is an individual's account on PickupHub. */
export type User = Actor & Node & UniformResourceLocatable & {
  __typename?: 'User';
  /** Avatar image for the user. */
  avatar?: Maybe<Image>;
  /** A URL pointing to the user's public avatar. */
  avatarUrl?: Maybe<Scalars['URL']>;
  /** Whether the user wants to show his/her birth date on the public profile. */
  birthDatePrivacy?: Maybe<Scalars['Boolean']>;
  /**
   * The birth day privacy option.
   * @deprecated Use `birthDatePrivacy` instead.
   */
  birthDayPrivacy: Scalars['Boolean'];
  /**
   * The cell phone number of the user
   * @deprecated Use `phone` instead.
   */
  cellPhone?: Maybe<Scalars['String']>;
  /** Viewer children. */
  children: UserConnection;
  /** Viewer children games. */
  childrenGames: GameConnection;
  /** The city of the user. */
  city?: Maybe<City>;
  /**
   * Color for user.
   * @deprecated Use UserProfile.color
   */
  color: Scalars['String'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  /** User credits by providers */
  credits?: Maybe<Array<Credit>>;
  /** The full name of the user, based on the values for firstName and lastName. */
  displayName: Scalars['String'];
  /** The user's email. */
  email?: Maybe<Scalars['String']>;
  /** The user's first name. */
  firstName: Scalars['String'];
  /** The follower privacy option */
  followerPrivacy?: Maybe<Scalars['Boolean']>;
  /** A list of users the given user is followed by. */
  followers: UserConnection;
  /** A list of users the given user is following. */
  following: UserConnection;
  /** A list of games in which the user is registered. */
  games: GameConnection;
  /** Whether or not this user has been a hockey goalie before */
  hasBeenGoalie: Scalars['Boolean'];
  /** If the user wants for the remind modal to show */
  hideRemindModal: Scalars['Boolean'];
  /** If the user wants for the waiting list modal to show */
  hideWaitlistModal: Scalars['Boolean'];
  id: Scalars['ID'];
  /** If the user can impersonate other users */
  impersonateUsers: Scalars['Boolean'];
  /** Whether or not this user is the viewing user. */
  isViewer: Scalars['Boolean'];
  /** The user's last name. */
  lastName: Scalars['String'];
  /** The user's preferred Last Name on public profile */
  lastNamePrivacy: Scalars['Boolean'];
  /**
   * The user's full name.
   * @deprecated Use `displayName` instead
   */
  name: Scalars['String'];
  /** The notification that the user will be able to enable or disable. */
  notifications: Array<NotificationCategory>;
  /** The parent of the user if this is a child. */
  parent?: Maybe<User>;
  /** The phone number of the user. */
  phone?: Maybe<Scalars['String']>;
  /** The postal code of the user. */
  postalCode?: Maybe<Scalars['String']>;
  /** The profile of the user. */
  profile?: Maybe<UserProfile>;
  /** User province key */
  provinceKey?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The HTML path to this resource. */
  resourcePath: Scalars['URL'];
  /** The last 4 digits of the saved card of the user */
  savedCard?: Maybe<Scalars['String']>;
  /** The settings of the user. */
  settings: UserSettings;
  /** The favorite sports of the user. */
  sports: Array<Sport>;
  /** The user's stats. */
  stats: Array<UserSportStat>;
  /** The time availability of the user for games. */
  timeAvailabilities: Array<UserTimeAvailability>;
  /** The URL to this resource. */
  url: Scalars['URL'];
  /** Whether or not the viewer is able to follow the user. */
  viewerCanFollow: Scalars['Boolean'];
  /** Whether or not this user is followed by the viewer. */
  viewerIsFollowing: Scalars['Boolean'];
  /** Is the current viewer a manager of the site? */
  viewerIsManager: Scalars['Boolean'];
};


/** A user is an individual's account on PickupHub. */
export type UserchildrenArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** A user is an individual's account on PickupHub. */
export type UserchildrenGamesArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** A user is an individual's account on PickupHub. */
export type UserfollowersArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** A user is an individual's account on PickupHub. */
export type UserfollowingArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** A user is an individual's account on PickupHub. */
export type UsergamesArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
  orderBy?: Maybe<GameOrder>;
  times?: Maybe<Array<GameTimeFilter>>;
  trashed?: Maybe<Trashed>;
};

/** A paginated list of User edges. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of User edges. */
  edges: Array<UserEdge>;
  /** Pagination information about the list of edges. */
  pageInfo: PageInfo;
};

/** Deletes a user. */
export type UserDeleteInput = {
  /** The ID of the user to be deleted. */
  id: Scalars['ID'];
};

export type UserDeletePayload = {
  __typename?: 'UserDeletePayload';
  /** The ID of the deleted user. */
  deletedUserId?: Maybe<Scalars['ID']>;
  /** List of errors that occurred executing the mutation. */
  userErrors: Array<UserError>;
};

export type UserDetails = {
  __typename?: 'UserDetails';
  /** Player Position */
  position: Scalars['String'];
  /** Player Team */
  team: Scalars['String'];
};

/** An edge that contains a node of type User and a cursor. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A unique cursor that can be used for pagination. */
  cursor: Scalars['String'];
  /** The User node. */
  node: User;
};

/** Represents an error in the input of a mutation. */
export type UserError = DisplayableError & {
  __typename?: 'UserError';
  /** Path to the input field which caused the error. */
  field?: Maybe<Array<Scalars['String']>>;
  /** The error message. */
  message: Scalars['String'];
};

export type UserFeedFilters = {
  cityIds?: Maybe<Array<Scalars['ID']>>;
  sportIds?: Maybe<Array<Scalars['ID']>>;
  timeIds?: Maybe<Array<GameTimeFilter>>;
};

/** Specifies the input fields required to create or update a user. */
export type UserInput = {
  /** The birth day of the user. */
  birthDate?: Maybe<Scalars['Date']>;
  /** Whether the user wants to show his/her birth date on the public profile. */
  birthDatePrivacy?: Maybe<Scalars['Boolean']>;
  /** The unique email address of the user. */
  email?: Maybe<Scalars['String']>;
  /** The favorite sports of the user. */
  favoriteSports?: Maybe<Array<Scalars['ID']>>;
  /** The first name of the user. */
  firstName?: Maybe<Scalars['String']>;
  /** Whether the user wants to show his/her follower button on the public profile. */
  followerPrivacy?: Maybe<Scalars['Boolean']>;
  /** The gender of the user. */
  gender?: Maybe<Gender>;
  /** Specifies the collection to update or create a new collection if absent. */
  id?: Maybe<Scalars['ID']>;
  /** The last name of the user. */
  lastName?: Maybe<Scalars['String']>;
  /**
   * Whether the user wants to show his/her
   * last name on the public profile and profile URL.
   */
  lastNamePrivacy?: Maybe<Scalars['Boolean']>;
  /** The unique phone number for the user. */
  phone?: Maybe<Scalars['String']>;
  /** The postal code of the user. */
  postalCode?: Maybe<Scalars['String']>;
  /** The time availability of the user for games. */
  timeAvailabilities?: Maybe<Array<UserTimeAvailabilityInput>>;
};

/** A paginated list of User items. */
export type UserPaginator = {
  __typename?: 'UserPaginator';
  /** A list of User items. */
  data: Array<User>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  /** The integer age of the user. */
  age?: Maybe<Scalars['Int']>;
  /** The birth date of the user. */
  birthDate?: Maybe<Scalars['Date']>;
  /** birthdate flag */
  birthdateFlag?: Maybe<Scalars['Boolean']>;
  /** The color of the user. */
  color: Scalars['String'];
  /** Pickuphub credits. */
  credits?: Maybe<Scalars['Float']>;
  /** The gender of the user. */
  gender?: Maybe<Gender>;
  /** Globally unique identifier. */
  id: Scalars['ID'];
};

export type UserSettings = {
  __typename?: 'UserSettings';
  /** Show availability in the missing information modal. */
  showAvailabilityInMissingInformationModal: Scalars['Boolean'];
  /** Show sports in the missing information modal. */
  showSportsInMissingInformationModal: Scalars['Boolean'];
};

/** Represents the stats of the user in a sport. */
export type UserSportStat = {
  __typename?: 'UserSportStat';
  /** The total of games of the user has played. */
  gamesPlayed: Scalars['Int'];
  /** The sport for this stat. */
  sport: Sport;
  /** The user who has this stat. */
  user: User;
};

export type UserTimeAvailability = {
  __typename?: 'UserTimeAvailability';
  day: DayOfTheWeek;
  dayPeriods: Array<DayPeriod>;
  /** Globally unique identifier. */
  id: Scalars['ID'];
};

export type UserTimeAvailabilityInput = {
  day: DayOfTheWeek;
  dayPeriods: Array<DayPeriod>;
};

export type UserUpdatePayload = {
  __typename?: 'UserUpdatePayload';
  /** The updated user. */
  user?: Maybe<User>;
  /** List of errors that occurred executing the mutation. */
  userErrors: Array<UserError>;
};

export type UserUpdatePrivacyPayload = {
  __typename?: 'UserUpdatePrivacyPayload';
  /** The updated user. */
  user?: Maybe<User>;
  /** List of errors that occurred executing the mutation. */
  userErrors: Array<UserError>;
};

export type ValueWrapper = BooleanWrapper | IntWrapper | StringWrapper;
