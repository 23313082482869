/**
 * @generated SignedSource<<3bf9cfa0dacb987d8e83235484c6329b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GameListItemCompact_game$data = {
  readonly url: string;
  readonly viewerIsAPlayer?: boolean;
  readonly location: {
    readonly " $fragmentSpreads": FragmentRefs<"LocationText_location">;
  };
  readonly sport: {
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"SportIcon_sport">;
  };
  readonly template: {
    readonly " $fragmentSpreads": FragmentRefs<"Cost_template">;
  };
  readonly isCancelled: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"CalendarIcon_game" | "DateTimeText_game" | "ProgressBar_game" | "ShirtIcon_game" | "SportAndAgesText_game" | "Title_game">;
  readonly " $fragmentType": "GameListItemCompact_game";
};
export type GameListItemCompact_game$key = {
  readonly " $data"?: GameListItemCompact_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"GameListItemCompact_game">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "youth"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GameListItemCompact_game",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CalendarIcon_game"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DateTimeText_game"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProgressBar_game"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShirtIcon_game"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SportAndAgesText_game"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Title_game"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "condition": "youth",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerIsAPlayer",
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LocationText_location"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sport",
      "kind": "LinkedField",
      "name": "sport",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SportIcon_sport"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GameTemplate",
      "kind": "LinkedField",
      "name": "template",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Cost_template"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCancelled",
      "storageKey": null
    }
  ],
  "type": "Game",
  "abstractKey": null
};

(node as any).hash = "960028a5f7b1efb7d7bb6edc2f114a20";

export default node;
