import React from 'react'
import ContentLoader from 'react-content-loader'

export default function FormLoader() {
  return (
    <ContentLoader
      speed={2}
      width={528}
      height={820}
      viewBox="0 0 528 820"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <circle cx="19" cy="269" r="11" />
      <rect x="6" y="8" rx="3" ry="3" width="244" height="40" />
      <rect x="2" y="177" rx="3" ry="3" width="528" height="40" />
      <circle cx="121" cy="267" r="11" />
      <circle cx="472" cy="268" r="11" />
      <circle cx="305" cy="269" r="11" />
      <circle cx="214" cy="269" r="11" />
      <rect x="282" y="93" rx="3" ry="3" width="244" height="40" />
      <rect x="4" y="93" rx="3" ry="3" width="244" height="40" />
      <rect x="279" y="8" rx="3" ry="3" width="244" height="40" />
      <circle cx="391" cy="268" r="11" />
      <rect x="6" y="304" rx="0" ry="0" width="589" height="543" />
    </ContentLoader>
  )
}
