import 'vite/modulepreload-polyfill'

import '@reach/dialog/styles.css'
import '@reach/tooltip/styles.css'

import ReactDOM from 'react-dom'
import {Suspense} from 'react'

import {ChakraProvider, CSSReset} from '@chakra-ui/react'

import 'utils/icons'

import App from 'App'
import Routes from 'routes'
import {theme} from 'chakra'
import GameCommentsContainer from 'components/GameCommentsContainer'
import {Header} from 'pages/site/components/Header'
import GameFollowProfile from './components/GameFollowProfile'

import {ProviderPluginGamesWrapper} from 'components/ProviderPluginGames/ProviderPluginGames'
import * as Sentry from "@sentry/react";

import {Loading} from 'components/loading'
import {SelectCities} from './components/SelectCities'

window.__isTouch = !matchMedia(`(pointer:fine)`).matches

try {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.2,
    tracesSampleRate: 1,
  })

  if (window.env.user) {
    Sentry.setUser({
      email: window.env.user.email,
      id: window.env.user.id,
    });
  }
} catch (error) {
  console.error(error)
}

const rootNode = document.getElementById(`react-app-root`)
const rootNodeStyles = document.getElementById(`react-app-root-styles`)

const gameCommentsNode = document.getElementById(`react-app-game-comments`)
const siteHeaderNode = document.getElementById(`react-app-site-header`)
const adminManageSettings = document.getElementById(`react-app-select-new`)
const gameFollowProfileNode = document.querySelectorAll(
  '.react-app-game-follow'
)
const providerPluginGames = document.getElementById(
  `react-app-provider-plugin-games`
)

if (rootNodeStyles) {
  // @ts-expect-error
  ReactDOM.createRoot(rootNodeStyles).render(
    <ChakraProvider theme={theme}>
      <CSSReset />
    </ChakraProvider>
  )
}

if (siteHeaderNode) {
  // @ts-expect-error
  ReactDOM.createRoot(siteHeaderNode).render(
    <App>
      <Header />
    </App>
  )
}

if (adminManageSettings) {
  ReactDOM.render(
    <App>
      <Suspense fallback={<Loading />}>
        <SelectCities />
      </Suspense>
    </App>,
    adminManageSettings
  )
}

if (rootNode) {
  // @ts-expect-error
  ReactDOM.createRoot(rootNode).render(
    <App resetCSS>
      <Routes />
    </App>
  )
}

if (gameCommentsNode) {
  const viewer = window.env.user ? window.env.user : window.env.admin
  const canHideComments = !!window.env.admin
  const {gameId} = gameCommentsNode.dataset

  ReactDOM.render(
    <App>
      <Suspense fallback={<Loading />}>
        <GameCommentsContainer
          gameId={gameId}
          viewer={{
            id: viewer.id,
            name: viewer.fullname,
            avatarUrl: viewer.avatarUrl,
            canHideComments,
          }}
        />
      </Suspense>
    </App>,
    gameCommentsNode
  )
}

if (providerPluginGames) {
  ReactDOM.render(
    <App>
      <ProviderPluginGamesWrapper />
    </App>,
    providerPluginGames
  )
}

if (gameFollowProfileNode) {
  const viewer = window.env.user ? window.env.user : window.env.admin
  if (viewer) {
    gameFollowProfileNode.forEach((profile) => {
      // @ts-ignore
      ReactDOM.render(
        <App>
          <Suspense fallback={<Loading />}>
            <GameFollowProfile
              viewer={{
                id: viewer.id,
                profile: profile.id,
              }}
            />
          </Suspense>
        </App>,
        profile
      )
    })
  }
}
