/**
 * @generated SignedSource<<ba6ca5ce24f3bc9defb7514606b09fac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBoxResultListItem_item$data = {
  readonly __typename: "Provider";
  readonly name: string;
  readonly city: {
    readonly name: string;
  } | null;
  readonly games: {
    readonly pageInfo: {
      readonly total: number;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_actor">;
  readonly " $fragmentType": "SearchBoxResultListItem_item";
} | {
  readonly __typename: "User";
  readonly displayName: string;
  readonly city: {
    readonly name: string;
  } | null;
  readonly games: {
    readonly pageInfo: {
      readonly total: number;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_actor">;
  readonly " $fragmentType": "SearchBoxResultListItem_item";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "SearchBoxResultListItem_item";
};
export type SearchBoxResultListItem_item$key = {
  readonly " $data"?: SearchBoxResultListItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBoxResultListItem_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "args": [
    {
      "kind": "Literal",
      "name": "size",
      "value": 48
    }
  ],
  "kind": "FragmentSpread",
  "name": "Avatar_actor"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "City",
  "kind": "LinkedField",
  "name": "city",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    },
    {
      "kind": "Literal",
      "name": "times",
      "value": [
        "UPCOMING"
      ]
    }
  ],
  "concreteType": "GameConnection",
  "kind": "LinkedField",
  "name": "games",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "games(first:1,times:[\"UPCOMING\"])"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchBoxResultListItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "type": "Provider",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "type": "User",
      "abstractKey": null
    }
  ],
  "type": "SearchResultItem",
  "abstractKey": "__isSearchResultItem"
};
})();

(node as any).hash = "1e40031f503686e9618a511d221b9b00";

export default node;
