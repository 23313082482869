/* eslint relay/must-colocate-fragment-spreads: off */
import React, {useEffect, useState} from 'react'
import {
  fetchQuery,
  graphql,
  useFragment,
  useRelayEnvironment,
} from 'react-relay'
import {
  Box,
  Flex,
  Grid,
  Link,
  Show,
  Spacer,
  Stack,
  useBreakpoint,
} from '@chakra-ui/react'

import {useSiteEnvironmentContext} from 'hooks'

import {GameListItem_game$key} from './__generated__/GameListItem_game.graphql'
import {GameListItemCompact_game$key} from './__generated__/GameListItemCompact_game.graphql'

import {
  CalendarIcon,
  Cost,
  DateTimeText,
  DateTimeTextWaitlist,
  GoalieIcon,
  LocationText,
  LocationTextWaitlist,
  ProgressBar,
  ShirtIcon,
  SportAndAgesText,
  SportIcon,
  Title,
  TitleWaitlist,
} from './components'
import {GameListItemWaitlist_game$key} from './__generated__/GameListItemWaitlist_game.graphql'
import {WaitlistButton} from './components/WaitlistButton'
import pusher from 'utils/Pusher'
import {GameListItemLocked_game$key} from './__generated__/GameListItemLocked_game.graphql'
import {RemindButton} from './components/RemindButton'
import {UpcomingCountdown} from './components/UpcomingCountdown'

interface GameListItemProps {
  game: GameListItem_game$key
  changeToWaitlistItem?: () => void
}

export function GameListItem(props: GameListItemProps) {
  const {IS_RUNNING_IN_IFRAME} = useSiteEnvironmentContext()
  const compactInMobile = useBreakpoint()
  const game = useFragment(
    graphql`
      fragment GameListItem_game on Game
      @argumentDefinitions(youth: {type: "Boolean!", defaultValue: false}) {
        ...CalendarIcon_game
        ...DateTimeText_game
        ...ProgressBar_game
        ...ShirtIcon_game
        ...SportAndAgesText_game
        ...Title_game
        ...GoalieIcon_game
        url
        viewerIsAPlayer @skip(if: $youth)
        location {
          ...LocationText_location
        }
        sport {
          ...SportIcon_sport
          slug
        }
        template {
          ...Cost_template
        }
      }
    `,
    props.game
  )

  return (
    <Box
      bg="white"
      borderRadius="sm"
      boxShadow="sm"
      overflow="hidden"
      position="relative"
    >
      <Stack
        alignItems="center"
        bg="gray.50"
        borderTopLeftRadius="sm"
        borderTopRightRadius="sm"
        direction="row"
        h="4.5rem"
        pr={2}
        spacing={{base: 2.5, md: 4}}
      >
        <SportIcon sport={game.sport} />
        <Show above="sm">
          <CalendarIcon game={game} />
        </Show>
        <Box overflow="hidden">
          <Box
            width={{base: '11rem', sm: '13rem', md: '25rem'}}
            sx={{margin: '0'}}
          >
            <Title game={game} />
          </Box>
          <SportAndAgesText game={game} />
        </Box>
        <Spacer />
        <Show above="md">
          {game.sport.slug === 'ball-hockey' ||
            (game.sport.slug === 'ice-hockey' && <GoalieIcon game={game} />)}
        </Show>
        {game.viewerIsAPlayer ? (
          <ShirtIcon game={game} />
        ) : (
          <Cost template={game.template} />
        )}
      </Stack>

      <Stack
        direction={{base: `column`, md: `row`}}
        justify={{md: `space-around`}}
        px={4}
        py={{base: 4, md: 6}}
        spacing={{base: 3, md: 0}}
      >
        <Stack direction="row" justify="space-between" align="center">
          <DateTimeText game={game} />
          <Show below="md">
            {game.sport.slug === 'ball-hockey' ||
              (game.sport.slug === 'ice-hockey' && <GoalieIcon game={game} />)}
          </Show>
        </Stack>
        <LocationText
          location={game.location}
          isHockey={game.sport.slug === 'ice-hockey'}
        />
      </Stack>

      <ProgressBar
        game={game}
        gameType={
          game.viewerIsAPlayer &&
          (compactInMobile === 'base' || compactInMobile === 'sm')
            ? 'compact'
            : 'full'
        }
        changeToWaitlistItem={props.changeToWaitlistItem}
      />

      <Link
        borderRadius="sm"
        bottom={0}
        href={game.url}
        left={0}
        position="absolute"
        right={0}
        target={IS_RUNNING_IN_IFRAME ? '_blank' : undefined}
        top={0}
        _focus={{boxShadow: `outline`}}
      />
    </Box>
  )
}

interface GameListItemCompactProps {
  game: GameListItemCompact_game$key
}

export function GameListItemCompact(props: GameListItemCompactProps) {
  const game = useFragment(
    graphql`
      fragment GameListItemCompact_game on Game
      @argumentDefinitions(youth: {type: "Boolean!", defaultValue: false}) {
        ...CalendarIcon_game
        ...DateTimeText_game
        ...ProgressBar_game
        ...ShirtIcon_game
        ...SportAndAgesText_game
        ...Title_game
        url
        viewerIsAPlayer @skip(if: $youth)
        location {
          ...LocationText_location
        }
        sport {
          ...SportIcon_sport
          slug
        }
        template {
          ...Cost_template
        }
        isCancelled
      }
    `,
    props.game
  )

  const isCancelled = game.isCancelled

  return (
    <Box
      borderRadius="sm"
      boxShadow="sm"
      position="relative"
      bg={game.isCancelled ? 'gray.200' : 'white'}
    >
      <Grid
        alignItems="center"
        columnGap="1rem"
        pr={4}
        templateAreas={{
          base: `
          "sport-icon calendar-icon title-sport-and-ages cost-or-shirt-icon"
          "datetime datetime datetime datetime"
          `,
          md: `"sport-icon calendar-icon title-sport-and-ages datetime cost-or-shirt-icon"`,
        }}
        templateColumns={{
          base: `4.5rem 48px 1fr 4rem`,
          md: `4.5rem 48px auto auto 4rem`,
        }}
      >
        <Box gridArea="sport-icon">
          <SportIcon sport={game.sport} isCancelled={game.isCancelled} />
        </Box>

        <Box gridArea="calendar-icon">
          <CalendarIcon game={game} isCancelled={game.isCancelled} />
        </Box>

        <Box gridArea="title-sport-and-ages" overflow="hidden">
          <Title game={game} isCancelled={isCancelled} />
          <SportAndAgesText game={game} />
        </Box>

        <Flex gridArea="cost-or-shirt-icon" justify="flex-end">
          {game.viewerIsAPlayer ? (
            !isCancelled && <ShirtIcon game={game} />
          ) : (
            <Cost template={game.template} />
          )}
        </Flex>

        <Stack
          gridArea="datetime"
          pl={{base: 4, md: 0}}
          py={{base: 4, md: 0}}
          spacing={3}
        >
          <DateTimeText game={game} isCancelled={isCancelled} />
          <LocationText
            location={game.location}
            isHockey={game.sport.slug === 'ice-hockey'}
            isCancelled={isCancelled}
          />
        </Stack>
      </Grid>

      <Box gridArea="progress-bar">
        {!game.isCancelled && <ProgressBar gameType="compact" game={game} />}
      </Box>

      <Link
        borderRadius="sm"
        bottom={0}
        href={game.url}
        left={0}
        position="absolute"
        right={0}
        top={0}
        _focus={{boxShadow: `outline`}}
      />
    </Box>
  )
}

interface GameListItemWaitlistProps {
  game: GameListItemWaitlist_game$key
  hasBeenGoalie: boolean
}

export function GameListItemWaitlist(props: GameListItemWaitlistProps) {
  const relayEnvironment = useRelayEnvironment()
  const {IS_YOUTH_DOMAIN} = useSiteEnvironmentContext()
  const breakpoint = useBreakpoint()
  const game = useFragment(
    graphql`
      fragment GameListItemWaitlist_game on Game {
        ...DateTimeTextWaitlist_game
        ...TitleWaitlist_game
        ...WaitlistButton_game
        ...ShirtIcon_game
        viewerIsAPlayer
        # eslint-disable-next-line relay/unused-fields
        __id
        # eslint-disable-next-line relay/unused-fields
        gameIsFull
        id
        url
        location {
          ...LocationTextWaitlist_location
        }
        numberOfTeams
        players(first: 1) {
          pageInfo {
            total
          }
        }
        sport {
          slug
          ...SportIcon_sport
        }
        template {
          ...Cost_template
          positions(first: 1) {
            pageInfo {
              total
            }
          }
        }
      }
    `,
    props.game
  )

  const [playersCount, setPlayersCount] = useState(game.players.pageInfo.total)
  const templatesCount = game.template.positions.pageInfo.total
  const progress = (playersCount / templatesCount) * (100 / game.numberOfTeams)

  useEffect(() => {
    const channelId = window.atob(game.id).replace('Game:', 'game-')
    const channel = pusher.subscribe(channelId)

    channel.bind(
      'App\\Events\\Positions\\CancelPosition',
      ({players_count}) => {
        setPlayersCount(players_count)
      }
    )
    channel.bind('App\\Events\\Positions\\PositionBuyed', ({players_count}) => {
      setPlayersCount(players_count)
    })

    return () => {
      channel.unbind('.App\\Events\\Positions\\CancelPosition')
      channel.unbind('.App\\Events\\Positions\\PositionBuyed')
      pusher.unsubscribe(channelId)
    }
  }, [game.id])

  useEffect(() => {
    if (progress < 100) {
      fetchQuery(
        relayEnvironment,
        graphql`
          query GameListItemGameQuery($gameId: ID!) {
            node(id: $gameId) {
              ...ProgressBar_game
            }
          }
        `,
        {
          gameId: game.id,
        },
        {
          networkCacheConfig: {
            force: true,
          },
        }
      ).toPromise()
    }
  }, [game.id, progress, relayEnvironment])

  return (
    <Box
      bg={IS_YOUTH_DOMAIN ? 'purple.300' : 'green.300'}
      borderRadius="sm"
      boxShadow="sm"
      overflow="hidden"
      position="relative"
    >
      <Flex>
        <Show above="md">
          <SportIcon isWaitlistItem={true} sport={game.sport} />
        </Show>
        <Flex flexGrow={1} paddingInlineStart={{base: '4', md: '0'}}>
          <Flex
            direction={{base: 'column', md: 'row'}}
            align={{base: 'start', md: 'center'}}
            gap={{base: 0, sm: '2', md: '4', lg: '8'}}
            fontSize={{base: '10px', md: '14px'}}
            paddingBlock="2"
          >
            <Box width="106px" marginTop="0" sx={{margin: '0'}}>
              <TitleWaitlist game={game} />
            </Box>
            <DateTimeTextWaitlist game={game} />
            <Box maxW="230px" marginTop="0" sx={{margin: '0'}}>
              <LocationTextWaitlist location={game.location} />
            </Box>
          </Flex>
          <Spacer />
          <Stack
            direction="row"
            align="center"
            gap={{base: '0', md: '2'}}
            paddingInlineEnd="4"
          >
            <Box
              color="whiteAlpha.900"
              fontWeight="semibold"
              textTransform="uppercase"
              fontSize={{base: '12px', md: '14px'}}
              textAlign="center"
            >
              Game Full
            </Box>
            {game.viewerIsAPlayer ? (
              <ShirtIcon game={game} />
            ) : (
              <WaitlistButton
                zIndex={1}
                game={game}
                showHockeyModal={
                  props.hasBeenGoalie && game.sport.slug === 'ice-hockey'
                }
              />
            )}
          </Stack>
        </Flex>
      </Flex>
      <Link
        borderRadius="sm"
        bottom={0}
        href={game.url}
        left={0}
        position="absolute"
        right={0}
        top={0}
        _focus={{boxShadow: `outline`}}
      />
    </Box>
  )
}

interface GameListItemLockedProps {
  game: GameListItemLocked_game$key
  changeToNormalItem: () => void
}

export function GameListItemLocked(props: GameListItemLockedProps) {
  const {IS_YOUTH_DOMAIN} = useSiteEnvironmentContext()
  const game = useFragment(
    graphql`
      fragment GameListItemLocked_game on Game {
        ...DateTimeTextWaitlist_game
        ...TitleWaitlist_game
        ...RemindButton_game
        ...UpcomingCountdown_game
        id
        url
        location {
          ...LocationTextWaitlist_location
        }
        sport {
          slug
          ...SportIcon_sport
        }
      }
    `,
    props.game
  )

  return (
    <Box
      backgroundColor="#a1a1a1"
      borderRadius="sm"
      boxShadow="sm"
      overflow="hidden"
      position="relative"
    >
      <Flex>
        <Show above="md">
          <SportIcon
            isLocked={true}
            sport={game.sport}
            isYouth={IS_YOUTH_DOMAIN}
          />
        </Show>
        <Flex flexGrow={1} paddingInlineStart={{base: '4', md: '0'}}>
          <Flex
            direction={{base: 'column', md: 'row'}}
            align={{base: 'start', md: 'center'}}
            fontSize={{base: '10px', md: '14px'}}
            paddingBlock="2"
          >
            <Box
              width="106px"
              marginTop="0"
              sx={{margin: '0', marginInlineEnd: '1.2rem'}}
            >
              <TitleWaitlist game={game} />
            </Box>
            <Box
              marginBlock="0"
              width="185px"
              marginInlineEnd={{base: '0', md: '1rem'}}
            >
              <DateTimeTextWaitlist game={game} />
            </Box>
            <Box margin="0" maxWidth={{base: '210px', md: '250px'}}>
              <LocationTextWaitlist location={game.location} />
            </Box>
          </Flex>
          <Spacer />
          <Stack
            direction="row"
            align="center"
            gap={{base: '0', md: '2'}}
            paddingInlineEnd="4"
          >
            <UpcomingCountdown
              game={game}
              changeToNormalItem={props.changeToNormalItem}
            />
            <RemindButton zIndex={1} game={game} isYouth={IS_YOUTH_DOMAIN} />
          </Stack>
        </Flex>
      </Flex>
      <Link
        borderRadius="sm"
        bottom={0}
        href={game.url}
        left={0}
        position="absolute"
        right={0}
        top={0}
        _focus={{boxShadow: `outline`}}
      />
    </Box>
  )
}
