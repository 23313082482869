import unescape from 'lodash.unescape'

var cache = {}

export default {
  getBootstrapData: function (name, optional) {
    var node = this._getScriptElm(name)

    if (!!node) {
      var data = JSON.parse(unescape(node.innerHTML))

      if (node.parentNode) {
        node.parentNode.removeChild(node)
      }

      cache[name] = data

      return data
    }

    var cachedData = cache[name]

    if (cachedData !== undefined) {
      return cachedData
    } else if (!optional) {
      throw new Error(`Missing bootstrap data ${name}`)
    }
  },

  hasBootstrapData: function (name) {
    if (name in cache) {
      if (cache[name]) {
        return false
      } else {
        return true
      }
    }

    var elmScript = this._getScriptElm(name)

    return !!elmScript
  },

  isInBootstrapCache: function (name) {
    return name in cache && !!cache[name]
  },

  _getScriptElm: function (name) {
    return document.getElementById(`bootstrap-data-${name}`)
  },
}
