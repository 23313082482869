/**
 * @generated SignedSource<<4ae11ef81d6b3e973c324eeb03a765aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UnfollowUserInput = {
  userId: string;
};
export type GameFollowProfileUnfollowMutation$variables = {
  input: UnfollowUserInput;
};
export type GameFollowProfileUnfollowMutation$data = {
  readonly unfollowUser: {
    readonly user: {
      readonly id: string;
      readonly viewerIsFollowing: boolean;
    } | null;
  } | null;
};
export type GameFollowProfileUnfollowMutation = {
  variables: GameFollowProfileUnfollowMutation$variables;
  response: GameFollowProfileUnfollowMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UnfollowUserPayload",
    "kind": "LinkedField",
    "name": "unfollowUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerIsFollowing",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GameFollowProfileUnfollowMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GameFollowProfileUnfollowMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9633bc000578814389dd47877de78cf1",
    "id": null,
    "metadata": {},
    "name": "GameFollowProfileUnfollowMutation",
    "operationKind": "mutation",
    "text": "mutation GameFollowProfileUnfollowMutation(\n  $input: UnfollowUserInput!\n) {\n  unfollowUser(input: $input) {\n    user {\n      id\n      viewerIsFollowing\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "34b84e6f19daf80d6bb70f98e33d3585";

export default node;
