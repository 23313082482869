import {css} from '@emotion/react'

import zIndex from '../../../utils/zIndex'

export default css`
  [data-reach-dialog-overlay] {
    /* align-items: center; */
    display: flex;
    justify-content: center;
    /* padding-left: 16px; */
    /* padding-right: 16px; */

    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${zIndex.modal};

    overflow-x: hidden;
    overflow-y: visible;
  }

  [data-reach-dialog-content] {
    align-self: flex-start;
    min-width: calc(320px - 32px);
    margin: 0;
    padding: 0;
    width: 100%;

    bottom: auto;
    left: auto;
    position: relative;
    right: auto;
    top: auto;

    background-clip: padding-box;
    background-color: hsla(0, 0%, 0%, 0);
    overflow: auto;
  }
`
