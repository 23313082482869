/* eslint relay/must-colocate-fragment-spreads: off */
import React, {Suspense} from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay/hooks'
import {
  Flex,
  Hide,
  Link,
  Show,
  Skeleton,
  SkeletonCircle,
  Spacer,
  Stack,
  useTheme,
} from '@chakra-ui/react'

import {HeaderQuery} from './__generated__/HeaderQuery.graphql'

import {ReactComponent as LogoDesktop} from './logo-desktop.svg'
import {ReactComponent as LogoMobile} from './logo-mobile.svg'
import {Navigation, UserMenu} from './components'
import {SearchBox} from '../SearchBox'
import {useSiteEnvironmentContext} from "../../../../hooks";

export function Header() {
  const {colors} = useTheme()

  return (
    <Flex
      as="header"
      align="center"
      bg="white"
      borderTop="4px solid"
      borderTopColor="primary.500"
      boxShadow="sm"
      h={{base: `4rem`, sm: `4.5rem`}}
      m="0 auto"
      maxW="container.xl"
      px={4}
      w="100%"
    >
      <Link aria-label="PickupHub" href="/" mr={{base: 4, md: 0}}>
        <Hide above="md">
          <LogoMobile
            height={24}
            style={{
              // @ts-expect-error
              '--color': colors.primary[500],
            }}
          />
        </Hide>
        <Show above="md">
          <LogoDesktop
            height={24}
            style={{
              // @ts-expect-error
              '--color': colors.primary[500],
            }}
          />
        </Show>
      </Link>
      <SearchBox
        onChange={(item) => {
          window.location.href = item.url
        }}
      />
      <Spacer />
      <Suspense fallback={<HeaderLeftSkeleton />}>
        <HeaderLeft />
      </Suspense>
    </Flex>
  )
}

function HeaderLeftSkeleton() {
  return (
    <Stack isInline spacing={4}>
      <Skeleton h={6} w={12} />
      <Skeleton h={6} w={12} />
      <SkeletonCircle h={6} w={6} />
    </Stack>
  )
}

function HeaderLeft() {
  const {USER_TYPE} =
    useSiteEnvironmentContext()

  const {viewer} = useLazyLoadQuery<HeaderQuery>(
    graphql`
      query HeaderQuery($isUser: Boolean!) {
        viewer {
          ...Navigation_viewer
          ...UserMenu_viewer @include(if: $isUser)
        }
      }
    `,
    {
      isUser: USER_TYPE === 'user',
    }
  )

  return (
    <Flex>
      <Navigation viewer={viewer} />
      {Boolean(viewer) && USER_TYPE == 'user' && <UserMenu ml={2} viewer={viewer} />}
    </Flex>
  )
}
