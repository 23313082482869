/**
 * @generated SignedSource<<cfbaa593ba75185eb7e215192045bea7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateLocationInput = {
  address?: string | null;
  cityId: string;
  facility?: CreateFacilityInput | null;
  facilityId?: string | null;
  name: string;
  latitude: number;
  longitude: number;
  hasWashRoom: boolean;
  hasShower: boolean;
  hasChangeRoom: boolean;
  isOutdoor: boolean;
  hasOutdoorLight: boolean;
  surfaceId: string;
};
export type CreateFacilityInput = {
  address?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
  website?: string | null;
};
export type CreateLocationFormModalMutation$variables = {
  input: CreateLocationInput;
};
export type CreateLocationFormModalMutation$data = {
  readonly createLocation: {
    readonly locationEdge: {
      readonly node: {
        readonly address: string | null;
        readonly latitude: number;
        readonly longitude: number;
        readonly id: string;
        readonly isOutdoor: boolean;
        readonly name: string;
        readonly city: {
          readonly name: string;
        } | null;
        readonly facility: {
          readonly name: string;
        } | null;
        readonly surface: {
          readonly name: string | null;
        } | null;
      };
    };
  } | null;
};
export type CreateLocationFormModalMutation = {
  variables: CreateLocationFormModalMutation$variables;
  response: CreateLocationFormModalMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latitude",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longitude",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOutdoor",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = [
  (v7/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateLocationFormModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LocationPayload",
        "kind": "LinkedField",
        "name": "createLocation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LocationEdge",
            "kind": "LinkedField",
            "name": "locationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Location",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "City",
                    "kind": "LinkedField",
                    "name": "city",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Facility",
                    "kind": "LinkedField",
                    "name": "facility",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Surface",
                    "kind": "LinkedField",
                    "name": "surface",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateLocationFormModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LocationPayload",
        "kind": "LinkedField",
        "name": "createLocation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LocationEdge",
            "kind": "LinkedField",
            "name": "locationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Location",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "City",
                    "kind": "LinkedField",
                    "name": "city",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Facility",
                    "kind": "LinkedField",
                    "name": "facility",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Surface",
                    "kind": "LinkedField",
                    "name": "surface",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b036f8f6ebbce979bfc3d944e108a284",
    "id": null,
    "metadata": {},
    "name": "CreateLocationFormModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreateLocationFormModalMutation(\n  $input: CreateLocationInput!\n) {\n  createLocation(input: $input) {\n    locationEdge {\n      node {\n        address\n        latitude\n        longitude\n        id\n        isOutdoor\n        name\n        city {\n          name\n          id\n        }\n        facility {\n          name\n          id\n        }\n        surface {\n          name\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f8d171579a9e71c866c76073af438e89";

export default node;
