const baseStyleHeader = {
  bg: `gray.50`,
  borderBottomColor: `gray.100`,
  borderBottomWidth: `1px`,
  borderTopLeftRadius: `inherit`,
  borderTopRightRadius: `inherit`,
  fontSize: `initial`,
  fontWeight: `initial`,
}

const baseStyleContent = {
  pb: `calc(env(safe-area-inset-bottom))`,
}

const baseStyle = () => ({
  header: baseStyleHeader,
  content: baseStyleContent,
})

export default {
  baseStyle,
}
