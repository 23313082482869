import {func, oneOf, string} from 'prop-types'
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Box} from '../primitives'

function CloseButton({label, onClick, shade, ...props}) {
  const useDarkTheme = shade === `dark`

  return (
    <Box
      {...props}
      as="button"
      bg="transparent"
      border="none"
      color={
        useDarkTheme ? `rgba(144, 148, 156, 0.4)` : `rgba(255, 255, 255, 0.6)`
      }
      onClick={onClick}
      p={0}
      title={label}
      type="button"
      css={{
        cursor: `pointer`,
        transition: `color 0.15s`,
        '&:hover': {
          color: useDarkTheme ? `rgba(144, 148, 156, 0.7)` : `#fff`,
        },
      }}
    >
      <FontAwesomeIcon fixedWidth icon={[`far`, `times`]} />
    </Box>
  )
}

CloseButton.propTypes = {
  label: string,
  onClick: func.isRequired,
  shade: oneOf([`dark`, `light`]),
}

CloseButton.defaultProps = {
  label: `Close`,
  shade: `dark`,
}

export default CloseButton
