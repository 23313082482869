import {Spinner, SpinnerProps} from '@chakra-ui/react'

import {LoadingContainer} from './LoadingContainer'

export * from './LoadingApp'
export * from './LoadingContainer'
export * from './LoadingModal'
export * from './LoadingPage'

export function Loading(props: SpinnerProps) {
  return (
    <LoadingContainer>
      <Spinner {...props} />
    </LoadingContainer>
  )
}
