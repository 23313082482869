import React from 'react'
import {Button} from '@chakra-ui/react'
import {Dialog} from '@reach/dialog'

import CloseButton from '../../CloseButton'
import {Box, Text} from '../../primitives'
import {useStore as useModalsStore} from '../../../store/modals'

function DeleteDoubleCheckModal({message, onConfirm}) {
  const closeModal = useModalsStore((state) => state.closeModal)

  function handleDelete() {
    closeModal()
    onConfirm()
  }

  return (
    <Dialog
      isOpen
      onDismiss={closeModal}
      style={{maxWidth: `400px`}}
      aria-labelledby={`DeleteDoubleCheckModal`}
    >
      <Box bg="white" p={6}>
        <CloseButton
          onClick={closeModal}
          position="absolute"
          right={2}
          top={2}
        />
        <Text fontSize="xl" fontWeight="semibold">
          Are you sure?
        </Text>
        <Text fontSize="lg">{message}</Text>

        <Box display="flex" justifyContent="flex-end" mt={8}>
          <Button type="button" onClick={closeModal} variant="ghost">
            Cancel
          </Button>

          <Button type="button" onClick={handleDelete} colorScheme="red" ml={2}>
            Delete
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default DeleteDoubleCheckModal
