import {Button, ButtonProps, Text} from '@chakra-ui/react'
import {faCheck as farCheck} from '@fortawesome/pro-regular-svg-icons'
import {useActor, useMachine} from '@xstate/react'
import {FontAwesomeIcon} from 'components/FontAwesomeIcon'
import {ModalService} from 'components/ModalRoot'
import {useSiteEnvironmentContext} from 'hooks'
import {graphql, useFragment, useLazyLoadQuery, useMutation} from 'react-relay'
import {wrapEvent} from 'utils'
import {InterpreterFrom} from 'xstate'
import {gameWaitlistButtonMachine} from './gameWaitlistButtonMachine'
import {
  WaitlistButtonJoinMutation,
  WaitlistButtonJoinMutation$data,
} from './__generated__/WaitlistButtonJoinMutation.graphql'
import {
  WaitlistButtonLeaveMutation,
  WaitlistButtonLeaveMutation$data,
} from './__generated__/WaitlistButtonLeaveMutation.graphql'
import {WaitlistButtonViewerQuery} from './__generated__/WaitlistButtonViewerQuery.graphql'
import {WaitlistButton_game$key} from './__generated__/WaitlistButton_game.graphql'

export interface WaitlistButtonProps extends Omit<ButtonProps, 'children'> {
  game: WaitlistButton_game$key
  showHockeyModal?: boolean
}
export function WaitlistButton({
  game: _game,
  showHockeyModal,
  ...props
}: WaitlistButtonProps) {
  const {IS_YOUTH_DOMAIN} = useSiteEnvironmentContext()

  const {id: gameId, viewerIsOnWaitingList} = useFragment(
    graphql`
      fragment WaitlistButton_game on Game {
        id
        viewerIsOnWaitingList
      }
    `,
    _game
  )

  const {viewer} = useLazyLoadQuery<WaitlistButtonViewerQuery>(
    graphql`
      query WaitlistButtonViewerQuery {
        viewer {
          id
        }
      }
    `,
    {}
  )

  const [joinWaitlistCommit] = useMutation<WaitlistButtonJoinMutation>(
    graphql`
      mutation WaitlistButtonJoinMutation($input: JoinWaitlistInput!) {
        joinWaitingList(input: $input) {
          game {
            id
            viewerIsOnWaitingList
          }
          user {
            id
            hideWaitlistModal
          }
        }
      }
    `
  )

  const [leaveWaitlistCommit] = useMutation<WaitlistButtonLeaveMutation>(
    graphql`
      mutation WaitlistButtonLeaveMutation($input: LeaveWaitlistInput!) {
        leaveWaitingList(input: $input) {
          game {
            viewerIsOnWaitingList
          }
        }
      }
    `
  )

  const [, , gameWaitlistButtonService] = useMachine(
    gameWaitlistButtonMachine,
    {
      actions: {
        checkModalWaitlist: (ctx, e) => {
          if (!e.data.joinWaitingList.user.hideWaitlistModal) {
            ModalService.send('OPEN', {
              modal: 'WAITLIST_NOTIFICATION_MODAL',
            })
          }
        },
      },
      services: {
        joinWaitlist: (ctx, e) => {
          return new Promise<WaitlistButtonJoinMutation$data>(function (
            resolve,
            reject
          ) {
            joinWaitlistCommit({
              variables: {input: {gameId: e.id}},
              onCompleted(response) {
                resolve(response)
              },
              onError(errors) {
                reject(errors)
              },
              optimisticResponse: {
                joinWaitingList: {
                  game: {
                    id: gameId,
                    viewerIsOnWaitingList: true,
                  },
                  user: {
                    id: viewer.id,
                    hideWaitlistModal: true || false,
                  },
                },
              },
            })
          })
        },
        leaveWaitlist: (ctx, e) => {
          return new Promise<WaitlistButtonLeaveMutation$data>(function (
            resolve,
            reject
          ) {
            leaveWaitlistCommit({
              variables: {input: {gameId: e.id}},
              onCompleted(response) {
                resolve(response)
              },
              onError(errors) {
                reject(errors)
              },
              optimisticResponse: {
                leaveWaitingList: {
                  game: {
                    id: gameId,
                    viewerIsOnWaitingList: false,
                  },
                },
              },
            })
          })
        },
      },
    }
  )

  if (viewerIsOnWaitingList) {
    return (
      <LeaveButton
        {...props}
        gameId={gameId}
        gameWaitlistButtonService={gameWaitlistButtonService}
        isYouth={IS_YOUTH_DOMAIN}
      />
    )
  }

  return (
    <JoinButton
      {...props}
      gameId={gameId}
      showHockeyModal={showHockeyModal}
      gameWaitlistButtonService={gameWaitlistButtonService}
      isYouth={IS_YOUTH_DOMAIN}
    />
  )
}

interface JoinButtonProps extends Omit<WaitlistButtonProps, 'game'> {
  gameId: string
  gameWaitlistButtonService: InterpreterFrom<typeof gameWaitlistButtonMachine>
  isYouth: Boolean
}

function JoinButton({
  onClick,
  gameId,
  gameWaitlistButtonService,
  showHockeyModal,
  isYouth,
  ...props
}: JoinButtonProps) {
  const [, sendGameWaitingList] = useActor(gameWaitlistButtonService)

  return (
    <Button
      width="100px"
      height="8"
      backgroundColor={'whiteAlpha.900'}
      sx={{_hover: {backgroundColor: 'green.500', color: 'whiteAlpha.900'}}}
      color={isYouth ? 'purple.500' : 'green.500'}
      fontSize={{base: '12px', md: '14px'}}
      onClick={wrapEvent(onClick, () => {
        sendGameWaitingList({
          type: showHockeyModal
            ? 'JOIN_WAITLIST_GOALIE_HOCKEY'
            : 'JOIN_WAITLIST',
          id: gameId,
        })
      })}
      {...props}
    >
      Join Waitlist
    </Button>
  )
}

interface LeaveButtonProps extends Omit<WaitlistButtonProps, 'game'> {
  gameId: string
  gameWaitlistButtonService: InterpreterFrom<typeof gameWaitlistButtonMachine>
  isYouth: Boolean
}

function LeaveButton({
  onClick,
  gameId,
  gameWaitlistButtonService,
  isYouth,
  ...props
}: LeaveButtonProps) {
  const [, sendGameWaitingList] = useActor(gameWaitlistButtonService)

  return (
    <Button
      width="100px"
      height="8"
      backgroundColor={isYouth ? 'purple.500' : 'green.500'}
      color="whiteAlpha.900"
      fontSize={{base: '12px', md: '14px'}}
      onClick={wrapEvent(onClick, () => {
        sendGameWaitingList({type: 'LEAVE_WAITLIST', id: gameId})
      })}
      {...props}
    >
      <Text marginInlineEnd="1">Waitlist</Text>
      <FontAwesomeIcon icon={farCheck} />
    </Button>
  )
}
