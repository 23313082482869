import type {WaitlistButtonLeaveMutation$data} from './__generated__/WaitlistButtonLeaveMutation.graphql'
import {createMachine} from 'xstate'
import type {WaitlistButtonJoinMutation$data} from './__generated__/WaitlistButtonJoinMutation.graphql'
import {ModalService} from 'components/ModalRoot'

export const gameWaitlistButtonMachine = createMachine(
  {
    predictableActionArguments: true,
    id: 'gameWaitlistButton',
    initial: 'idle',
    schema: {
      context: {} as {},
      events: {} as
        | {
            type: 'JOIN_WAITLIST'
            id: string
          }
        | {
            type: 'JOIN_WAITLIST_GOALIE_HOCKEY'
            id: string
          }
        | {
            type: 'LEAVE_WAITLIST'
            id: string
          },
      services: {} as {
        joinWaitlist: {
          data: WaitlistButtonJoinMutation$data
        }
        leaveWaitlist: {
          data: WaitlistButtonLeaveMutation$data
        }
      },
    },
    tsTypes: {} as import('./gameWaitlistButtonMachine.typegen').Typegen0,
    states: {
      idle: {
        on: {
          JOIN_WAITLIST: 'joining',
          JOIN_WAITLIST_GOALIE_HOCKEY: {
            actions: 'joinWaitlistHockeyModal',
          },
          LEAVE_WAITLIST: 'leaving',
        },
      },
      joining: {
        invoke: {
          id: 'joinWaitlist',
          src: 'joinWaitlist',
          onDone: {
            actions: 'checkModalWaitlist',
            target: 'idle',
          },
          onError: {
            actions: 'setErrorMessage',
            target: 'idle',
          },
        },
      },
      leaving: {
        invoke: {
          id: 'leaveWaitlist',
          src: 'leaveWaitlist',
          onDone: 'idle',
          onError: {
            actions: 'setErrorMessage',
            target: 'idle',
          },
        },
      },
    },
  },
  {
    actions: {
      setErrorMessage: () => {
        console.log('setErrorMessage: ')
      },
      joinWaitlistHockeyModal: (ctx, e) => {
        ModalService.send(`OPEN`, {
          modal: 'WAITLIST_HOCKEY_POSITION_MODAL',
          props: {gameId: e.id},
        })
      },
    },
  }
)
