import {createContext} from 'react'

interface SiteEnvironmentContextObject {
  APP_ENV: string
  APP_VERSION: string
  IS_RUNNING_IN_IFRAME: boolean
  IS_YOUTH_DOMAIN: boolean
  PICKUPHUB_GRAPHQL_URL: string
  PICKUPHUB_URL: string
  PICKUPHUB_YOUTH_URL: string
  PUSHER_APP_KEY: string
  SENTRY_DSN: string
  USER_TYPE: null | 'provider' | 'user'
}

export const SiteEnvironmentContext = createContext<
  SiteEnvironmentContextObject | undefined
>(undefined)

export const SiteEnvironmentProvider = SiteEnvironmentContext.Provider

// @ts-expect-error
SiteEnvironmentProvider.displayName = `SiteEnvironmentContext.Provider`
