import sample from 'lodash.sample'
import React, {memo} from 'react'
import {Box, Divider, Flex, Skeleton, Stack} from '@chakra-ui/react'

const PROVIDER_NAME_SIZES = [20, 24, 32, 40]
const PROVIDER_CREDIT_SIZES = [6, 8, 10]

export const PopoverBodyContentSkeleton = memo(
  function PopoverBodyContentSkeleton() {
    return (
      <>
        <Flex alignItems="center" p={2}>
          <Skeleton borderRadius="full" h={12} w={12} />
          <Box ml={2}>
            <Skeleton h={3} w={32} />
            <Skeleton h={2} mt={2} w={24} />
          </Box>
        </Flex>

        <Divider borderColor="gray.300" m={2} />

        <Stack py={3} spacing={6}>
          <Skeleton h={2} mx={2} w={12} />
          {Array.from(Array(3).keys()).map((i) => (
            <Flex justify="space-between" key={i} px={2}>
              <Skeleton h={3} w={sample(PROVIDER_NAME_SIZES)} />
              <Skeleton h={3} w={sample(PROVIDER_CREDIT_SIZES)} />
            </Flex>
          ))}
        </Stack>

        <Divider borderColor="gray.300" m={2} />

        <Stack p={2} spacing={6}>
          <Skeleton h={3} w={24} />
          <Skeleton h={3} w={16} />
        </Stack>
      </>
    )
  }
)
