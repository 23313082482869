import {differenceInMinutes, parseISO} from 'date-fns'
import React from 'react'
import {graphql, useFragment} from 'react-relay/hooks'
import {Box} from '@chakra-ui/react'
import {faClock as farClock} from '@fortawesome/pro-regular-svg-icons'

import {DateTimeText_game$key} from './__generated__/DateTimeText_game.graphql'

import {FontAwesomeIcon} from 'components/FontAwesomeIcon'
import Time from 'components/Time'
import {DateTimeTextWaitlist_game$key} from './__generated__/DateTimeTextWaitlist_game.graphql'

export interface DateTimeTextProps {
  game: DateTimeText_game$key
  isCancelled?: boolean
}

export function DateTimeText(props: DateTimeTextProps) {
  const {endTime, startTime} = useFragment(
    graphql`
      fragment DateTimeText_game on Game {
        endTime
        startTime
      }
    `,
    props.game
  )

  return (
    <Box fontSize="sm" lineHeight="none">
      <Box as="span" color={props.isCancelled ? 'gray.500' : 'primary.500'}>
        <FontAwesomeIcon icon={farClock} />
      </Box>
      {` `}
      <Time format="E, MMM d @ h:mm aaaa" value={startTime} />
      {` `}
      <Box as="span" color="gray.400" fontStyle="italic">
        ({differenceInMinutes(parseISO(endTime), parseISO(startTime))} mins)
      </Box>
    </Box>
  )
}

export interface DateTimeTextWaitlistProps {
  game: DateTimeTextWaitlist_game$key
}

export function DateTimeTextWaitlist(props: DateTimeTextWaitlistProps) {
  const {startTime} = useFragment(
    graphql`
      fragment DateTimeTextWaitlist_game on Game {
        endTime
        startTime
      }
    `,
    props.game
  )

  return (
    <Box
      // fontSize="sm"
      lineHeight="none"
      textTransform="uppercase"
      color="white"
      fontWeight="semibold"
      fontSize={{base: '11px', sm: '14px'}}
    >
      <Time format="E, MMM d @ h:mm aaaa" value={startTime} />
    </Box>
  )
}
