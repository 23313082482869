/**
 * @generated SignedSource<<ac4dbfc34e5d11a4756273295d6ae22c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemindButton_game$data = {
  readonly id: string;
  readonly viewerIsOnRemindMeList: boolean;
  readonly " $fragmentType": "RemindButton_game";
};
export type RemindButton_game$key = {
  readonly " $data"?: RemindButton_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemindButton_game">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemindButton_game",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerIsOnRemindMeList",
      "storageKey": null
    }
  ],
  "type": "Game",
  "abstractKey": null
};

(node as any).hash = "f56c751a718c0bd2d5291c7ea78bd99e";

export default node;
