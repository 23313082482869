import {createMachine} from 'xstate'
import {assign} from '@xstate/immer'

export default createMachine(
  {
    id: 'modal',
    initial: 'closed',
    schema: {
      context: {} as {
        modal?: string
        modals: string[]
        props: any
      },
      events: {} as
        | {
            type: 'CLOSE'
          }
        | {
            modal: string
            props?: any
            type: 'OPEN'
          },
    },
    tsTypes: {} as import('./machine.typegen').Typegen0,
    context: {
      modal: undefined,
      modals: [],
      props: {},
    },
    states: {
      closed: {
        on: {
          OPEN: {
            actions: 'setContext',
            cond: 'isValidPayload',
            target: 'opened',
          },
        },
      },

      opened: {
        on: {
          CLOSE: 'closed',
        },
      },
    },
  },
  {
    actions: {
      setContext: assign((ctx, event) => {
        // @ts-ignore
        ctx.modal = event.modal
        // @ts-ignore
        ctx.props = event.props ?? {}
      }),
    },
    guards: {
      // @ts-ignore
      isValidPayload: (ctx, {modal}) => ctx.modals.includes(modal),
    },
  }
)
