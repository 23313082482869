import {
  Box,
  Button,
  Link,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {LoadingPage} from 'components'
import {Suspense, useState} from 'react'
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay'

import {
  faUserPlus as fasUserPlus,
  faUserMinus as fasUserMinus,
  faUser as fasUser,
} from '@fortawesome/pro-solid-svg-icons'

import {GameFollowProfileFollowMutation} from './__generated__/GameFollowProfileFollowMutation.graphql'
import {GameFollowProfileQuery} from './__generated__/GameFollowProfileQuery.graphql'
import {GameFollowProfileUnfollowMutation} from './__generated__/GameFollowProfileUnfollowMutation.graphql'

export default function GameFollowProfile({viewer}) {
  const userId = btoa(`User:${viewer.profile}`)
  const {isOpen, onOpen, onClose} = useDisclosure()

  const {user} = useLazyLoadQuery<GameFollowProfileQuery>(
    graphql`
      query GameFollowProfileQuery($userId: ID!) {
        user(id: $userId) {
          resourcePath
          firstName
          lastName
          viewerIsFollowing
        }
      }
    `,
    {
      userId: userId,
    }
  )

  const [followUserCommit, isInFlightFollowUser] =
    useMutation<GameFollowProfileFollowMutation>(graphql`
      mutation GameFollowProfileFollowMutation($input: FollowUserInput!) {
        followUser(input: $input) {
          user {
            id
            viewerIsFollowing
          }
        }
      }
    `)

  const [unfollowUserCommit, isInFlightUnfollowUser] =
    useMutation<GameFollowProfileUnfollowMutation>(graphql`
      mutation GameFollowProfileUnfollowMutation($input: UnfollowUserInput!) {
        unfollowUser(input: $input) {
          user {
            id
            viewerIsFollowing
          }
        }
      }
    `)

  let [follow, setFollow] = useState(user.viewerIsFollowing)

  function onclick(userId) {
    if (!follow) {
      followUserCommit({
        variables: {input: {userId: userId}},
        optimisticResponse: {
          followUser: {
            user: {
              id: userId,
              viewerIsFollowing: true,
            },
          },
        },
      })

      setFollow(true)
      onClose()
    } else {
      unfollowUserCommit({
        variables: {input: {userId: userId}},
        optimisticResponse: {
          unfollowUser: {
            user: {
              id: userId,
              viewerIsFollowing: false,
            },
          },
        },
      })
      setFollow(false)
      onClose()
    }
  }
  return (
    <Box
      display="flex"
      textAlign="center"
      justifyContent="center"
      alignItems="center"
      h="100%"
      w="100%"
    >
      <Suspense fallback={<LoadingPage />}>
        <Link
          href={user.resourcePath}
          w="50%"
          h="100%"
          display="flex"
          columnGap="8px"
          alignItems="center"
          justifyContent="center"
          fontWeight="600"
          _focus={{boxShadow: `none`}}
          _hover={{
            bg: '#b2d876',
            height: '100%',
            borderRadius: 'none',
            color: '#FFFFFF',
            boxShadow: `none`,
          }}
        >
          <FontAwesomeIcon icon={fasUser} size="lg" />
          <Text>Profile</Text>
        </Link>

        <Button
          w="50%"
          h="100%"
          colorScheme="ghost"
          isLoading={isInFlightFollowUser || isInFlightUnfollowUser}
          _focus={{boxShadow: `none`}}
          _hover={{
            bg: '#b2d876',
            height: '100%',
            borderRadius: 'none',
            boxShadow: `none`,
          }}
          leftIcon={
            <FontAwesomeIcon
              icon={!follow ? fasUserPlus : fasUserMinus}
              size="lg"
            />
          }
          onClick={onOpen}
        >
          {!follow ? 'Follow' : 'Unfollow'}
        </Button>
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{ follow ? `Unfollow ${user.firstName} ${user.lastName}` : `Follow ${user.firstName} ${user.lastName}`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              { follow ? `Confirm you'd like to unfollow ${user.firstName} ${user.lastName}?` : `Confirm you'd like to follow ${user.firstName} ${user.lastName}?`}
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={onClose}>
                Close
              </Button>
              <Button
              _hover={{bg: follow ? 'red.400' : 'green.300'}}
              color={'white'}
              bgColor={ follow ? 'red.600' : 'green.500'}
              onClick={() => onclick(userId)}>
                {!follow ? 'Follow' : 'Unfollow'}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Suspense>
    </Box>
  )
}
